
export const Url = process.env.NODE_ENV==='development'?'http://localhost:8002/api/v1/':'https://portailclientapi.tryon-erp.com/api/v1/';
export const url_files = process.env.NODE_ENV==='development'?'http://localhost:8001/':'';


export const login='auth/login';
export const logout='auth/logout';
export const status='auth/status';
export const user_data='auth/user-data';
export const password_forget='auth/forget-password';

export const dashboard='dashboard/';
export const details_operationnels_graph=dashboard+'details/operationnel/';
export const details_operationnels_graph_toggle=details_operationnels_graph+'toggle/';
export const aleas_logisitque_graph=dashboard+'aleas/logistiques/';
export const aleas_logisitque_graph_toggle=aleas_logisitque_graph+'toggle/';

export const analyse_poids_graph=dashboard+'analyse/graph/';
export const toggle_analyse_poids_graph=analyse_poids_graph+'toggle/';
export const couverts_numbers=dashboard+'couverts/numbers/';
export const add_couverts_numbers=dashboard+'couverts/numbers/add/';
export const synthese_environnementale_graph=dashboard+'synthese/environnementale/graph/';
export const synthese_environnementale_graph_toggle=synthese_environnementale_graph+'toggle/';
export const synthese_utilisateur=dashboard+'synthese/utilisateur/';

export const dacs='dacs/';
export const dacs_list=dacs+'list/';
export const dac_details=dacs+'details/';

export const factures='factures/';
export const factures_graph=factures+'graph/';
export const factures_moyen_couvert=factures+'moyenne-couvert/';
export const factures_listes=factures+'listes/';
export const couts_widgets=factures+'couts/widgets/';
export const toggle_factures_graph=factures_graph+'toggle/'
export const factures_couts_graph=factures+'couts/graph/';
export const factures_couts_graph_toggle=factures_couts_graph+'toggle/';
export const profil_details='profil/';
export const profil_update='profil/update/';

export const role_management="role-management/";
export const role_management_societe_admin=role_management+"societes-admin/";
export const role_management_etablissement_admin=role_management+"etablissements-admin/";
export const role_management_etablissements_admin=role_management+"etablissements-admin/";
export const role_management_etablissements_societe=role_management+"etablissements-societe/";

export const etablissements='etablissements/';
export const consignes_collectes=etablissements+'consignes-collectes/listes';
export const changes_consignes=etablissements+'consignes-collectes/update';
export const horaires_ouverture=etablissements+'horaires-ouverture/listes';
export const add_horaire_ouverture=etablissements+'horaires-ouverture/add';
