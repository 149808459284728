import * as types from "../index";
import {changes_consignes, dacs_list, Url} from "../../Env/env";
import {UpdateConsignes} from "../../Models/etablissements/updateConsignes";

export const ChangeConsignesAction = (data:UpdateConsignes) => {
    return {
        type: types.ADD_CHANGE_CONSIGNES,
        url:Url + changes_consignes,
        dataPayload:data

    }
};
