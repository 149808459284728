import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from '../reducers';
import rootSaga from '../sagas';

// Redux devtools extension setup
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

// Persist configuration
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['LoginUserReducer']

  // Add any reducer names you want to ignore persisting:
  // blacklist: ['reducerName']
};

// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  // Create the store with middleware and persisted reducer
  const store = createStore(
      persistedReducer,
      composeSetup(applyMiddleware(sagaMiddleware))
  );

  // Run the saga middleware
  sagaMiddleware.run(rootSaga);

  // Create a persistor instance
  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
