import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {get} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"
import {
    GetDacDetailsInterface,
    GetDacDetailsInterfaceResponse
} from "../../interfaces/dacsInterface/GetDacDetailsInterface";



export function* GetDacDetailsSaga(payload: any):any {
    try {
        const apiCall = async () => {
            return await get<GetDacDetailsInterface>(payload.url, headers);
        };
        let response, parsedBody: GetDacDetailsInterface | any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody.data) {
                        let data: GetDacDetailsInterfaceResponse= parsedBody.data;
                        yield put({
                            type: types.GET_DAC_DETAILS_SUCCESS,
                            response: {
                                dac_details:data.dac_details,
                                success:data.success,
                                timestamp:data.timestamp,
                            }
                        });
                    }
                    break;
                case 400:
                    break;
                case 401:
                    localStorage.clear();
                    window.location.reload();
                    break;
                default:
                    break;
            }
        }
        else {
            yield put({type: types.GET_DAC_DETAILS_ERROR, error: response.status})
        }


    } catch (error) {
        yield [
            put({type: types.GET_DAC_DETAILS_ERROR, error}),
        ];
    }
}
