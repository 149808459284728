import React, {Component} from 'react';
import {connect} from "react-redux";

import {GetProfilDetailsAction} from "../../../../actions/profil/GetProfilDetailsAction";
import {UpdateProfilAction} from "../../../../actions/profil/updateProfilAction";


import Breadcrumbs from "../../../Common/Breadcrumb";
import {Container, Row} from "reactstrap";


class ProfilDetails extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            breadcrumbItems : [
                { title : "portail client", link : "/" },
                { title : "Profil", link : "#" },
            ],
            file: null,
            flagSuccess:0,
            id:0,
        };

    }


    componentDidMount(){
        if(this.props.decoded_token) {
                this.setState({id: this.props.decoded_token.id})
                this.props.GetProfilDetailsAction(this.props.decoded_token.id);
            }


    }
    updateProfilHandler = (event) => {
        event.preventDefault();
        this.setState({flagSuccess:1})
        const formData=new FormData();
        /*event.target.first_name.value && formData.append('first_name',event.target.first_name.value);
        event.target.last_name.value && formData.append('last_name',event.target.last_name.value);*/
        event.target.password.value && formData.append('password',event.target.password.value);
        event.target.phone.value && formData.append('phone',event.target.phone.value);
        // this.state.file && formData.append('picture', this.state.file);
        this.props.UpdateProfilAction(formData,this.state.id);
    };
    handleFileUpload = (event) => {
        this.setState({file:event.target.files[0]});
    };

    render() {
        const {profil_details,}=this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Profil" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <div className="col-lg-12 mx-auto">
                                <div className="card radius-10">
                                    <div className="card-body">
                                        <form onSubmit={this.updateProfilHandler}>
                                            {/*<div className="d-flex flex-column gap-3 align-items-center justify-content-center">*/}
                                            {/*    <div className="user-change-photo shadow">*/}
                                            {/*        <img src={profil_details['userDetails']['picture']? url_files+'profil/picture/'+profil_details['userDetails']['picture']:"/images/avatars/06.png"} alt="..."/>*/}
                                            {/*    </div>*/}
                                            {/*    <input ref="fileInput"  accept={"image/png,image/jpg,image/jpeg"} onChange={this.handleFileUpload} type="file" style={{ display: "none" }}/>*/}
                                            {/*    <button type="button" onClick={() => this.refs.fileInput.click()} className="btn btn-outline-primary btn-sm radius-30 px-4">*/}
                                            {/*        <ion-icon name="image-sharp"></ion-icon>Change Photo*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                            <h5 className="mb-0 mt-4">Information d'utilisateur</h5>
                                            <hr/>
                                            <div className="row g-3" key={profil_details['id']}>
                                                {/*<div className="col-6">*/}
                                                {/*    <label className="form-label">First Name</label>*/}
                                                {/*    <input type="text" className="form-control" name="first_name"  defaultValue={profil_details['first_name']}/>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-6">*/}
                                                {/*    <label className="form-label">Last Name</label>*/}
                                                {/*    <input type="text" className="form-control" name="last_name" defaultValue={profil_details['last_name']}/>*/}
                                                {/*</div>*/}
                                                <div className="col-6">
                                                    <label className="form-label">Email address</label>
                                                    <input type="text" className="form-control" disabled defaultValue={profil_details['email']}/>
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Password</label>
                                                    <input type="password" className="form-control" name="password"   minLength="8"/>
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Phone</label>
                                                    <input type="text" className="form-control" name="phone"  defaultValue={profil_details['userDetails'] ? profil_details['userDetails']['phone']:null}/>
                                                </div>
                                                {/*<div className="col-6">*/}
                                                {/*    <label className="form-label">Role</label>*/}
                                                {/*    <input type="text" className="form-control" disabled defaultValue={profil_details['role'] && getRoles(profil_details['role'])}/>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="text-start mt-3">
                                                <button type="submit" className="btn btn-primary px-4">Save Changes</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>

        )

    }
}


const mapDispatchToProps = {GetProfilDetailsAction,UpdateProfilAction};

function mapStateToProps(state) {
    return {
        profil_details:state.GetProfilDetailsReducer.profil_details,
        message:state.UpdateProfilReducer.message,
        decoded_token:state.DecodeTokenReducer.decoded_token,


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilDetails);
