export class CouvertsNumbers {
    num_mois: string;
    qte_couverts_mois: number;
    id: number;
    societeId?: number;
    etablissementId?: number;
    constructor(id:number,num_mois:string,qte_couverts_mois: number,societeId: number,etablissementId: number) {
        this.id = id;
        this.num_mois = num_mois;
        this.qte_couverts_mois = qte_couverts_mois;
        this.societeId = societeId;
        this.etablissementId = etablissementId;
    }
}
