import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {get, post} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions"
import {
    GetFacturesGraphInterface,
    GetFacturesGraphInterfaceResponse
} from "../../interfaces/facturesInterface/GetFacturesGraphInterface";


export function* ToggleFacturesGraphSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await post<GetFacturesGraphInterface>(payload.url,payload.dataPayload, headers);
            };
            let response, parsedBody: GetFacturesGraphInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetFacturesGraphInterfaceResponse= parsedBody.data;
                            yield put({
                                    type: types.GET_FACTURES_GRAPH_SUCCESS, response: {
                                        series: data.series,
                                        categories:data.categories
                                    }
                                });
                        }
                        break;
                    case 400:
                        break;
                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        if(parsedBody.status ==="Token is Invalid")
                        {
                            // logout();
                        }
                        break;
                }
            }
            else {
                yield put({type: types.GET_FACTURES_GRAPH_ERROR, error: response.status})
            }


    } catch (error) {
        yield [
            put({type: types.GET_FACTURES_GRAPH_ERROR, error}),
        ];
    }
}
