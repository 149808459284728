import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
//Import Breadcrumb
import Breadcrumbs from '../../../Common/Breadcrumb';

//Import Components
import {connect} from "react-redux";
import DetailsOperationnels from "./DetailsOperationnels";
import {GetDetailsOperationnelsGraphAction} from "../../../../actions/dashboard/getDetailsOperationnelsGraphAction";
import CouvertsMensuels from "./CouvertsMensuels";
import PoidsAnalyse from "./PoidsAnalyse";
import {GetPoidsAnalyseGraphAction} from "../../../../actions/dashboard/getPoidsAnalyseGraphAction";
import {GetCouvertsNumbersAction} from "../../../../actions/dashboard/getCouvertsNumbersAction";
import {AddCouvertNumberAction} from "../../../../actions/dashboard/addCouvertNumberAction";
import {GetSyntheseEnvironnementaleGraphAction} from "../../../../actions/dashboard/getSyntheseEnvironnementaleGraphAction";
import {
    ToggleDetailsOperationnelsGraphAction
} from "../../../../actions/dashboard/toggleDetailsOperationnelsGraphAction";
import {ToggleSyntheseEnvironnementalePeriodeAction} from "../../../../actions/dashboard/toggleSyntheseEnvironnementalePeriodeAction";
import moment from "moment";
import SyntheseEnvironnementale from "./SyntheseEnvironnementale";
import SyntheseUtilisateur from "./SyntheseUtilisateur";
import {GetSyntheseUtilisateurAction} from "../../../../actions/dashboard/getSyntheseUtilisateurAction";
import {TogglePoidsAnalyticsGraphAction} from "../../../../actions/dashboard/togglePoidsAnalyticsGraphAction";
import AleasLogistiques from "./AleasLogistiques";
import {GetAleasLogistiquesGraphAction} from "../../../../actions/dashboard/getAleasLogistiquesGraphAction";
import {ToggleAleasLogistiquesGraphAction} from "../../../../actions/dashboard/toggleAleasLogistiquesGraphAction";
import {exportMultipleChartsToPdf} from "../../../../utils/printCharts";
import {isAdmin, isEtablissement, isSociete} from "../../../../utils/myFunction";

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "portail client", link : "/" },
                { title : "Dashboard", link : "#" },
            ],
            ranges:[
                this.props.startDate!==""?this.props.startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                this.props.endDate!==""?this.props.endDate: moment().format('YYYY-MM-DD'),
            ],
            data_etablissement:null
        }
    }
    componentDidMount() {
        const {user,data_admins,data_societe}=this.props;
        if(user && this.props.startDate!=="" && this.props.endDate!== ""){
            const startDate=moment(this.props.startDate).format('YYYY-MM-DD');
            const endDate=moment(this.props.endDate).format('YYYY-MM-DD');
            this.setState({ranges:[startDate,endDate?endDate:null]})
            let data;
            if(isAdmin(user.scope.role) && data_admins){
                data=data_admins;
                data['start_date'] = startDate;
                data['end_date'] = endDate;
            }
            if(isSociete(user.scope.role) && data_societe){
                data=data_societe;
                data['start_date'] = startDate;
                data['end_date'] = endDate;
            }
            if(isEtablissement(user.scope.role)){
                data = {
                    "societeId":this.props.user.societeId,
                    "etablissementsId":[this.props.user.etablissementId],
                    "start_date":startDate,
                    "end_date":endDate,
                } ;
                this.setState({data_etablissement:data});

            }
            if(data){
                this.props.GetDetailsOperationnelsGraphAction(data);
                this.props.GetAleasLogistiquesGraphAction(data);
                this.props.GetPoidsAnalyseGraphAction(data);
                if(!data['etablissementsId'].includes(0) && data['etablissementsId'].length===1){
                    this.props.GetCouvertsNumbersAction(data);
                }
                this.props.GetSyntheseEnvironnementaleGraphAction(data);
                this.props.GetSyntheseUtilisateurAction(data);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.user){
            if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate ||
                (this.props.data_admins && JSON.stringify(this.props.data_admins) !== JSON.stringify(prevProps.data_admins)) ||
                (this.props.data_societe && JSON.stringify(this.props.data_societe) !== JSON.stringify(prevProps.data_societe))) {
                const startDate = moment(this.props.startDate).format('YYYY-MM-DD');
                const endDate = moment(this.props.endDate).format('YYYY-MM-DD');
                this.setState({ranges: [startDate, endDate ? endDate : null]})
                let data;
                if (isAdmin(this.props.user.scope.role) && this.props.data_admins) {
                    data = this.props.data_admins;
                    data['start_date'] = startDate;
                    data['end_date'] = endDate;
                }
                if (isSociete(this.props.user.scope.role) && this.props.data_societe) {
                    data = this.props.data_societe;
                    data['start_date'] = startDate;
                    data['end_date'] = endDate;
                }
                if (isEtablissement(this.props.user.scope.role)) {
                    data = {
                        "societeId":this.props.user.societeId,
                        "etablissementsId":[this.props.user.etablissementId],
                        "start_date":startDate,
                        "end_date":endDate,

                    };
                    this.setState({data_etablissement:data});
                }
                if (data) {
                    this.props.GetDetailsOperationnelsGraphAction(data);
                    this.props.GetAleasLogistiquesGraphAction(data);
                    this.props.GetPoidsAnalyseGraphAction(data);
                    if (!data['etablissementsId'].includes(0) && data['etablissementsId'].length === 1) {
                        this.props.GetCouvertsNumbersAction(data);
                    }
                    this.props.GetSyntheseEnvironnementaleGraphAction(data);
                    this.props.GetSyntheseUtilisateurAction(data);
                }
            }
        }
    }

    render() {
        const {details_operationnels_series,details_operationnels_categories,
            analyse_poids_series,analyse_poids_categories,couverts_number,societe_for_admin,etablissements_from_store,aleas_logistique_series,aleas_logistique_categories,
            synthese_environnementale_series, synthese_environnementale_categories,synthese_utilisateur,data_admins,data_societe,user}=this.props;
        let etablissements="";
        let societe="";
        if(!user) return null;
        if(isAdmin(user.scope.role) && societe_for_admin && etablissements_from_store){
            etablissements=etablissements_from_store.map(item=>{return item.label});
            if (etablissements.includes('Voir Tous'))
                etablissements="Tous les établissements";
            else etablissements=etablissements.join(" , ");
            societe=societe_for_admin.label.split('-')[1];
        }
        if(isSociete(user.scope.role) && etablissements_from_store){
            etablissements=etablissements_from_store.map(item=>{return item.label});
            if (etablissements.includes('Voir Tous'))
                etablissements="Tous les établissements";
            else etablissements=etablissements.join(" , ");
            societe=user.firstName;
        }
        if(isEtablissement(user.scope.role)){
            etablissements=user.nom_etablissement || user.firstName;
            societe=user.nom_societe || user.firstName;
        }
        return (
            <React.Fragment>
                <div className="page-content" >
                    <Container fluid >
                        <Row>
                            <Col  xl={12}>
                                <button onClick={exportMultipleChartsToPdf} className={"float-end btn btn-info mb-4 "}>
                                    <i className="ri-download-line"></i>
                                    <span className="ms-1">Export dashboard</span>
                                </button>
                            </Col>
                        </Row>
                        <Breadcrumbs  title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} nameEtablissement={etablissements} societe={societe} />
                        <Row>
                            {/*SyntheseUtilisateur*/}
                            <div className="custom-chart">
                                <Col xl={12} >
                                    <Row>
                                        <Col>
                                            <SyntheseUtilisateur
                                                rangeDate={this.state.ranges}
                                                synthese_utilisateur={synthese_utilisateur}
                                                nameEtablissement={etablissements_from_store && etablissements_from_store.length>1?null:etablissements + '-' + societe}  />
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div className="custom-chart">
                                <Col xl={12}>
                                    <Row>
                                        <Col>
                                            <SyntheseEnvironnementale ToggleSyntheseEnvironnementalePeriode={this.props.ToggleSyntheseEnvironnementalePeriodeAction}
                                                                      client={isAdmin(user.scope.role)?data_admins
                                                                          : isSociete(user.scope.role)?data_societe
                                                                              : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                                                  :null}
                                                                      series={synthese_environnementale_series}
                                                                      categories={synthese_environnementale_categories} />
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                            <div className="custom-chart">
                                {/*Gaspillage alimentaire*/}
                                <Col>
                                    <Row>
                                        <Col md={(data_admins  && data_admins.etablissementsId.length===1 && !data_admins.etablissementsId.includes(0))
                                            || (data_societe && data_societe.etablissementsId.length===1 && !data_societe.etablissementsId.includes(0))
                                            || this.state.data_etablissement!==null ?8 : 12 }>
                                            <PoidsAnalyse TogglePoidsAnalyticsGraph={this.props.TogglePoidsAnalyticsGraphAction}
                                                          client={isAdmin(user.scope.role)?data_admins:
                                                              isSociete(user.scope.role)?data_societe
                                                                  : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                                      :null}
                                                          series={analyse_poids_series}
                                                          categories={analyse_poids_categories} />
                                        </Col>
                                        {
                                        (data_admins  && data_admins.etablissementsId.length===1 && !data_admins.etablissementsId.includes(0))
                                        || (data_societe && data_societe.etablissementsId.length===1 && !data_societe.etablissementsId.includes(0))
                                            || this.state.data_etablissement!==null?
                                            <Col md={4}>
                                                <CouvertsMensuels AddCouvertNumber={this.props.AddCouvertNumberAction}
                                                                  user={isAdmin(user.scope.role)?data_admins
                                                                      : isSociete(user.scope.role)?data_societe
                                                                          : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                                              :null}
                                                                  couverts={couverts_number}/>
                                            </Col>:
                                            null}
                                    </Row>
                                </Col>
                            </div>
                        </Row>
                        {/*Détails opérationnels */}
                        <Row>
                            <div className="custom-chart">
                                <Col>
                                    <DetailsOperationnels ToggleDetailsOperationnelsGraph={this.props.ToggleDetailsOperationnelsGraphAction}
                                                          client={isAdmin(user.scope.role)?data_admins
                                                              : isSociete(user.scope.role)?data_societe
                                                                  : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                                      :null}
                                                          series={details_operationnels_series}
                                                          categories={details_operationnels_categories} />
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div className="custom-chart">
                                <Col>
                                    <AleasLogistiques ToggleAleasLogistiquesGraph={this.props.ToggleAleasLogistiquesGraphAction}
                                                      client={isAdmin(user.scope.role)?data_admins
                                                          : isSociete(user.scope.role)?data_societe
                                                              : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                                  :null}
                                                      series={aleas_logistique_series}
                                                      categories={aleas_logistique_categories}/>
                                </Col>
                            </div>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {GetDetailsOperationnelsGraphAction
    ,GetPoidsAnalyseGraphAction,GetCouvertsNumbersAction,GetSyntheseEnvironnementaleGraphAction,AddCouvertNumberAction,
    ToggleDetailsOperationnelsGraphAction,ToggleSyntheseEnvironnementalePeriodeAction,
    GetSyntheseUtilisateurAction, TogglePoidsAnalyticsGraphAction,GetAleasLogistiquesGraphAction,ToggleAleasLogistiquesGraphAction};

function mapStateToProps(state) {
    return {
        user:state.LoginUserReducer.user,
        details_operationnels_series:state.GetDetailsOperationnelsGraphReducer.series,
        details_operationnels_categories:state.GetDetailsOperationnelsGraphReducer.categories,
        analyse_poids_series:state.GetPoidsAnalyseGraphReducer.series,
        analyse_poids_categories:state.GetPoidsAnalyseGraphReducer.categories,
        aleas_logistique_series:state.GetAleasLogistiquesGraphReducer.series,
        aleas_logistique_categories:state.GetAleasLogistiquesGraphReducer.categories,
        couverts_number:state.GetCouvertsNumbersReducer.couverts_number,
        synthese_environnementale_series:state.GetSyntheseEnvironnementaleGraphReducer.series,
        synthese_environnementale_categories:state.GetSyntheseEnvironnementaleGraphReducer.categories,
        synthese_utilisateur:state.GetSyntheseUtilisateurReducer.synthese_utilisateur,
        startDate:state.ChangeDateRangeReducer.startDateDashboard,
        endDate:state.ChangeDateRangeReducer.endDateDashboard,
        data_admins:state.SetDataForAdminReducer.data_to_admin,
        data_societe:state.SetDataForSocieteReducer.data_to_societe,
        societe_for_admin:state.GetEtablissementsForAdminReducer.societe_for_admin,
        etablissements_from_store:state.AddEtablissementsToStoreReducer.etablissements,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
