import React from "react";

export function consignesCollectesElementsGenerator ( items){
    let consignes_collectes_list = [];
    Object.keys(items).forEach(function (itemKey) {
        consignes_collectes_list.push(
            {
                id:items[itemKey]['id'],
                societeId:items[itemKey]['societes']['id'],
                etablissement_name:items[itemKey]['etablissement_name'],
                consignes:items[itemKey]['consignes'],
                code_etablissement:items[itemKey]['code_etablissement'],
                etablissement_horaire:items[itemKey]['etablissement_horaire']?items[itemKey]['etablissement_horaire'].map(item=>{
                    return <div key={Math.random()*1001+1}>
                    <li>{item.jour_collecte} {item.horaire_start!=="" && item.horaire_start!==""?
                        " : de "+item.horaire_start.substring(0,5)+ " au "+ item.horaire_end.substring(0,5)
                    : null} </li>
                </div>;
                }):null,
                societe:items[itemKey]['societes']['nom_societe'],
            }
        );
    });
    return consignes_collectes_list;
}
