import { call, put } from 'redux-saga/effects';
import * as types from "../actions/index";
import {getBackendToken, getCurrentSessionTokens} from "../services/authService";

export function* postLoginActions(email: string) : Generator<any, void, any> {
    try {
        // Get current session tokens
        const { idToken, refreshToken } = yield call(getCurrentSessionTokens);

        // Get a personalized token from your backend
        const personalizedToken = yield call(getBackendToken, idToken,refreshToken, email);

        // Store the personalized token in local storage

        // Dispatch a successful login action to update the Redux store
        yield put({
            type: types.LOGIN_USER_SUCCESS,
            response: {
                isAuth: true,
                NEW_PASSWORD_REQUIRED: false,
                user: personalizedToken.user,
                message: personalizedToken.message,
                success: personalizedToken.success,
                timestamp: personalizedToken.timestamp,
            }
        });
        window.location.reload();

    } catch (error) {
        if(error instanceof Error)
            // If anything goes wrong, you can handle errors here.
            yield put({ type: types.LOGIN_USER_ERROR, error: error.message });
    }
}
