import * as types from "../../actions";
import {LoginInterfaceResponse} from "../../interfaces/authInterface/loginInterface/loginInterfaceResponse";


const initialState: any = {
    isAuthenticated:false,
}
export const CheckStatusReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.CHECK_STATUS_SUCCESS:
            return {
                ...state,
                isAuthenticated: response.isAuthenticated,
            };
        case types.CHECK_STATUS_ERROR:
            return {
                ...state,
                isAuthenticated: response.isAuthenticated,
            };
        default:
            return state;
    }
}
;
