export class SyntheseUtilisateur {
    tonnes_biodechets: number;
    moyenne_couverts: number;
    carbonne: number;
    production_gaz_tonne: number;
    production_gaz_fetiliz: number;
    production_gaz_energie: number;
    consommation_energie_habitants: number;
    production_organique_fertilis: number;
    qte_couverts_mois: number;
    constructor(tonnes_biodechets:number,moyenne_couverts:number,carbonne:number,production_gaz_tonne:number
                ,production_gaz_fetiliz:number,production_gaz_energie:number,consommation_energie_habitants:number,
                production_organique_fertilis:number,
                qte_couverts_mois:number) {
        this.tonnes_biodechets=tonnes_biodechets;
        this.moyenne_couverts=moyenne_couverts;
        this.carbonne=carbonne;
        this.production_gaz_tonne=production_gaz_tonne;
        this.production_gaz_fetiliz=production_gaz_fetiliz;
        this.production_gaz_energie=production_gaz_energie;
        this.consommation_energie_habitants=consommation_energie_habitants;
        this.production_organique_fertilis=production_organique_fertilis;
        this.qte_couverts_mois=qte_couverts_mois;
    }
}