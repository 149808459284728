import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "../../../../assets/scss/dashboard.scss";
import {optionsForReportingCoutGraph} from "../../../../Constansts/optionsForFacturesGraph";
import {optionsForSyntheseEnvironnementaleGraph} from "../../../../Constansts/optionsForDashboardGraph";

class CoutsAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButtonId: "num_sem"
        }

    }
    setActiveButton=(id)=>{
        if(id!== this.state.activeButtonId && this.props.client) {
            const data=this.props.client;
            data['type']=id;
            this.setState({activeButtonId: id});
            this.props.ToggleCoutsAnalyticsPeriode(data);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light"  className={this.state.activeButtonId === "num_sem"? "active" : ""} onClick={() => this.setActiveButton("num_sem")} type="button">Semaine</Button>
                                <Button size="sm" color="light" className={this.state.activeButtonId === "num_mois"? "active" : ""} onClick={() => this.setActiveButton("num_mois")} type="button">Mois</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Détails des coûts </h4>
                        {this.props.series && this.props.series.length > 0 && this.props.categories && this.props.categories.length > 0 ? (
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                <ReactApexChart width={"100%"} options={optionsForReportingCoutGraph(this.props.categories)} series={this.props.series}
                                                type="line" height={350} />
                            </div>
                        ) : (
                            <p>Aucune donnée disponible.</p>
                        )}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default CoutsAnalytics;