
import React from "react";
import {TableColumn} from "react-data-table-component";
import "reactstrap";
type ConsignesCollectesDataRow = {
    id:number;
    etablissement_name:string;
    consignes:string;
    etablissement_horaire:string;
    societe:string;
    code_etablissement:string;
};


export function ConsignesCollectesListColumns(hideElements:any,handleConsigne:any):TableColumn<ConsignesCollectesDataRow>[]{
    return [
        {
            name: 'Code établissement',
            selector: row => row.code_etablissement,
            sortable:true,
            omit:hideElements[0].hideId,
            reorder:true,
        },
        {
            name: 'Société',
            selector: row => row.societe,
            sortable:true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
        },
        {
            name: "Nom d'établissement",
            selector: row => row.etablissement_name,
            sortable:true,
            omit:hideElements[2].hideSociete,
            reorder:true,
        },
        {
            name: 'Horaire de collecte',
            selector: row => row.etablissement_horaire,
            sortable:true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
        },
        {
            name: 'Consignes',
            selector: row => row.consignes,
            sortable: true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
            cell: (row) => {
                return (
                         <div className="row mb-2" key={row.id}>
                                 <div className="col-sm-10" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                     e.stopPropagation();}}>
                                     <textarea rows={4} defaultValue={row.consignes}
                                               name="consignes"
                                               className="form-control"
                                               data-row-id={row.id}

                                     />
                                 </div>
                                 <div className="col-sm-2">
                                     <button className="btn btn-sm btn-primary mt-2" onClick={(e) => handleConsigne(e,row)}>
                                         <i className="ri-check-line"/>
                                     </button>
                                 </div>
                         </div>
                );

            },
        },
    ];
}
