import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import {Route, Router} from "react-router-dom";
import configureStore from './store/configureStore';
import App from './App';
import history from './helpers/history'
import registerServiceWorker from "./config/registerServiceWorker";
import {Amplify} from 'aws-amplify';
import {PersistGate} from "redux-persist/integration/react";
import {config} from './aws-export';

const {store,persistor} = configureStore();
Amplify.configure(config);



store.subscribe(() => {
});
ReactDOM.render(
    <Router history={history} key={Math.random()}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Route component={App}  />
            </PersistGate>
        </Provider>
    </Router>
    ,document.getElementById('root'));
registerServiceWorker();


