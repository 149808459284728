import * as types from "../index";
import { factures_couts_graph, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetReportingCoutsGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_REPORTING_COUT_GRAPH,
        url:Url + factures_couts_graph ,
        dataPayload:data_to_send
    }
};
