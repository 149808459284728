import * as types from "../../actions";
import {
    GetReportingCoutsGraphInterfaceResponse
} from "../../interfaces/facturesInterface/GetReportingCoutsGraphInterface";

const initialState: GetReportingCoutsGraphInterfaceResponse = {
    series:[],
    categories:[]
}
export const GetReportingCoutGraphReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_REPORTING_COUT_GRAPH_SUCCESS:
            return {
                ...state,
                series: response.series,
                categories: response.categories,
            };
        case types.GET_REPORTING_COUT_GRAPH_ERROR:
            return {
                ...state,
                error:response.error
            };
        default:
            return state;
    }
}
;
