import * as types from "../../actions";
import {AddCouvertsNumbersInterfaceResponse} from "../../interfaces/dashboardInterface/AddCouvertsNumbersInterface";

const initialState: AddCouvertsNumbersInterfaceResponse = {
    message:""
}
export const AddCouvertsNumbersReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.ADD_COUVERTS_NUMBERS_SUCCESS:
            return {
                ...state,
                message: response.message,
            };
        case types.ADD_COUVERTS_NUMBERS_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
