import Dashboard from "../../components/sectionPages/dashboard/main";
import Login from "../../components/auth/login/main";
import Profil from "../../components/sectionPages/profil/main";
import Dacs from "../../components/sectionPages/dac/main";
import Factures from "../../components/sectionPages/factures/main";
import ConsignesCollectes from "../../components/sectionPages/etablissements/consignesCollectes/main";
import HoraireOuverture from "../../components/sectionPages/etablissements/horaireOuverture/main";



export const privateComponents = {
    DashboardPage:Dashboard,
    ProfilPage:Profil,
    DacsPage:Dacs,
    FacturesPage:Factures,
    ConsignesCollectesPages:ConsignesCollectes,
    HoraireOuverturePages:HoraireOuverture

};
export const publicComponents  = {
    LoginPage:Login,

};