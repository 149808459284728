import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import { post} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"
import {GetConsignesCollectesListInterface, GetConsignesCollectesListInterfaceResponse} from "../../interfaces/etablissements/consignesCollectesInterface/GetConsignesCollectesListInterface";



export function* GetConsignesCollectesListSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await post<GetConsignesCollectesListInterface>(payload.url,payload.dataPayload, headers);
            };
            let response, parsedBody: GetConsignesCollectesListInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetConsignesCollectesListInterfaceResponse= parsedBody.data;
                            yield put({
                                type: types.GET_CONSIGNES_COLLECTES_LIST_SUCCESS,
                                response: {
                                    consignes_collectes:data.consignes_collectes,
                                    }
                                });
                        }
                        break;
                    case 400:
                        break;
                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        if(parsedBody.status ==="Token is Invalid")
                        {
                            // logout();
                        }
                        break;
                }
            }
            else {
                yield put({type: types.GET_CONSIGNES_COLLECTES_LIST_ERROR, error: response.status})
            }


    } catch (error) {
        yield [
            put({type: types.GET_CONSIGNES_COLLECTES_LIST_ERROR, error}),
        ];
    }
}
