import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {get, post} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions"
import {
    GetReportingCoutsWidgetsInterface,
    GetReportingCoutsWidgetsInterfaceResponse
} from "../../interfaces/facturesInterface/GetReportingCoutsWidgetsInterface";


export function* GetReportingCoutsWidgetsSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await post<GetReportingCoutsWidgetsInterface>(payload.url,payload.dataPayload, headers);
            };
            let response, parsedBody: GetReportingCoutsWidgetsInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetReportingCoutsWidgetsInterfaceResponse= parsedBody.data;
                            yield put({
                                    type: types.GET_REPORTING_COUT_WIDGETS_SUCCESS, response: {
                                        couts_widgets: data.couts_widgets
                                    }
                                });
                        }
                        break;
                    case 404:
                        break;
                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        if(parsedBody.status ==="Token is Invalid")
                        {
                            // logout();
                        }
                        break;
                }
            }
            else {
                yield [
                    put({type: types.GET_REPORTING_COUT_WIDGETS_ERROR, error: response.status}),
                ];
            }


    } catch (error) {
        yield [
            put({type: types.GET_REPORTING_COUT_WIDGETS_ERROR, error}),
        ];
    }
}
