import * as types from "../../../actions";
import {
    GetSocietesForAdminInterfaceResponse
} from "../../../interfaces/roleManagementInterface/getSocietesForAdminInterface";


const initialState: GetSocietesForAdminInterfaceResponse = {
    societes:[]
}
export const GetSocietesForAdminReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_SOCIETES_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                societes:response.societes
            };
        case types.GET_SOCIETES_FOR_ADMIN_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
