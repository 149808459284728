import {call, put} from "redux-saga/effects";
import {headers} from "../../../utils/headers";
import {get} from "../../../helpers/service/httpSpecificFunctions";
import * as types from "../../../actions"

import {
    GetSocietesForAdminInterface,
    GetSocietesForAdminInterfaceResponse
} from "../../../interfaces/roleManagementInterface/getSocietesForAdminInterface";
import {SetDataWithSocieteAndEtablissements} from "../../../Models/roleManagement/setDataWithSocieteAndEtablissements";


export function* AddEtablissementsToStoreSaga(payload: any):any {
    try {
        if(payload.dataPayload){
            const data: { value: number, label: string } = payload.dataPayload;
            yield put({
                type: types.ADD_ETABLISSEMENTS_TO_STORE_SUCCESS,
                response: {
                    etablissements: data
                }
            });
        }
    } catch (error) {
        yield [
            put({type: types.ADD_ETABLISSEMENTS_TO_STORE_ERROR, error}),
        ];
    }
}
