import * as types from "../index";
import {add_horaire_ouverture, Url} from "../../Env/env";
import {AddHoraireOuverture} from "../../Models/etablissements/addHoraireOuverture";

export const AddHoraireOuvertureAction = (data:AddHoraireOuverture) => {
    return {
        type: types.ADD_HORAIRES_OUVERTURE,
        url:Url + add_horaire_ouverture,
        dataPayload:data

    }
};
