import React, {Component} from 'react';
import {connect} from "react-redux";
import {LoginForm} from "../../../../Models/authForm/loginForm";
import {LoginUserAction} from "../../../../actions/auth/LoginUserAction";
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

import {Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// import images
import logodark from "../../../../assets/images/logo-dark.png";
import logolight from "../../../../assets/images/logo-light.png";
import Swal from "sweetalert2";


class LoginPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            multiValue: [],
            multiValueSelect: []
        };
    }
    onHandleLogin = (event) => {
        event.preventDefault();
        let email = event.target.email.value;
        let password = event.target.password.value;
        let data = new LoginForm(email, password);
        this.props.LoginUserAction(data);
    };

    componentDidMount() {
        document.body.classList.add("auth-body-bg");
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.success && this.props.message!=="" && this.state.isSubmit && this.props.timestamp!==prevProps.timestamp){
            this.setState({isSubmit:false})
            Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            }).fire({
                icon: 'success',
                title: this.props.message
            });
        }
        else if(!this.props.success && this.props.message!=="" && this.state.isSubmit && this.props.timestamp!==prevProps.timestamp){
            this.setState({isSubmit:false})
            Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            }).fire({
                icon: 'error',
                title: this.props.message
            });
        }
    }



    render() {
            const {isAuth,message}=this.props;
            return (
                    <>

                        {!isAuth &&
                            <React.Fragment>
                                <div>
                                    <Container fluid className="p-0">
                                        <Row className="g-0">
                                            <Col lg={4}>
                                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                                    <div className="w-100">
                                                        <Row className="justify-content-center">
                                                            <Col lg={9}>
                                                                <div>
                                                                    <div className="text-center">
                                                                        <div>
                                                                            <Link to="/" >
                                                                                <img src={logodark} alt="" height="20" className="auth-logo logo-dark mx-auto" />
                                                                                <img src={logolight} alt="" height="20" className="auth-logo logo-light mx-auto" />
                                                                            </Link>
                                                                        </div>

                                                                        <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                                        <p className="text-muted">Sign in to continue to portail client.</p>
                                                                    </div>

                                                                    {message && message ? <Alert color="danger">{message.split('.')[0]}</Alert> : null}

                                                                    <div className="p-2 mt-5">
                                                                        <AvForm className="form-horizontal" onValidSubmit={this.onHandleLogin} >

                                                                            <div className="auth-form-group-custom mb-4">
                                                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                                <Label htmlFor="username">Email</Label>
                                                                                <AvField name="email"  type="text" className="form-control" id="username" validate={{ email: true, required: true }} placeholder="Enter username" />
                                                                            </div>

                                                                            <div className="auth-form-group-custom mb-4">
                                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                                <Label htmlFor="userpassword">Password</Label>
                                                                                <AvField name="password"  type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                                            </div>

                                                                            <div className="form-check">
                                                                                <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                                                <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                                                                            </div>

                                                                            <div className="mt-4 text-center">
                                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                                            </div>

                                                                            {/*<div className="mt-4 text-center">
                                                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                                            </div>*/}
                                                                        </AvForm>
                                                                    </div>


                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <div className="authentication-bg">
                                                    <div className="bg-overlay"></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </React.Fragment>
                        }

                    </>
        )

    }
}


const mapDispatchToProps = {LoginUserAction};

function mapStateToProps(state) {
    return {
        isAuth:state.LoginUserReducer.isAuth,
        success:state.LoginUserReducer.success,
        timestamp:state.LoginUserReducer.timestamp,
        message:state.LoginUserReducer.message,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);