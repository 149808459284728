import React, { Component } from 'react';
import {columnVisibilty, dacsListColumnsMaterial} from "../../../../Constansts/dacsListColumns";
import {dacsListElementGenerator, processRow} from "../../../../utils/dacs/dacsList";
import MaterialReactTable from "material-react-table";
import {MRT_Localization_FR} from "material-react-table/locales/fr";
import {Box, Button} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {  generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';

class DacsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
      }
    downloadPDF=(id)=>{
     this.props.CreatePDFDac(id);
    }
    handleExportData = () => {
        const csvConfig = {
            fieldSeparator: ',',
            decimalSeparator: '.',
            showColumnHeaders:true,
            columnHeaders :[
                {key:'collecteur_prevu',displayLabel:"Collecteur prévu"},
                {key:'collecteur_effectif',displayLabel:"Collecteur effectif"},
                {key:'nom_etablissement',displayLabel:"Nom établissement"},
                {key:'code_etablissement',displayLabel:"Code établissement"},
                {key:'adresse',displayLabel:"Adresse"},
                {key:'cp',displayLabel:"CP"},
                {key:'ville',displayLabel:"Ville"},
                {key:'date_presta',displayLabel:"Date prestation"},
                {key:'type_presta',displayLabel:"Type prestation"},
                {key:'motif',displayLabel:"Motif"},
                {key:'quantite',displayLabel:"Quantité"},
                {key:'volume_total',displayLabel:"Volume Total"},
                {key:'poids',displayLabel:"Poids(Kg)"},
                {key:'origine_mesure',displayLabel:"Origine Mesure"},
                {key:'temps_attente',displayLabel:"Temps d'attente"},],
            useKeysAsHeaders: false
        };

        const dataToExport = dacsListElementGenerator(this.props.dacs);
        const processedData = dataToExport.map(row => processRow(row)); // Process the row data
        const csv = generateCsv(csvConfig)(processedData);
        download(csvConfig)(csv);
    };
    handleExportToExcel = () => {
        const dataToExport = dacsListElementGenerator(this.props.dacs);
        const processedData = dataToExport.map(row => processRow(row)); // Process the row data

        // For Excel Export
        const worksheet = XLSX.utils.json_to_sheet(processedData);
        XLSX.utils.sheet_add_aoa(worksheet, [
            ["Collecteur prévu","Collecteur effectif", "Nom établissement","Code établissement", "Adresse", "CP", "Ville",
                "Date prestation", "Type prestation", "Motif", "Quantité", "Volume Total", "Poids(Kg)",
                "Origine Mesure", "Temps d'attente"]
        ], {origin: 'A1'});
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcel(excelBuffer, "exported_data.xlsx");
    };

    saveAsExcel = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    };
    renderTopToolbarCustomActions = () => (
        <Box
            sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
            }}
        >
            <Button
                onClick={this.handleExportData}
                startIcon={<FileDownloadIcon />}
            >
                Export as CSV
            </Button>
            <Button
                onClick={this.handleExportToExcel}
                startIcon={<FileDownloadIcon />} // You may want to use a different icon for Excel
            >
                Export as Excel
            </Button>
        </Box>
    );
  render() {
    return (
      <React.Fragment>
            <div className="col-sm-12 mt-2">
              <MaterialReactTable
                  columns={dacsListColumnsMaterial(this.downloadPDF)}
                  data={this.props.dacs && dacsListElementGenerator(this.props.dacs)}
                  enableColumnResizing
                  enableColumnOrdering
                  columnResizeMode="onChange" //default
                  initialState={{ density: 'compact' ,columnVisibility:columnVisibilty}}
                  enableStickyHeader
                  enableStickyFooter
                  localization={MRT_Localization_FR}
                  muiTableContainerProps={{ sx: { maxHeight: '550px' } }}
                  renderTopToolbarCustomActions={this.renderTopToolbarCustomActions}

              />
            </div>
      </React.Fragment>
    );
  }
}
export default DacsTable;
