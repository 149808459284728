import React, { Component } from 'react';
import {Button, ButtonGroup, Card, CardBody} from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "../../../../assets/scss/dashboard.scss";
import {optionsForFacturesGraph, optionsForReportingCoutGraph} from "../../../../Constansts/optionsForFacturesGraph";

class CoutsAvgGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButtonId: "num_sem"
        }
    }
    setActiveButton=(id)=>{
        if(id!== this.state.activeButtonId && this.props.client) {
            const data=this.props.client;
            data['type']=id;
            this.setState({activeButtonId: id});
            this.props.ToggleCoutAvgGraph(data);
        }
    }
    render() {
        let maxLeft,maxRight=0;

        // Check if this.props.series is not empty
        if (this.props.series && this.props.series.length > 0) {
            maxLeft=this.props.series[1]&& this.props.series[2]?
                Math.max(...this.props.series[1].data
                    ,...this.props.series[2].data):0;
            maxRight=this.props.series[0] ?
                Math.max(...this.props.series[0].data):0;
        }
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light"  className={this.state.activeButtonId === "num_sem"? "active" : ""} onClick={() => this.setActiveButton("num_sem")} type="button">Semaine</Button>
                                <Button size="sm" color="light" className={this.state.activeButtonId === "num_mois"? "active" : ""} onClick={() => this.setActiveButton("num_mois")} type="button">Mois</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Coûts</h4>
                        {this.props.series && this.props.series.length > 0 && this.props.categories && this.props.categories.length > 0 ? (
                            <div>
                                <div id="line-column-chart" className="apex-charts" dir="ltr">
                                    <ReactApexChart width={"100%"} options={optionsForFacturesGraph(this.props.categories,maxLeft,maxRight)}
                                                    series={this.props.series} type="line" height={350} />
                                </div>
                            </div>
                        ) : (
                            <p>Aucune donnée disponible.</p>
                        )}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
export default CoutsAvgGraph;
