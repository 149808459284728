import * as types from "../index";
import { toggle_factures_graph, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";


export const ToggleCoutAvgGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.TOGGLE_FACTURES_GRAPH,
        url:Url + toggle_factures_graph ,
        dataPayload:data_to_send
    }
};
