export const optionsForReportingCoutGraph=(categories:string[])=>{
       return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false
        },
           colors: [
            "#008ffb",
            "#66dc0b",
            "#775dd0",
            "#e00000",
            "#FEB019",
            "#2204ce",
           ],
           markers: {
            size: [5,5,5,5]
           },
           dataLabels: {
               enabled: true,
               enabledOnSeries: [0],
               offsetY: -10,
               background: {
                   enabled: false,
               },
               formatter: (val: string) => {
                   return Number(val).toFixed(2)+' €'
               },
           },
           stroke: {
            width: [1,3,3,3,3,3]
        },
           xaxis: {
            categories:categories,
        },
           yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return Number(val).toLocaleString()+' €'
                    },
                },

            },
            {
                seriesName: 'Coût Traitement',
                max:100,
                opposite:true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return Number(val).toLocaleString()+'%'
                    }
                },
            },
            {
                seriesName: 'Coût Collecte',
                show:false,
                max:100,
                opposite:true,

                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return Number(val).toLocaleString()+'%'
                    }
                },
            },
            {
                opposite:true,
                max:100,
                show:false,
                seriesName: 'Coûts d\'Attente',
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return Number(val).toLocaleString()+'%'
                    }
                },
            },
            {
                seriesName: 'Coût Passage à vide',
                show:false,
                opposite:true,
                max:100,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return Number(val).toFixed(2)+'%'
                    }
                },
            },
            {
                show:false,
                opposite:true,
                max:100,
                seriesName: 'Coût Location Bac',
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return Number(val).toFixed(2)+'%'
                    }
                },
            }
        ],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
}
export const optionsForFacturesGraph=(categories:string[],maxLeft:number,maxRight:number)=>{
    return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false,
            dataLabels:{
                position:'top'
            }
        },
        colors: [
            "#008ffb",
            "#775dd0",
            "#228500",
        ],
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            offsetY: -10,
            background: {
                enabled: false,
            },
            formatter: (val: string) => {
                return (Number(val).toFixed(2)).toLocaleString()+' €'
            },
        },
        stroke: {
            width: [3,3,3]
        },
        markers: {
            size: [5,5]
        },
        xaxis: {
            categories:categories,
        },
        yaxis: [
            {   show:true,
                opposite:true,
                seriesName: 'Coût par tonne',
                axisTicks: {
                    show: false,
                },
                max:maxRight,
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return (Number(val).toFixed(2)).toLocaleString()+' €'
                    }
                },
                title: {
                    text: "Coût par tonne en €",
                    style: {
                        color: '#000000',
                    }
                },
            },
            {
                show:true,
                seriesName: 'Coût par bac',
                max:maxLeft,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return (Number(val).toFixed(2)).toLocaleString()+' €'
                    }
                },
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                title: {
                    text: "Coût en €",
                    style: {
                        color: '#000000',
                    }
                },
            },
            {
                seriesName: 'Coût par passage',
                show:false,
                max:maxLeft,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => {
                        return (Number(val).toFixed(2)).toLocaleString()+' €'
                    }
                },

            },
        ],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
};
export const optionForMoyenCoutCouvert=(categories:string[])=>{
    return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false
        },
        markers: {
            size: [5]
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            offsetY: -10,
            background: {
                enabled: false,
            },
            formatter: (val: string) => {
                return Number(val)+' € / couvert'
            },
        },
        stroke: {
            width: [2]
        },
        xaxis: {
            categories:categories,
        },
        yaxis: {
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#000000'
            },
            labels: {
                style: {
                    colors: '#000000',
                },
                formatter: (val: string) => {
                    return Number(val).toLocaleString()+' € / couvert'
                },
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
}
