import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "../../../../assets/scss/dashboard.scss";
import {
    optionsForDetailsOperationnelsGraph, optionsForSyntheseEnvironnementaleGraph
} from "../../../../Constansts/optionsForDashboardGraph";

class DetailsOperationnels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMonth:false,
            activeButtonId:"num_sem",
        }
    }

    setActiveButton=(id)=>{
        if(id!== this.state.activeButtonId && this.props.client) {
            const data=this.props.client;
            data['type']=id;
            this.setState({activeButtonId: id});
            this.props.ToggleDetailsOperationnelsGraph(data);
        }
    }
    render() {
        let maxLeftAxe,maxRightAxe=0;

        if (this.props.series && this.props.series.length > 0) {
            maxLeftAxe = this.props.series[0] && this.props.series[1]
                ? Math.max(...this.props.series[0].data, ...this.props.series[1].data)
                : 0;
            maxRightAxe=this.props.series[2] ? Math.max(...this.props.series[2].data ):0;
        }
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light"  className={this.state.activeButtonId === "num_sem"? "active" : ""} onClick={() => this.setActiveButton("num_sem")} type="button">Semaine</Button>
                                <Button size="sm" color="light" className={this.state.activeButtonId === "num_mois"? "active" : ""} onClick={() => this.setActiveButton("num_mois")} type="button">Mois</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Détails opérationnels </h4>
                        {this.props.series && this.props.series.length > 0 && this.props.categories && this.props.categories.length > 0 ? (
                            <div >
                                <div id="line-column-chart" className="apex-charts" dir="ltr">
                                    <ReactApexChart width={"100%"} options={optionsForDetailsOperationnelsGraph(this.props.categories,maxLeftAxe,maxRightAxe)}
                                                    series={this.props.series} type="line" height={450} />
                                </div>
                            </div>
                        ) : (
                            <p>Aucune donnée disponible.</p>
                        )}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default DetailsOperationnels;
