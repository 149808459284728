import { put} from "redux-saga/effects";
import * as types from "../../../actions"
import {SetDataWithSocieteAndEtablissements} from "../../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export function* SetDataForSocieteSaga(payload: any):any {
    try {
            const data : SetDataWithSocieteAndEtablissements=payload.dataPayload;
            yield put({
                type: types.SET_DATA_FOR_SOCIETE_SUCCESS,
                response: {
                    data_to_societe:data
                }
            });

    } catch (error) {
        yield [
            put({type: types.SET_DATA_FOR_SOCIETE_ERROR, error}),
        ];
    }
}