
export const optionsForDetailsOperationnelsGraph=(categories:string[],maxLeftAxe:number,maxRightAxe:number)=>{
    return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false,
            dataLabels: {
                position: 'top'
            }
        },
        colors: [
            "#008ffb",
            "#ff4560",
            "#775dd0",
        ],
        dataLabels: {
            enabled: true,
            enabledOnSeries: [2],
            offsetY:-13,
            formatter: (val: string) => (Number(val).toLocaleString()+' Kg'),
            background: {
                enabled: false,
            },
        },
        markers: {
            size: [5,5]
        },
        stroke: {
            width: [3,3,1]
        },
        xaxis: {
            categories:categories,
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                max:maxLeftAxe,
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                title: {
                    text: "Quantite",
                    style: {
                        color: '#000000',
                    }
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: function (val:number) {
                        return val.toFixed(0).toLocaleString();
                    }
                },
            },
            {
                seriesName: 'Nombre de collectes',
                show:false,
                max:maxLeftAxe,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: function (val:number) {
                        return val.toFixed(0).toLocaleString();
                    }
                },
            },
            {
                seriesName: 'Poids collecté',
                opposite: true,
                max:maxRightAxe,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toLocaleString()+' Kg'),

                },
                title: {
                    text: "Poids en kg",
                    style: {
                        color: '#000000',
                    },

                },
            },
        ],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
};
export const optionsForAleasLogistiqueGraph=(categories:string[],leftAxeMax:number)=>{
    return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false,
            dataLabels: {
                position: 'top'
            }
        },
        colors: [
            "#008ffb",
            "#ff4560",
            "#775dd0",
        ],
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0,1],
            offsetY: -10,
            background: {
                enabled: false,
            },
            formatter: function (val:number) {
                return val;
            }
        },
        stroke: {
            width: [1,1,3]
        },
        xaxis: {
            categories:categories,
        },
        markers: {
            size: [5]
        },
        yaxis: [
            {
                seriesName: 'Nombre de passage à vide',
                max:leftAxeMax,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                title: {
                    text: "Quantite",
                    style: {
                        color: '#000000',
                    }
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: function (val:number) {
                        return val.toFixed(0);
                    }
                },
            },
            {
                seriesName: 'Nombre de Temps d’attente >6 min',
                show:false,
                max:leftAxeMax,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: function (val:number) {
                        return val.toFixed(0);
                    }
                },
            },
            {
                seriesName: '% de pesée embarquée',
                max:100,
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter:  (val:string)=> {
                        return Number(val).toFixed(2) +'%';
                    }
                },
                title: {
                    text: "% de pesée embarquée",
                    style: {
                        color: '#000000',
                    }
                },
            },
        ],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
};


export const optionsForAnalysePoidsGraph=(categories:string[],maxValue:number)=>{
    return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false,
        },
        colors: [
            "#008ffb",
            "#ff4560",
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3,3]
        },
        xaxis: {
            categories:categories,
        },
        yaxis:[{
            axisTicks: {
                show: true,
            },
            max:maxValue,
            axisBorder: {
                show: true,
                color: '#000000'
            },
            labels: {
                style: {
                    colors: '#000000',
                },
                formatter: (val: string) => (Number(val).toFixed(2).toLocaleString()+' Kg'),
            },
            title: {
                text: "Poids en kg",
                style: {
                    color: '#000000',
                }
            },
        },{
            seriesName: 'Poids moyen par bac collecté',
            show:false,
            max:maxValue,
            labels: {
                style: {
                    colors: '#000000',
                },
                formatter: (val: string) => (Number(val).toFixed(2).toLocaleString()+' Kg'),
            },
        },],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
};

export const optionsPoidsAnalyseMonthlyGraph=(categories:string[],maxLeftAxe:number,maxRightAxe:number)=>{
    return {
        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false,
            dataLabels:{
                position:'top'
            }
        },

        colors: [
            "#17279a",
            "#ff4560",
            "#69e7d5",
        ],

        markers: {
            size: [5,5]
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [2],
            offsetY:-15,
            formatter: (val: string) => (Number(val).toFixed(2).toLocaleString()+' gr'),
            background: {
                enabled: false,
            },
        },
        stroke: {
            width: [3,3,1]
        },
        xaxis: {
            categories:categories,
        },
        yaxis: [
            {
                seriesName: 'Poids moyen collecté par passage',
                axisTicks: {
                    show: true,
                },
                max:maxLeftAxe,
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toFixed(2).toLocaleString()+' Kg'),

                },
                title: {
                    text: "Poids en kg",
                    style: {
                        color: '#000000',
                    }
                },
            },
            {
                seriesName: 'Poids moyen par bac collecté',
                show:false,
                max:maxLeftAxe,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toFixed(2).toLocaleString()+' Kg'),

                },

            },
            {

                seriesName: 'Quantité moyenne par couvert',
                max:maxRightAxe,
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toFixed(2).toLocaleString()+' gr'),

                },
                title: {
                    text: "Poids en gr/couvert",
                    style: {
                        color: '#000000',
                    }
                },
            },
        ],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
};
export const optionsForSyntheseEnvironnementaleGraph=(categories:string[],maxLeftAxe:number,maxRightAxe:number)=>{
    return {

        chart: {
            toolbar: {
                tools: {
                    pan: false,
                }
            },
            height: 400,
            type: 'line',
            stacked: false,
            dataLabels: {
                position:'top'
            }
        },

        colors: [
            "#05ce7b",
            "#f5ad2c",
            "#17279a",
            "#503409",
        ],
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            offsetY:-15,
            formatter: (val: string) => (Number(val).toFixed(2)+' T'),
            background: {
                enabled: false,
            },
        },
        stroke: {
            width: [1,3,3,3]
        },
        xaxis: {
            categories:categories,
        },
        markers: {
            size: [5,5,5]
        },
        yaxis: [
            {
                seriesName: 'Poids collecté (T)',
                max:maxLeftAxe,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toFixed(2)+' T'),

                },
                yaxis:{
                    labels:{
                        formatter: (val: string) => (val+' T'),
                    }
                },
                title: {
                    text: "Tonnes & MWh",
                    style: {
                        color: '#000000',
                    }
                },
            },
            {
                seriesName: 'Quantité d’énergie ( MWh)',
                max:maxLeftAxe,
                show:false,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (val+' MWh'),
                },
            },
            {
                seriesName: 'CO2 économisé (kg)',
                max:maxRightAxe,
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toFixed(2)+' KgCO2'),

                },
                title: {
                    text: "Kg CO2 & Hectare",
                    style: {
                        color: '#000000',

                    }
                },
            },
            {
                opposite: true,
                seriesName: 'Surface fertilisée (Ha)',
                max:maxRightAxe,
                show:false,
                labels: {
                    style: {
                        colors: '#000000',
                    },
                    formatter: (val: string) => (Number(val).toFixed(2)+' Ha'),

                },
            },

        ],
        legend: {
            horizontalAlign: 'left',
            offsetX: 40,
            onItemClick: {
                toggleDataSeries: false
            },
        }
    }
};



