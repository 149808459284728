
import React from "react";
import {TableColumn} from "react-data-table-component";
type HoraireOuvertureDataRow = {
    id:number;
    etablissement_name:string;
    horaire_ouverture:string;
    societe:string;
    code_etablissement:string;
};


export function HoraireOuvertureListColumns(hideElements:any):TableColumn<HoraireOuvertureDataRow>[]{
    return [
        {
            name: 'Code établissement',
            selector: row => row.code_etablissement,
            sortable:true,
            omit:hideElements[0].hideId,
            reorder:true,
        },
        {
            name: 'Société',
            selector: row => row.societe,
            sortable:true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
        },
        {
            name: "Nom d'établissement",
            selector: row => row.etablissement_name,
            sortable:true,
            omit:hideElements[2].hideSociete,
            reorder:true,
        },
        {
            name: "Horaires d'ouverture",
            selector: row => row.horaire_ouverture,
            sortable:true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
        },
    ];
}
