import * as types from "../index";
import {consignes_collectes, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetConsignesCollecteListesAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_CONSIGNES_COLLECTES_LIST,
        url:Url + consignes_collectes,
        dataPayload:data_to_send

    }
};
