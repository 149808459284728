import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {post} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"

import {
    GetCouvertsNumbersInterface, GetCouvertsNumbersInterfaceResponse,

} from "../../interfaces/dashboardInterface/GetCouvertsNumbersInterface";


export function* GetCouvertsNumbersSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await post<GetCouvertsNumbersInterface>(payload.url,payload.dataPayload, headers);
            };
            let response, parsedBody: GetCouvertsNumbersInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetCouvertsNumbersInterfaceResponse= parsedBody.data;
                            yield put({
                                    type: types.GET_COUVERTS_NUMBERS_SUCCESS, response: {
                                        couverts_number: data.couverts_number
                                    }
                                });
                        }
                        break;
                    case 404:
                        break;

                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        if(parsedBody.status ==="Token is Invalid")
                        {
                            // //logout();
                        }
                        break;
                }
            }
            else {
                yield [
                    put({type: types.GET_COUVERTS_NUMBERS_ERROR, error: response.status}),
                ];
            }


    } catch (error) {
        yield [
            put({type: types.GET_COUVERTS_NUMBERS_ERROR, error}),
        ];
    }
}
