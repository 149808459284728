import React, { Component } from "react";

import { connect } from "react-redux";
import {

    Button, InputGroup

} from "reactstrap";

import { Link } from "react-router-dom";

// Import menuDropdown

import ProfileMenu from "../../../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";


// Redux Store
import { toggleRightSidebar } from "../../../../actions/layout/actions";
import { LogOutAction } from "../../../../actions/auth/LogOutAction";

//Import logo Images
import logosmdark from "../../../../assets/images/logo-sm-dark.png";
import logodark from "../../../../assets/images/logo-dark.png";
import logosmlight from "../../../../assets/images/logo-sm-light.png";
import logolight from "../../../../assets/images/logo-light.png";
import Flatpickr from "react-flatpickr";
import moment from "moment/moment";
import {ChangeDateRangeAction} from "../../../../actions/dashboard/changeDateRangeAction";


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isSocialPf: false,
            ranges:[
                moment().subtract(1, 'days').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD'),
            ]

        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleRightbar = this.toggleRightbar.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }

    /**
     * Toggles the sidebar
     */
    toggleRightbar() {
        this.props.toggleRightSidebar();
    }


    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }
    componentDidMount() {
        if(this.props.startDate && this.props.endDate!=="")
            this.setState({ranges:[this.props.startDate,this.props.endDate]});
        else
            this.props.ChangeDateRangeAction(this.state.ranges[0] , this.state.ranges[1])
    }
        handleDateRange=(e)=>{
        if(e.length===2) {
            this.setState({ranges:[moment(e[0]).format('YYYY-MM-DD'),moment(e[1]).format('YYYY-MM-DD')]})
            this.props.ChangeDateRangeAction(this.state.ranges[0] , this.state.ranges[1])
        }
    }

    render() {

        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex" style={{width: "70%"}}>
                            <div className="navbar-brand-box">
                                <Link to="#" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logosmdark} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logodark} alt="" height="20" />
                                    </span>
                                </Link>

                                <Link to="#" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logosmlight} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logolight} alt="" height="20" />
                                    </span>
                                </Link>
                            </div>
                            <Button size="sm" color="none" type="button" onClick={this.toggleMenu} className="px-3 font-size-24 header-item waves-effect" id="societe">
                                <i className="ri-menu-2-line align-middle"></i>
                            </Button>
                            <div className="app-range-date  d-lg-block" style={{width: "40%"}}>
                                <div className="position-relative">
                                    <InputGroup size={"sm"}>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder={'Date du'.concat(' ',moment(this.state.ranges[0]).format('DD-MM-YYYY')
                                                .concat(' au ',moment(this.state.ranges[1]).format('DD-MM-YYYY')))}
                                            onChange={this.handleDateRange}
                                            options={{
                                                mode: "range",
                                                dateFormat: "d-m-Y",
                                                maxDate: new Date() // set max date to today
                                            }}
                                        />
                                    </InputGroup>
                                    <span className="ri-calendar-line"></span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-none d-lg-inline-block ms-1">
                                <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.toggleFullscreen}>
                                    <i className="ri-fullscreen-line"></i>
                                </Button>
                            </div>
                            <ProfileMenu  LogOut={this.props.LogOutAction}/>
                            {/*<div className="dropdown d-inline-block">*/}
                            {/*    <Button color="none" onClick={this.toggleRightbar} type="button" className="header-item noti-icon right-bar-toggle waves-effect">*/}
                            {/*        <i className="ri-settings-2-line"></i>*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {toggleRightSidebar,ChangeDateRangeAction,LogOutAction};
const mapStatetoProps = state => {
    return {
        layoutType:state.Layout,
        startDate:state.ChangeDateRangeReducer.startDateDashboard,
        endDate:state.ChangeDateRangeReducer.endDateDashboard,
    };
};

export default connect(mapStatetoProps, mapDispatchToProps )(withNamespaces()(Header));
