import * as types from "../../actions";
const initialState = {
    startDateDashboard:"",
    endDateDashboard:""
}
export const ChangeDateRangeReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.CHANGE_RANGE_DATE_DASHBOARD_SUCCESS:
            return {
                ...state,
                startDateDashboard: response.startDateDashboard,
                endDateDashboard: response.endDateDashboard,
            };
        case types.CHANGE_RANGE_DATE_DASHBOARD_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
