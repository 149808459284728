import React, { Component } from 'react';
import { Document, Page,  Text, View,StyleSheet } from '@react-pdf/renderer';

export function DacPDF(dac_details) {
    const data= dac_details.data;

    const styles =StyleSheet.create( {
            page: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Helvetica',
                paddingTop: 50,
                paddingBottom: 50,
            },
            headerTitle: {
                fontSize: 20,
                fontWeight: 'bold',
                textTransform: 'uppercase',
            },
            bigTitle: {
                fontSize: 14,
                fontWeight: 'bold',
                marginBottom: 10,
                marginLeft: 20,
                textDecoration: 'underline',
                textTransform: 'uppercase',
                alignSelf: 'flex-start',
                textAlign: 'left',
            },
            smallTitle: {
                fontSize: 10,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                alignSelf: 'flex-start',
                textAlign: 'left',
                marginTop:30
            },
            line: {
                borderBottom: '1pt solid black',
                width: '80%',
                marginBottom: 30,
            },
            row: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginLeft: 20,
            },
            col: {
                flex: '0 0 33.33%', // Ajuster la largeur des colonnes ici
            },
            label: {
                fontSize: 14,
                fontWeight: 'bold',
                marginRight: 40,
            },
            info: {
                fontSize: 12,
                marginLeft: 40,
            },
            table: {
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#000',
                marginBottom: 20,
            },
            tableRow: {
                display: 'flex',
                flexDirection: 'row',
                borderBottom: '1pt solid black',
            },
            tableCellSmall: {
                flex: '1',
                padding: 5,
                borderLeft: '1pt solid black', // Ligne verticale à gauche de chaque cellule
                flexBasis: '15%', // Ajuster la largeur de la colonne ici
            },
            tableCellBig: {
                flex: '1',
                padding: 5,
                borderLeft: '1pt solid black', // Ligne verticale à gauche de chaque cellule
                flexBasis: '70%', // Ajuster la largeur de la colonne ici
            },
            tableInfo: {
                fontSize: 10,
            },
            tableTitre: {
                fontWeight: 'bold',
                fontSize: 12,
            },
            specialText: {
                fontSize: 10,
                fontStyle: 'italic',
                alignSelf: 'flex-start',
                textAlign: 'left',
                marginLeft: 20,
            },
            rowTable: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row', // Change the direction to 'row'
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginLeft: 30,
                marginRight: 20,
            },
            colTable: {
                flex:'0 0 30%',
                width: 'auto',
            },
            container: {
                marginBottom: 20,
            },
            text: {
                fontSize: 10,
                marginBottom: 15,
            },
        });


    const updateDenominationPresence = (denomination, dacDetailsCode) => {
        return denomination.map(item => ({
            ...item,
            presence: item.codeDechets === dacDetailsCode ? ' X ' : item.presence,
        }));
    };
    const updateConditionnement = (conditionnement, dacDetailConditionnement) => {
        return conditionnement.map(item => ({
            ...item,
            presence: item.value === dacDetailConditionnement ? ' X ' : item.presence,
        }));
    };
    const updateModeConservation = (mode_concervation, dacDetailMode) => {
        return mode_concervation.map(item => ({
            ...item,
            presence: item.value === dacDetailMode ? ' X ' : item.presence,
        }));
    };

    const updateTypeEtablissements = (type_etablissements, dacDetailsType) => {
        return type_etablissements.map(item => ({
            ...item,
            presence: item.value === dacDetailsType ? ' X ' : item.presence,
        }));
    };
    const denomination = [
        { presence: '', codeDechets: '3 – 10 f', description: 'Invendus de produits pour consommation humaine' },
        { presence: '', codeDechets: '3 – 10 g', description: 'Invendus de produits pour consommation animale' },
        { presence: '', codeDechets: '3 – 10 e', description: 'Résidus de préparations provenant de grandes et moyennes surfaces ou d’usines agro-alimentaires' },
        { presence: '', codeDechets: '3 – 10 p', description: 'Reste de plats cuisinés ou résidus de préparation provenant des restaurants et des déchets des ménages triés' },
    ];
    const updatedDenomination = updateDenominationPresence(denomination, data.etablissement_code_dechet);

    const conditionnement = [
        { presence: '', value: 'Triés vrac' },
        { presence: ' ', value: 'Emballés' },
        { presence: ' ', value: 'Triés vrac & emballés'},
        { presence: ' ', value: 'Autres'},
    ];
   const updatedConditionnement = updateConditionnement(conditionnement, data.etablissement_conditionnement_dechet);

    const mode_conservation = [
        { presence: ' ', value: 'Réfrigéré' },
        { presence: ' ', value: 'Air libre' },
        { presence: ' ', value: 'Confiné'},
        { presence: ' ', value: 'Autres'},
    ];

    const updatedModeConservation = updateModeConservation(mode_conservation, data.etablissement_mode_conservation);

    const vehicule_collecte = [
        { presence: '  ', value: 'Benne à ordure ménagère' },
        { presence: ' ', value: 'Camion polybenne' },
    ];
    const type_etablissements = [
        { presence: ' ', value: 'Restauration collective & traditionnelle' },
        { presence: ' ', value: 'Restauration rapide' },
        { presence: ' ', value: 'Boulangeries & sandwicherie' },
        { presence: ' ', value: 'GMS et commerces' },
        { presence: ' ', value: 'Produits IAA, marchés, restauration spécialisé - au cas par cas' },
        { presence: ' ', value: 'Ménages' },
        { presence: ' ', value: 'Autres' },
    ];
    const updatedTypeEtablissements = updateTypeEtablissements(type_etablissements, data.etablissement_categorie_origine);

    return (

            <Document>
            <Page size="A4">
                <View style={styles.page}>
                    <Text style={styles.headerTitle}>Document d’accompagnement commercial (DAC)[1]</Text>
                    <View style={styles.line} />
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text>
                                <Text style={styles.label}>N° DAC:   </Text>
                                <Text style={styles.info}>{`${data.date_presta.split(' ')[0].replace(/-/g, '')}_${data.id}`}</Text>
                            </Text>
                        </View>
                        <View style={styles.col}>
                            <Text>
                                <Text style={styles.label}>Date de collecte: </Text>
                                <Text style={styles.info}>{data.date_presta.split(' ')[0]}</Text>
                            </Text>
                        </View>
                    </View>
                    <Text style={[styles.bigTitle]}>Dénomination et poids des sous-produits</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCellSmall}>
                                <Text style={styles.tableTitre}>Présence</Text>
                            </View>
                            <View style={styles.tableCellSmall}>
                                <Text style={styles.tableTitre}>Code déchets*</Text>
                            </View>
                            <View style={styles.tableCellBig}>
                                <Text style={styles.tableTitre}>Description</Text>
                            </View>
                        </View>
                        {updatedDenomination.map((item, index) => (
                            <View key={index} style={styles.tableRow}>
                                <View style={styles.tableCellSmall}>
                                    <Text style={styles.tableInfo}>{item.presence}</Text>
                                </View>
                                <View style={styles.tableCellSmall}>
                                    <Text style={styles.tableInfo}>{item.codeDechets}</Text>
                                </View>
                                <View style={styles.tableCellBig}>
                                    <Text style={styles.tableInfo}>{item.description}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <Text style={[styles.specialText,{justifySelf:'start'}]}>
                        *Les déchets sont des sous-produits animaux de catégorie 3 au sens du règlement CE (CE) N° 1069/2009
                    </Text>
                    <View style={styles.rowTable}>
                        <View style={[styles.colTable]}>
                            <Text style={[styles.smallTitle]}>Conditionnement</Text>
                            <View style={[styles.table, { alignSelf: 'flex-start',  width: 'auto' }]}>
                                {updatedConditionnement.map((item, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={[styles.tableCellSmall]}>
                                            <Text style={styles.tableInfo}>{item.presence}</Text>
                                        </View>
                                        <View style={styles.tableCellBig}>
                                            <Text style={styles.tableInfo}>{item.value}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                        <View style={[styles.colTable]}>
                            <Text style={[styles.smallTitle]}>Mode de conservation</Text>
                            <View style={[styles.table, { alignSelf: 'flex-start',  width: 'auto' }]}>
                                {updatedModeConservation.map((item, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={[styles.tableCellSmall]}>
                                            <Text style={styles.tableInfo}>{item.presence}</Text>
                                        </View>
                                        <View style={styles.tableCellBig}>
                                            <Text style={styles.tableInfo}>{item.value}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                        <View style={[styles.colTable]}>
                            <Text style={[styles.smallTitle,]}>Véhicule de collecte</Text>
                            <View style={[styles.table, { alignSelf: 'flex-start',  width: 'auto' }]}>
                                {vehicule_collecte.map((item, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={[styles.tableCellSmall]}>
                                            <Text style={styles.tableInfo}>{item.presence}</Text>
                                        </View>
                                        <View style={styles.tableCellBig}>
                                            <Text style={styles.tableInfo}>{item.value}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                        <Text style={[styles.bigTitle]}>Établissement de départ des sous-produits</Text>
                        <View style={styles.rowTable}>
                            <View style={[styles.colTable,{flex:'0 0 60%',}]}>
                                <Text style={[styles.smallTitle,{marginTop:10}]}>Type d'établissement</Text>
                                <View style={[styles.table, { alignSelf: 'flex-start',  width: 'auto' }]}>
                                    {updatedTypeEtablissements.map((item, index) => (
                                        <View key={index} style={styles.tableRow}>
                                            <View style={[styles.tableCellSmall]}>
                                                <Text style={styles.tableInfo}>{item.presence}</Text>
                                            </View>
                                            <View style={styles.tableCellBig}>
                                                <Text style={styles.tableInfo}>{item.value}</Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            </View>
                            <View style={[styles.colTable,{flex:'0 0 40%'}]}>
                                <Text style={{marginTop: 30,marginBottom:10}}>
                                    <Text style={[styles.label, {fontSize:10}]}>Nom de l'établissement:   </Text>
                                    <Text style={[styles.info, {fontSize:10}]}>{data.nom_etablissement}</Text>
                                </Text>
                                <Text style={{marginBottom:10}}>
                                    <Text style={[styles.label, {fontSize:10}]}>Adresse:   </Text>
                                    <Text style={[styles.info, {fontSize:10}]}>{`${data.adresse}, ${data.cp}  ${data.ville}`}</Text>
                                </Text>
                                <Text style={{marginBottom:10}}>
                                    <Text style={[styles.label, {fontSize:10}]}>Nombre de bac collectés:   </Text>
                                    <Text style={[styles.info, {fontSize:10}]}>{data.quantite}</Text>
                                </Text>
                                <Text style={{marginBottom:10}}>
                                    <Text style={[styles.label, {fontSize:10}]}>Poids(kg):   </Text>
                                    <Text style={[styles.info, {fontSize:10}]}>{Math.round(Number(data.poids))}</Text>
                                </Text>
                                <Text style={{marginBottom:10}}>
                                    <Text style={[styles.label, {fontSize:10}]}>Signature:   </Text>
                                    <Text style={[styles.info, {fontSize:10}]}></Text>
                                </Text>
                                <Text style={{marginBottom:10}}>
                                    <Text style={[styles.label, {fontSize:10}]}>N° de Siret:   </Text>
                                    <Text style={[styles.info, {fontSize:10}]}></Text>
                                </Text>
                            </View>
                        </View>
                        <Text style={[styles.bigTitle]}>Collecteur – transporteur des sous-produits</Text>
                        <View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>Raison sociale (Nom du collecteur) :     </Text>
                                        <Text style={[styles.info,{fontSize:10}]}>                            {data.societe_name}</Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>Adresse :     </Text>
                                        <Text style={[styles.info,{fontSize:10}]}>                                                                          46 rue rené clair 75018 Paris</Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>N° d’enregistrement sanitaire :   </Text>
                                        <Text style={[styles.info,{fontSize:10}]}>                                        FR78123100</Text>
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <Text style={[styles.bigTitle,{marginTop:30}]}>Établissement destinataire des sous-produits</Text>
                        <View style={{flex:'0 0 50%'}}>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>Type d’établissement : </Text>
                                        <Text style={[styles.info,{fontSize:10}]}>        Méthanisation</Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>N° de Siret  : </Text>
                                        <Text style={[styles.info,{fontSize:10}]}>    87879471800022</Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>N° d’agrément sanitaire  : </Text>
                                        <Text style={[styles.info,{fontSize:10}]}> FR78123100 </Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>Téléphone: </Text>
                                        <Text style={[styles.info,{fontSize:10}]}>  </Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.col,{flex:'0 0 100%'}]}>
                                    <Text>
                                        <Text style={[styles.label,{fontSize:11}]}>Date de réception des sous-produits : </Text>
                                        <Text style={[styles.info,{fontSize:10}]}> {data.date_presta} </Text>
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{flex:'0 0 50%'}}>
                            <Text style={[styles.container,{alignSelf:"center"}]}>
                                <Text style={[styles.text,{fontWeight:'bold',fontSize:11}]}>ModulO Yvelines{'\n\n'}</Text>
                                <Text style={styles.text}>Déchetterie AZALYS{'\n\n'}</Text>
                                <Text style={styles.text}>RD 190, lieu-dit Les Bouveries{'\n\n'}</Text>
                                <Text style={styles.text}>78955 Carrières-sous-Poissy</Text>
                            </Text>
                        </View>
                        <Text style={{fontSize:9,marginTop:30}}>
                            [1] Pour le transport à l’intérieur de l’union européenne, de sous-produits animaux et de produits dérivés non destinés à la consommation humaine, conformément au règlement (CE) n°1069/2009. Ce document est émis par le producteur ou le collecteur de déchet, et est archivé par le producteur et/ou le collecteur. L’établissement destinataire signe également ce document ou bien une attestation de prise en charge y faisant référence et le tient à disposition des producteurs ou collecteurs de déchets.
                        </Text>

                    </View>
                </View>
            </Page>
        </Document>
        );
}

