import * as types from "../index";
import {login, Url} from "../../Env/env";
import {LoginForm} from "../../Models/authForm/loginForm";

export const LoginUserAction = (classLoginForm: LoginForm) => {
    return {
        type: types.LOGIN_USER,
        LoginUserPayload: classLoginForm,
        url:Url + login
    }
};