import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../../../Common/Breadcrumb';
//Import Components
import HoraireOuvertureTable from "./horaireOuvertureTable";
import {connect} from "react-redux";

import {isAdmin, isEtablissement, isSociete} from "../../../../../utils/myFunction";
import Swal from 'sweetalert2';
import {AddHoraireOuvertureAction} from "../../../../../actions/etablissements/addHoraireOuvertureAction";
import {GetHoraireOuvertureListesAction} from "../../../../../actions/etablissements/getHoraireOuvertureListesAction";

class HoraireOuverturePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Portail Client", link : "/" },
                { title : "Etablissements", link : "/#" },
            ],
            isClicked:false,
        }
    }


    componentDidMount() {
        const {decoded_token,data_admins,data_societe}=this.props;
        if(this.props.decoded_token){
            let data;
            if(isAdmin(decoded_token.scope.role) && data_admins){
                data=data_admins;
            }
            if(isSociete(decoded_token.scope.role) && data_societe){
                data=data_societe;
            }
            if (isEtablissement( decoded_token.scope.role)) {
                data = {
                    "societeId": decoded_token.societeId,
                    "etablissementsId":[decoded_token.etablissementId],
                };
            }
            if(data){
                this.props.GetHoraireOuvertureListesAction(data);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let data;
        if (this.props.decoded_token && (JSON.stringify(this.props.data_admins) !== JSON.stringify(prevProps.data_admins) ||
            JSON.stringify(this.props.data_societe) !== JSON.stringify(prevProps.data_societe))) {
            if (isAdmin(this.props.decoded_token.scope.role) && this.props.data_admins) {
                data = this.props.data_admins;
            }
            if (isSociete(this.props.decoded_token.scope.role) && this.props.data_societe) {
                data =  this.props.data_societe;
            }
            if (isEtablissement(this.props.decoded_token.scope.role)) {
                data = {
                    "societeId":this.props.decoded_token.societeId,
                    "etablissementsId":[this.props.decoded_token.etablissementId],
                };
            }
            if(data){
                this.props.GetHoraireOuvertureListesAction(data);
            }
        }
        if((this.props.messageHoraireAdded !==prevProps.messageHoraireAdded || this.props.timestampHoraireAdded !==prevProps.timestampHoraireAdded) && this.state.isClicked ){
            this.setState({isClicked:false});
            Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            }).fire({
                icon: 'success',
                title: this.props.messageHoraireAdded
            });
            if (isAdmin(this.props.decoded_token.scope.role) && this.props.data_admins) {
                data = this.props.data_admins;
            }
            if (isSociete(this.props.decoded_token.scope.role) && this.props.data_societe) {
                data =  this.props.data_societe;
            }
            if (isEtablissement(this.props.decoded_token.scope.role)) {
                data = {
                    "societeId":this.props.decoded_token.societeId,
                    "etablissementsId":[this.props.decoded_token.etablissementId],
                };
            }
            if(data){
                this.props.GetHoraireOuvertureListesAction(data);
            }
        }
    }
    handleClick =(bool)=>{
        this.setState({isClicked:bool})
    }
    render() {
        const {horaires_ouverture_listes,etablissements_for_societe,decoded_token,etablissements_for_admin}=this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    <Breadcrumbs title="Horaires d'ouverture" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <HoraireOuvertureTable
                                    decoded_toekn={decoded_token}
                                    etablissements_for_admin={etablissements_for_admin}
                                    etablissements_for_societe={etablissements_for_societe}
                                    handleClick={this.handleClick}
                                    handleChangeConsignes={this.props.ChangeConsignesAction}
                                    decoded_token={decoded_token}
                                    data={horaires_ouverture_listes}
                                    addHoraireOuverture={this.props.AddHoraireOuvertureAction}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    GetHoraireOuvertureListesAction,
    AddHoraireOuvertureAction
};

function mapStateToProps(state) {
    return {
        decoded_token:state.DecodeTokenReducer.decoded_token,
        data_admins:state.SetDataForAdminReducer.data_to_admin,
        data_societe:state.SetDataForSocieteReducer.data_to_societe,
        etablissements_for_societe:state.GetEtablissementsForSocieteReducer.etablissements,
        etablissements_for_admin:state.GetEtablissementsForAdminReducer.etablissements,
        horaires_ouverture_listes:state.GetHorairesOuvertureListReducer.horaires_ouverture,
        messageHoraireAdded:state.AddHoraireOuvertureReducer.message,
        timestampHoraireAdded:state.AddHoraireOuvertureReducer.timestamp,

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HoraireOuverturePage);
