export class SetDataWithSocieteAndEtablissements {
    societeId: number;
    etablissementsId: [number];
    start_date?:string;
    end_date?:string;
    type?:string;
    constructor(societeId:number,etablissementsId: [number],start_date:string,end_date:string,type:string,) {
        this.societeId = societeId;
        this.etablissementsId = etablissementsId;
        this.start_date = start_date;
        this.end_date = end_date;
        this.type = type;
    }
}
