import * as types from "../../actions";
import {GetPoidsAnalyseGraphInterfaceResponse} from "../../interfaces/dashboardInterface/GetPoidsAnalyseGraphInterface";

const initialState: GetPoidsAnalyseGraphInterfaceResponse = {
    series:[],
    categories:[],
}
export const GetPoidsAnalyseGraphReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_POIDS_ANALYSE_GRAPH_SUCCESS:
            return {
                ...state,
                series: response.series,
                categories: response.categories,
            };
        case types.GET_POIDS_ANALYSE_GRAPH_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
