import * as types from "../index";
import {factures_moyen_couvert, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetMoyenCoutCouvertAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_FACTURES_MOYEN_COUT_COUVERT,
        url:Url + factures_moyen_couvert ,
        dataPayload:data_to_send

    }
};
