import * as types from "../../actions";
import {DacsListRows} from "../../Models/dacs/dacsList";
import {GetDacsListInterfaceResponse} from "../../interfaces/dacsInterface/GetDacsListInteface";


const initialState: GetDacsListInterfaceResponse = {
    rows:[]
}
export const GetDacsListReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_DACS_LIST_SUCCESS:
            return {
                ...state,
                rows: response.rows,
            };
        case types.GET_DACS_LIST_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
