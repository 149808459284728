import {call, put} from "redux-saga/effects";
import {headers} from "../../../utils/headers";
import {get} from "../../../helpers/service/httpSpecificFunctions";
import * as types from "../../../actions"

import {
    GetSocietesForAdminInterface,
    GetSocietesForAdminInterfaceResponse
} from "../../../interfaces/roleManagementInterface/getSocietesForAdminInterface";
import {SetDataWithSocieteAndEtablissements} from "../../../Models/roleManagement/setDataWithSocieteAndEtablissements";


export function* SetDataForAdminSaga(payload: any):any {
    try {
            const data : SetDataWithSocieteAndEtablissements=payload.dataPayload;
            yield put({
                type: types.SET_DATA_FOR_ADMIN_SUCCESS,
                response: {
                    data_to_admin:data
                }
            });

    } catch (error) {
        yield [
            put({type: types.SET_DATA_FOR_ADMIN_ERROR, error}),
        ];
    }
}
