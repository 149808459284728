import * as types from "../../actions/index";
import { call, put } from "redux-saga/effects";

import {post} from "../../helpers/service/httpSpecificFunctions";

import {headers} from "../../utils/headers";
import {ForgetPasswordInterface} from "../../interfaces/authInterface/loginInterface/forgetPasswordInterface";


export function* ForgetPasswordSaga(payload:any) : Generator<any, void, any>{
    try {
        const apiCall = async () => {
            return await post<ForgetPasswordInterface>(payload.url, payload.emailPayload, headers);
        };
        let response, parsedBody: ForgetPasswordInterface | any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody) {
                        let data: ForgetPasswordInterface = parsedBody;
                        yield [
                            put({
                                type: types.PASSWORD_FORGET_SUCCESS, response: {
                                    message: data.message,
                                    timestamp: data.timestamp,
                                    success: data.success,
                                }
                            }),
                        ];
                    }
                    break;
                case 401:
                    if(parsedBody){
                        let data: ForgetPasswordInterface = parsedBody;
                        yield [
                            put({
                                type: types.PASSWORD_FORGET_ERROR, response: {
                                    message: data.message,
                                    timestamp: data.timestamp,
                                    success: data.success,
                                }
                            }),
                        ];
                    }
                    break;
                default:
                    //logout()
                    break;
            }
        }
        else {
            yield [
                put({type: types.PASSWORD_FORGET_ERROR, error: response.error}),
            ];
        }
    } catch (error) {
        yield [
            put({type: types.PASSWORD_FORGET_ERROR, error}),
        ];
    }
}
