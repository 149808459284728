import * as types from "../index";
import {synthese_environnementale_graph,  Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetSyntheseEnvironnementaleGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH,
        url:Url + synthese_environnementale_graph,
        dataPayload:data_to_send
}
};