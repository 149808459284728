import * as types from "../../../actions";

const initialState: [{ value:number,label:string }] = [{
    value:0,
    label:"",
}]
export const AddEtablissementsToStoreReducer= (state = initialState, action: any) =>{
    const response = action.response;
        switch (action.type) {
        case types.ADD_ETABLISSEMENTS_TO_STORE_SUCCESS:
            return {
                ...state,
                etablissements:response.etablissements
            };
        case types.ADD_ETABLISSEMENTS_TO_STORE_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
