import React from "react";
import {MRT_ColumnDef} from "material-react-table";

type DacsDataRows = {
    id:number;
    collecteur_prevu:string;
    collecteur_effectif:string;
    nom_etablissement:string;
    code_etablissement:string;
    adresse:string;
    cp:string;
    ville:string;
    date_presta:string;
    type_presta:string;
    motif:string;
    quantite:string;
    volume_total:string;
    poids:string;
    origine_mesure:string;
    temps_attente:string;
};
export const columnVisibilty ={
    collecteur_prevu:true,
    collecteur_effectif:true,
    code_etablissement:true,
    nom_etablissement:true,
    adresse:false,
    cp:false,
    ville:false,
    date_presta:true,
    type_presta:false,
    motif:false,
    quantite:true,
    volume_total:false,
    poids:true,
    origine_mesure:true,
    temps_attente:true,
}
export function dacsListColumnsMaterial(downloadPDF:any): MRT_ColumnDef<DacsDataRows>[] {
    return [
        {
            header: 'Actions',
            footer: 'Actions',
            enableHiding: false,
            Cell: ({row}) => {
                return (
                        <div className="col-md-12">
                            <button onClick={() => downloadPDF(row.original.id)} type="button"
                                    className="btn btn-info radius-30 m-1">
                                <i className="ri-file-download-line"/>
                                Pdf
                            </button>
                        </div>
                )
            }
        },
        {
            header: "Collecteur prévu",
            footer: "Collecteur prévu",
            id:'collecteur_prevu',
            accessorFn: row => row.collecteur_prevu
        },
        {
            header: "Collecteur effectif",
            footer: "Collecteur effectif",
            id:'collecteur_effectif',
            accessorFn: row => row.collecteur_effectif
        },
        {
            header: "Nom établissement",
            footer: "Nom établissement",
            id:'nom_etablissement',
            accessorFn: row => row.nom_etablissement
        },
        {
            header: "Code établissement",
            footer: "Code établissement",
            id:'code_etablissement',
            accessorFn: row => row.code_etablissement
        },
        {
            header: "Quantité",
            footer: "Quantité",
            id:'quantite',
            accessorFn: row => row.quantite
        },
        {
            header: "Poids(Kg)",
            footer: "Poids(Kg)",
            id:'poids',
            accessorFn: row => row.poids
        },
        {
            header: "Adresse",
            footer: "Adresse",
            id:'adresse',
            accessorFn: row => row.adresse
        },
        {
            header: "CP",
            footer: "CP",
            id:'cp',
            accessorFn: row => row.cp
        },
        {
            header: "Ville",
            footer: "Ville",
            id:'ville',
            accessorFn: row => row.ville
        },
        {
            header: "Date prestation",
            footer: "Date prestation",
            id:'date_presta',
            accessorFn: row => row.date_presta
        },
        {
            header: "Type prestation",
            footer: "Type prestation",
            id:'type_presta',
            accessorFn: row => row.type_presta
        },
        {
            header: "Motif",
            footer: "Motif",
            id:'motif',
            accessorFn: row => row.motif
        },
        {
            header: "Volume Total",
            footer: "Volume Total",
            id:'volume_total',
            accessorFn: row => row.volume_total
        },
        {
            header: "Origine Mesure",
            footer: "Origine Mesure",
            id:'origine_mesure',
            accessorFn: row => row.origine_mesure
        },
        {
            header: "Temps d'attente",
            footer: "Temps d'attente",
            id:'temps_attente',
            accessorFn: row => row.temps_attente
        },

    ];
}

