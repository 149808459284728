import {call, put} from "redux-saga/effects";
import {headersFormData} from "../../utils/headers";
import {putFormData} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"
import {logout} from "../../utils/cookies";

import {
    UpdateProfilInterfaceResponse
} from "../../interfaces/ProfilInterface/UpdateProfilInterface";
import {
    GetProfilDetailsInterface,
    GetProfilDetailsInterfaceResponse
} from "../../interfaces/ProfilInterface/GetProfilDetailsInterface";


export function* UpdateProfilSaga(payload: any) :any{
    try {
        let updateProfilData:any=payload.updateProfilPayload;
        const apiCall = async () => {
            return await putFormData<GetProfilDetailsInterface>(payload.url, updateProfilData,headersFormData);
        };
        let response, parsedBody: GetProfilDetailsInterface | any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody.data) {
                        let data: GetProfilDetailsInterfaceResponse = parsedBody.data;
                        yield
                            put({
                                type: types.GET_PROFIL_DETAILS_SUCCESS, response: {
                                    profil_details: data.profil_details
                                }
                            });
                    }
                    break;
                case 401:
                    localStorage.clear();
                    window.location.reload();
                    break;
                case 422:
                    if(parsedBody.error){
                        yield [
                            put({
                                type: types.UPDATE_PROFIL_ERROR, response: {
                                    error: parsedBody.error,
                                }
                            }),
                        ];
                    }
                    break;
                case 409:
                    if(parsedBody.data){
                        let data: UpdateProfilInterfaceResponse = parsedBody.data;
                        yield [
                            put({
                                type: types.UPDATE_PROFIL_ERROR, response: {
                                    error: data.message,
                                }
                            }),
                        ];
                    }
                    break;
                default:
                    logout();
                    break;
            }
        }
        else {

            yield [
                put({type: types.UPDATE_PROFIL_ERROR, error: response.error}),
            ];
        }
    } catch (error) {
        yield [
            put({type: types.UPDATE_PROFIL_ERROR, error}),
        ];
    }
}
