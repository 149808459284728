export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const USER_DATA = "USER_DATA";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_ERROR = "USER_DATA_ERROR";

export const LOG_OUT = "LOG_OUT";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "LOG_OUT_ERROR";

export const CHECK_STATUS = "CHECK_STATUS";
export const CHECK_STATUS_SUCCESS = "CHECK_STATUS_SUCCESS";
export const CHECK_STATUS_ERROR = "CHECK_STATUS_ERROR";

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";

export const PASSWORD_FORGET = "PASSWORD_FORGET";
export const PASSWORD_FORGET_SUCCESS = "PASSWORD_FORGET_SUCCESS";
export const PASSWORD_FORGET_ERROR = "PASSWORD_FORGET_ERROR";


export const DECODE_TOKEN = "DECODE_TOKEN";
export const DECODE_TOKEN_SUCCESS = "DECODE_TOKEN_SUCCESS";
export const DECODE_TOKEN_ERROR = "DECODE_TOKEN_ERROR";


export const GET_PROFIL_DETAILS = "GET_PROFIL_DETAILS";
export const GET_PROFIL_DETAILS_SUCCESS = "GET_PROFIL_DETAILS_SUCCESS";
export const GET_PROFIL_DETAILS_ERROR = "GET_PROFIL_DETAILS_ERROR";

export const UPDATE_PROFIL = "UPDATE_PROFIL";
export const UPDATE_PROFIL_SUCCESS = "UPDATE_PROFIL_SUCCESS";
export const UPDATE_PROFIL_ERROR = "UPDATE_PROFIL_ERROR";


/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

// Dashboard
// reproting cout widgets
export const GET_REPORTING_COUT_WIDGETS = "GET_REPORTING_COUT_WIDGETS";
export const GET_REPORTING_COUT_WIDGETS_SUCCESS = "GET_REPORTING_COUT_WIDGETS_SUCCESS";
export const GET_REPORTING_COUT_WIDGETS_ERROR = "GET_REPORTING_COUT_WIDGETS_ERROR";

// prestation analytics graph
export const GET_DETAILS_OPERATIONNELS_GRAPH = "GET_DETAILS_OPERATIONNELS_GRAPH";
export const GET_DETAILS_OPERATIONNELS_GRAPH_SUCCESS = "GET_DETAILS_OPERATIONNELS_GRAPH_SUCCESS";
export const GET_DETAILS_OPERATIONNELS_GRAPH_ERROR = "GET_DETAILS_OPERATIONNELS_GRAPH_ERROR";
// toggle month or week prestation analytics graph
export const TOGGLE_DETAILS_OPERATIONNELS_GRAPH = "TOGGLE_DETAILS_OPERATIONNELS_GRAPH";
export const TOGGLE_DETAILS_OPERATIONNELS_GRAPH_SUCCESS = "TOGGLE_DETAILS_OPERATIONNELS_GRAPH_SUCCESS";
export const TOGGLE_DETAILS_OPERATIONNELS_GRAPH_ERROR = "TOGGLE_DETAILS_OPERATIONNELS_GRAPH_ERROR";
// aleas logistique
export const GET_ALEAS_LOGISTIQUES_GRAPH = "GET_ALEAS_LOGISTIQUES_GRAPH";
export const GET_ALEAS_LOGISTIQUES_GRAPH_SUCCESS = "GET_ALEAS_LOGISTIQUES_GRAPH_SUCCESS";
export const GET_ALEAS_LOGISTIQUES_GRAPH_ERROR = "GET_ALEAS_LOGISTIQUES_GRAPH_ERROR";
export const TOGGLE_ALEAS_LOGISTIQUES_GRAPH = "TOGGLE_ALEAS_LOGISTIQUES_GRAPH";
export const TOGGLE_ALEAS_LOGISTIQUES_GRAPH_SUCCESS = "TOGGLE_ALEAS_LOGISTIQUES_GRAPH_SUCCESS";
export const TOGGLE_ALEAS_LOGISTIQUES_GRAPH_ERROR = "TOGGLE_ALEAS_LOGISTIQUES_GRAPH_ERROR";
// analyse de poids graph
export const GET_POIDS_ANALYSE_GRAPH = "GET_POIDS_ANALYSE_GRAPH";
export const GET_POIDS_ANALYSE_GRAPH_SUCCESS = "GET_POIDS_ANALYSE_GRAPH_SUCCESS";
export const GET_POIDS_ANALYSE_GRAPH_ERROR = "GET_POIDS_ANALYSE_GRAPH_ERROR";
export const TOGGLE_POIDS_ANALYSE_GRAPH = "TOGGLE_POIDS_ANALYSE_GRAPH";
export const TOGGLE_POIDS_ANALYSE_GRAPH_SUCCESS = "TOGGLE_POIDS_ANALYSE_GRAPH_SUCCESS";
export const TOGGLE_POIDS_ANALYSE_GRAPH_ERROR = "TOGGLE_POIDS_ANALYSE_GRAPH_ERROR";
// get couverts numbers
export const GET_COUVERTS_NUMBERS = "GET_COUVERTS_NUMBERS";
export const GET_COUVERTS_NUMBERS_SUCCESS = "GET_COUVERTS_NUMBERS_SUCCESS";
export const GET_COUVERTS_NUMBERS_ERROR = "GET_COUVERTS_NUMBERS_ERROR";
// add couverts numbers
export const ADD_COUVERTS_NUMBERS = "ADD_COUVERTS_NUMBERS";
export const ADD_COUVERTS_NUMBERS_SUCCESS = "ADD_COUVERTS_NUMBERS_SUCCESS";
export const ADD_COUVERTS_NUMBERS_ERROR = "ADD_COUVERTS_NUMBERS_ERROR";
// change Date range
export const CHANGE_RANGE_DATE_DASHBOARD = "CHANGE_RANGE_DATE_DASHBOARD";
export const CHANGE_RANGE_DATE_DASHBOARD_SUCCESS = "CHANGE_RANGE_DATE_DASHBOARD_SUCCESS";
export const CHANGE_RANGE_DATE_DASHBOARD_ERROR = "CHANGE_RANGE_DATE_DASHBOARD_ERROR";
// get tonnes collectees
export const GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH = "GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH";
export const GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH_SUCCESS = "GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH_SUCCESS";
export const GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH_ERROR = "GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH_ERROR";
// toggle month week  tonnes collectees
export const TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH = "TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH";
export const TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH_SUCCESS = "TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH_SUCCESS";
export const TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH_ERROR = "TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH_ERROR";
//
export const GET_SYNTHESE_UTILISATEUR = "GET_SYNTHESE_UTILISATEUR";
export const GET_SYNTHESE_UTILISATEUR_SUCCESS = "GET_SYNTHESE_UTILISATEUR_SUCCESS";
export const GET_SYNTHESE_UTILISATEUR_ERROR = "GET_SYNTHESE_UTILISATEUR_ERROR";

// Dacs
//      Dacs liste
export const GET_DACS_LIST = "GET_DACS_LIST";
export const GET_DACS_LIST_SUCCESS = "GET_DACS_LIST_SUCCESS";
export const GET_DACS_LIST_ERROR = "GET_DACS_LIST_ERROR";

export const GET_DAC_DETAILS = "GET_DAC_DETAILS";
export const GET_DAC_DETAILS_SUCCESS = "GET_DAC_DETAILS_SUCCESS";
export const GET_DAC_DETAILS_ERROR = "GET_DAC_DETAILS_ERROR";

// Factures
//      Factures liste
export const GET_FACTURES_LISTES = "GET_FACTURES_LISTES";
export const GET_FACTURES_LISTES_SUCCESS = "GET_FACTURES_LISTES_SUCCESS";
export const GET_FACTURES_LISTES_ERROR = "GET_FACTURES_LISTES_ERROR";

export const GET_FACTURES_GRAPH = "GET_FACTURES_GRAPH";
export const GET_FACTURES_GRAPH_SUCCESS = "GET_FACTURES_GRAPH_SUCCESS";
export const GET_FACTURES_GRAPH_ERROR = "GET_FACTURES_GRAPH_ERROR";

export const GET_FACTURES_MOYEN_COUT_COUVERT = "GET_FACTURES_MOYEN_COUT_COUVERT";
export const GET_FACTURES_MOYEN_COUT_COUVERT_SUCCESS = "GET_FACTURES_MOYEN_COUT_COUVERT_SUCCESS";
export const GET_FACTURES_MOYEN_COUT_COUVERT_ERROR = "GET_FACTURES_MOYEN_COUT_COUVERT_ERROR";
//      Factures liste
export const TOGGLE_FACTURES_GRAPH = "TOGGLE_FACTURES_GRAPH";
export const TOGGLE_FACTURES_GRAPH_SUCCESS = "TOGGLE_FACTURES_GRAPH_SUCCESS";
export const TOGGLE_FACTURES_GRAPH_ERROR = "TOGGLE_FACTURES_GRAPH_ERROR";
// reproting cout graph
export const GET_REPORTING_COUT_GRAPH = "GET_REPORTING_COUT_GRAPH";
export const GET_REPORTING_COUT_GRAPH_SUCCESS = "GET_REPORTING_COUT_GRAPH_SUCCESS";
export const GET_REPORTING_COUT_GRAPH_ERROR = "GET_REPORTING_COUT_GRAPH_ERROR";
// toggle month or week reproting cout graph
export const TOGGLE_REPORTING_COUT_GRAPH = "TOGGLE_REPORTING_COUT_GRAPH";
export const TOGGLE_REPORTING_COUT_GRAPH_SUCCESS = "TOGGLE_REPORTING_COUT_GRAPH_SUCCESS";
export const TOGGLE_REPORTING_COUT_GRAPH_ERROR = "TOGGLE_REPORTING_COUT_GRAPH_ERROR";


// Get societe For Admin
export const GET_SOCIETES_FOR_ADMIN = "GET_SOCIETES_FOR_ADMIN";
export const GET_SOCIETES_FOR_ADMIN_SUCCESS = "GET_SOCIETES_FOR_ADMIN_SUCCESS";
export const GET_SOCIETES_FOR_ADMIN_ERROR = "GET_SOCIETES_FOR_ADMIN_ERROR";
// Get etablissement for admin
export const GET_ETABLISSEMENTS_FOR_ADMIN = "GET_ETABLISSEMENTS_FOR_ADMIN";
export const GET_ETABLISSEMENTS_FOR_ADMIN_SUCCESS = "GET_ETABLISSEMENTS_FOR_ADMIN_SUCCESS";
export const GET_ETABLISSEMENTS_FOR_ADMIN_ERROR = "GET_ETABLISSEMENTS_FOR_ADMIN_ERROR";
// set data for admins
export const SET_DATA_FOR_ADMIN = "SET_DATA_FOR_ADMIN";
export const SET_DATA_FOR_ADMIN_SUCCESS = "SET_DATA_FOR_ADMIN_SUCCESS";
export const SET_DATA_FOR_ADMIN_ERROR = "SET_DATA_FOR_ADMIN_ERROR";

// Get etablissement for societe
export const GET_ETABLISSEMENT_FOR_SOCIETE = "GET_ETABLISSEMENT_FOR_SOCIETE";
export const GET_ETABLISSEMENT_FOR_SOCIETE_SUCCESS = "GET_ETABLISSEMENT_FOR_SOCIETE_SUCCESS";
export const GET_ETABLISSEMENT_FOR_SOCIETE_ERROR = "GET_ETABLISSEMENT_FOR_SOCIETE_ERROR";

export const SET_DATA_FOR_SOCIETE = "SET_DATA_FOR_SOCIETE";
export const SET_DATA_FOR_SOCIETE_SUCCESS = "SET_DATA_FOR_SOCIETE_SUCCESS";
export const SET_DATA_FOR_SOCIETE_ERROR = "SET_DATA_FOR_SOCIETE_ERROR";

export const ADD_ETABLISSEMENTS_TO_STORE = "ADD_ETABLISSEMENTS_TO_STORE";
export const ADD_ETABLISSEMENTS_TO_STORE_SUCCESS = "ADD_ETABLISSEMENTS_TO_STORE_SUCCESS";
export const ADD_ETABLISSEMENTS_TO_STORE_ERROR = "ADD_ETABLISSEMENTS_TO_STORE_ERROR";


// etablissement consignes collecte ouverture
export const GET_CONSIGNES_COLLECTES_LIST = "GET_CONSIGNES_COLLECTES_LIST";
export const GET_CONSIGNES_COLLECTES_LIST_SUCCESS = "GET_CONSIGNES_COLLECTES_LIST_SUCCESS";
export const GET_CONSIGNES_COLLECTES_LIST_ERROR = "GET_CONSIGNES_COLLECTES_LIST_ERROR";

export const ADD_CHANGE_CONSIGNES = "ADD_CHANGE_CONSIGNES";
export const ADD_CHANGE_CONSIGNES_SUCCESS = "ADD_CHANGE_CONSIGNES_SUCCESS";
export const ADD_CHANGE_CONSIGNES_ERROR = "ADD_CHANGE_CONSIGNES_ERROR";

export const GET_HORAIRES_OUVERTURE_LIST = "GET_HORAIRES_OUVERTURE_LIST";
export const GET_HORAIRES_OUVERTURE_LIST_SUCCESS = "GET_HORAIRES_OUVERTURE_LIST_SUCCESS";
export const GET_HORAIRES_OUVERTURE_LIST_ERROR = "GET_HORAIRES_OUVERTURE_LIST_ERROR";
export const ADD_HORAIRES_OUVERTURE = "ADD_HORAIRES_OUVERTURE";
export const ADD_HORAIRES_OUVERTURE_SUCCESS = "ADD_HORAIRES_OUVERTURE_SUCCESS";
export const ADD_HORAIRES_OUVERTURE_ERROR = "ADD_HORAIRES_OUVERTURE_ERROR";

