import { put} from "redux-saga/effects";
import * as types from "../../actions/index"

export function* ChangeDateRangeSaga(payload: any) :any {
    try {
        let startDate:any=payload.rangeDatePayload.startDate;
        let endDate:any=payload.rangeDatePayload.endDate;

        if (startDate && endDate) {
            yield put({
                type: types.CHANGE_RANGE_DATE_DASHBOARD_SUCCESS, response: {
                    startDateDashboard: startDate,
                    endDateDashboard: endDate,
                }
            });
        }

    } catch (error) {
        yield put({type: types.CHANGE_RANGE_DATE_DASHBOARD_ERROR, error});
    }
}
