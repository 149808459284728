export class ProfilDetailsModel {
    phone: string;
    picture: string;

    constructor(phone: string,picture: string) {
        this.phone = phone;
        this.picture = picture;
    }
}
export class ProfilRoleModel {
    id: number;
    role: RoleModel;

    constructor(id: number,role: RoleModel) {
        this.id = id;
        this.role = role;
    }
}
export class RoleModel{
    name: string;
    constructor(name:string) {
        this.name=name;
    }
}