import * as types from "../../actions";
import {
    GetHorairesOuvertureListInterfaceResponse
} from "../../interfaces/etablissements/horairesOuvertureInterface/GetHorairesOuvertureListInterface";


const initialState: GetHorairesOuvertureListInterfaceResponse = {
    horaires_ouverture:[]
}
export const GetHorairesOuvertureListReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_HORAIRES_OUVERTURE_LIST_SUCCESS:
            return {
                ...state,
                horaires_ouverture: response.horaires_ouverture,
            };
        case types.GET_HORAIRES_OUVERTURE_LIST_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
