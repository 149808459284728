import * as types from "../../actions";
import {UpdateProfilInterfaceResponse} from "../../interfaces/ProfilInterface/UpdateProfilInterface";

const initialState: UpdateProfilInterfaceResponse = {
    message:"",
}

export const UpdateProfilReducer= (state = initialState,
                         action: any
) =>{
    const response = action.response;
    switch (action.type) {
        case types.UPDATE_PROFIL_SUCCESS:
            return {
                ...state,
                message: response.message,
            };
        case types.UPDATE_PROFIL_ERROR:
            return {
                ...state,
                message:response.message,

            };
        default:
            return state;
    }
}
;
