import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {get} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"
import {logout} from "../../utils/cookies";
import {GetProfilDetailsInterface, GetProfilDetailsInterfaceResponse} from "../../interfaces/ProfilInterface/GetProfilDetailsInterface";


export function* GetProfilDetailsSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await get<GetProfilDetailsInterface>(payload.url, headers);
            };
            let response, parsedBody: GetProfilDetailsInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetProfilDetailsInterfaceResponse= parsedBody.data;
                            yield put({
                                    type: types.GET_PROFIL_DETAILS_SUCCESS, response: {
                                        profil_details: data.profil_details
                                    }
                                });
                        }
                        break;
                    case 404:
                        break;
                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        if(parsedBody.status ==="Token is Invalid")
                        {
                            logout();
                        }
                        break;
                }
            }
            else {
                yield [
                    put({type: types.GET_PROFIL_DETAILS_ERROR, error: response.status}),
                ];
            }


    } catch (error) {
        yield [
            put({type: types.GET_PROFIL_DETAILS_ERROR, error}),
        ];
    }
}
