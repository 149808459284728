


export const isLogged = () =>  {
    return !!localStorage.getItem('PortailClientTokenKey');
}
export const getToken = () =>  {
    return localStorage.getItem('PortailClientTokenKey')?localStorage.getItem('PortailClientTokenKey'):'';
}
export const logout = () => {
    localStorage.removeItem('PortailClientTokenKey');
    window.location.href="/login";

}

