import * as types from "../../actions";
import {GetReportingCoutsWidgetsInterfaceResponse} from "../../interfaces/facturesInterface/GetReportingCoutsWidgetsInterface";

const initialState: GetReportingCoutsWidgetsInterfaceResponse = {
    couts_widgets:[]
}
export const GetReportingCoutWidgetsReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_REPORTING_COUT_WIDGETS_SUCCESS:
            return {
                ...state,
                couts_widgets: response.couts_widgets,
            };
        case types.GET_REPORTING_COUT_WIDGETS_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
