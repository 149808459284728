import * as types from "../../../actions";
import {
    GetEtablissementsForSocieteInterfaceResponse
} from "../../../interfaces/roleManagementInterface/getEtablissementsForSocieteInterface";


const initialState: GetEtablissementsForSocieteInterfaceResponse = {
    etablissements:[{
        id:0,
        nom_affichage_portail_client:"",
        code_etablissement:""
    }],
    societe_for_admin:null

}
export const GetEtablissementsForSocieteReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_ETABLISSEMENT_FOR_SOCIETE_SUCCESS:
            return {
                ...state,
                etablissements:response.etablissements,
            };
        case types.GET_ETABLISSEMENT_FOR_SOCIETE_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
