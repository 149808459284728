import * as types from "../index";
import { horaires_ouverture, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetHoraireOuvertureListesAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_HORAIRES_OUVERTURE_LIST,
        url:Url + horaires_ouverture,
        dataPayload:data_to_send

    }
};
