import {takeLatest} from 'redux-saga/effects';
import * as types from '../actions';
import {LoginUserSaga} from "./auth/LoginUserSaga";
import {LogOutSaga} from "./auth/LogOutSaga";
import {ForgetPasswordSaga} from "./auth/ForgetPasswordSaga";
import {ChangePasswordSaga} from "./auth/ChangePasswordSaga";
import {GetUserDataSaga} from "./auth/GetUserDataSaga";
import {CheckStatusSaga} from "./auth/CheckStatusSaga";
import * as layoutSaga from "./layout/saga";
import {DecodeTokenSaga} from "./DecodeTokenSaga";
import {GetProfilDetailsSaga} from "./profil/GetProfilDetailsSaga";
import {UpdateProfilSaga} from "./profil/UpdateProfilSaga";
import {GetReportingCoutsWidgetsSaga} from "./factures/GetReportingCoutsWidgetsSaga";
import {GetReportingCoutsGraphSaga} from "./factures/GetReportingCoutsGraphSaga";
import {GetDetailsOperationnelsGraphSaga} from "./dashboard/GetDetailsOperationnelsGraphSaga";
import {GetAnalysePoidsGraphSaga} from "./dashboard/GetAnalysePoidsGraphSaga";
import {GetDacsListSaga} from "./dacs/GetDacsListSaga";
import {GetCouvertsNumbersSaga} from "./dashboard/GetCouvertsNumbersSaga";
import {AddCouvertNumberSaga} from "./dashboard/AddCouvertNumberSaga";
import {GetSyntheseEnvironnementaleGraphSaga} from "./dashboard/GetSyntheseEnvironnementaleGraphSaga";
import {GetFacturesGraphSaga} from "./factures/GetFacturesGraphSaga";
import {ToggleReportingCoutsGraphSaga} from "./factures/ToggleReportingCoutsGraphSaga";
import {ToggleDetailsOperationnelsGraphSaga} from "./dashboard/ToggleDetailsOperationnelsGraphSaga";
import {ToggleSyntheseEnvironnementaleGraphSaga} from "./dashboard/ToggleSyntheseEnvironnementaleGraphSaga";
import {GetSyntheseUtilisateurSaga} from "./dashboard/GetSyntheseUtilisateurSaga";
import {TogglePoidsAnalyticsGraphSaga} from "./dashboard/TogglePoidsAnalyticsGraphSaga";
import {GetAleasLogistiquesGraphSaga} from "./dashboard/GetAleasLogistiquesGraphSaga";
import {ToggleAleasLogistiquesGraphSaga} from "./dashboard/ToggleAleasLogistiquesGraphSaga";
import {ChangeDateRangeSaga} from "./dashboard/ChangeDateRangeSaga";
import {ToggleFacturesGraphSaga} from "./factures/ToggleFacturesGraphSaga";
import {GetSocietesForAdminSaga} from "./roleManagement/adminPortail/getSocietesForAdminSaga";
import {GetEtablissementsForAdminSaga} from "./roleManagement/adminPortail/getEtablissementsForAdminSaga";
import {SetDataForAdminSaga} from "./roleManagement/adminPortail/setDataForAdminSaga";
import {AddEtablissementsToStoreSaga} from "./roleManagement/adminPortail/addEtablissementsToStoreSaga";
import {GetEtablissementsForSocieteSaga} from "./roleManagement/societes/GetEtablissementsForSocieteSaga";
import {SetDataForSocieteSaga} from "./roleManagement/societes/setDataForSocieteSaga";
import {GetFacturesListesSaga} from "./factures/GetFacturesListesSaga";
import {GetConsignesCollectesListSaga} from "./etablissements/GetConsignesCollectesListSaga";
import {ChangeConsignesSaga} from "./etablissements/ChangeConsignesSaga";
import {AddHorairesOuvertureSaga} from "./etablissements/AddHorairesOuvertureSaga";
import {GetHorairesOuvertureListSaga} from "./etablissements/GetHorairesOuvertureListSaga";
import {GetDacDetailsSaga} from "./dacs/getDacDetailsSaga";
import {GetFacturesMoyenCoutCouvertSaga} from "./factures/GetFacturesMoyenCoutCouvertSaga";


export default function* watchUserAuthentication() {

    // Login Saga
    yield takeLatest(types.LOGIN_USER, LoginUserSaga);
    yield takeLatest(types.LOG_OUT, LogOutSaga);
    yield takeLatest(types.PASSWORD_FORGET, ForgetPasswordSaga);
    yield takeLatest(types.PASSWORD_RESET, ChangePasswordSaga);
    yield takeLatest(types.USER_DATA, GetUserDataSaga);
    yield takeLatest(types.CHECK_STATUS, CheckStatusSaga);
    // Decode Token Saga
    yield takeLatest(types.DECODE_TOKEN, DecodeTokenSaga);

    // Profil Saga
    yield takeLatest(types.GET_PROFIL_DETAILS, GetProfilDetailsSaga);
    yield takeLatest(types.UPDATE_PROFIL, UpdateProfilSaga);
    // Layout Saga
    yield takeLatest(types.CHANGE_LAYOUT, layoutSaga.changeLayout);
    yield takeLatest(types.CHANGE_LAYOUT_WIDTH, layoutSaga.changeLayoutWidth);
    yield takeLatest(types.CHANGE_SIDEBAR_THEME, layoutSaga.changeLeftSidebarTheme);
    yield takeLatest(types.CHANGE_SIDEBAR_TYPE, layoutSaga.changeLeftSidebarType);
    yield takeLatest(types.CHANGE_TOPBAR_THEME, layoutSaga.changeTopbarTheme);
    yield takeLatest(types.TOGGLE_RIGHT_SIDEBAR, layoutSaga.toggleRightSidebar);
    yield takeLatest(types.SHOW_RIGHT_SIDEBAR, layoutSaga.showRightSidebar);
    yield takeLatest(types.HIDE_RIGHT_SIDEBAR, layoutSaga.hideRightSidebar);
    // Dashboard Saga
    yield takeLatest(types.GET_REPORTING_COUT_WIDGETS, GetReportingCoutsWidgetsSaga);
    yield takeLatest(types.GET_DETAILS_OPERATIONNELS_GRAPH, GetDetailsOperationnelsGraphSaga);
    yield takeLatest(types.TOGGLE_DETAILS_OPERATIONNELS_GRAPH, ToggleDetailsOperationnelsGraphSaga);
    yield takeLatest(types.GET_POIDS_ANALYSE_GRAPH, GetAnalysePoidsGraphSaga);
    yield takeLatest(types.GET_COUVERTS_NUMBERS, GetCouvertsNumbersSaga);
    yield takeLatest(types.ADD_COUVERTS_NUMBERS, AddCouvertNumberSaga);
    yield takeLatest(types.GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH, GetSyntheseEnvironnementaleGraphSaga);
    yield takeLatest(types.TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH, ToggleSyntheseEnvironnementaleGraphSaga);
    yield takeLatest(types.TOGGLE_POIDS_ANALYSE_GRAPH, TogglePoidsAnalyticsGraphSaga);
    yield takeLatest(types.GET_SYNTHESE_UTILISATEUR, GetSyntheseUtilisateurSaga);
    yield takeLatest(types.GET_ALEAS_LOGISTIQUES_GRAPH, GetAleasLogistiquesGraphSaga);
    yield takeLatest(types.TOGGLE_ALEAS_LOGISTIQUES_GRAPH, ToggleAleasLogistiquesGraphSaga);
    yield takeLatest(types.CHANGE_RANGE_DATE_DASHBOARD, ChangeDateRangeSaga);
    // dacs saga
    yield takeLatest(types.GET_DACS_LIST, GetDacsListSaga);
    yield takeLatest(types.GET_DAC_DETAILS, GetDacDetailsSaga);
    // factures saga
    yield takeLatest(types.GET_FACTURES_GRAPH, GetFacturesGraphSaga);
    yield takeLatest(types.GET_FACTURES_LISTES, GetFacturesListesSaga);
    yield takeLatest(types.TOGGLE_FACTURES_GRAPH, ToggleFacturesGraphSaga);
    yield takeLatest(types.GET_REPORTING_COUT_GRAPH, GetReportingCoutsGraphSaga);
    yield takeLatest(types.TOGGLE_REPORTING_COUT_GRAPH, ToggleReportingCoutsGraphSaga);
    yield takeLatest(types.GET_FACTURES_MOYEN_COUT_COUVERT, GetFacturesMoyenCoutCouvertSaga);

    yield takeLatest(types.GET_SOCIETES_FOR_ADMIN, GetSocietesForAdminSaga);
    yield takeLatest(types.GET_ETABLISSEMENTS_FOR_ADMIN, GetEtablissementsForAdminSaga);
    yield takeLatest(types.SET_DATA_FOR_ADMIN, SetDataForAdminSaga);
    yield takeLatest(types.ADD_ETABLISSEMENTS_TO_STORE, AddEtablissementsToStoreSaga);

    yield takeLatest(types.GET_ETABLISSEMENT_FOR_SOCIETE, GetEtablissementsForSocieteSaga);
    yield takeLatest(types.SET_DATA_FOR_SOCIETE, SetDataForSocieteSaga);

    yield takeLatest(types.GET_CONSIGNES_COLLECTES_LIST, GetConsignesCollectesListSaga);
    yield takeLatest(types.ADD_CHANGE_CONSIGNES ,ChangeConsignesSaga );

    yield takeLatest(types.ADD_HORAIRES_OUVERTURE ,AddHorairesOuvertureSaga );
    yield takeLatest(types.GET_HORAIRES_OUVERTURE_LIST ,GetHorairesOuvertureListSaga );



}
