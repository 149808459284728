import {Auth} from "@aws-amplify/auth";
import {call, put} from "redux-saga/effects";
import * as types from "../../actions";
import {post} from "../../helpers/service/httpSpecificFunctions";

import {headers} from "../../utils/headers";

export function* LogOutSaga(payload:any) : Generator<any, void, any>{
    try {
        yield call([Auth, "signOut"]);
        // Clear all local storage
        localStorage.clear();
        const apiCall = async () => {
            return await post<any>(payload.url, headers);
        };
        let response, parsedBody: any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody) {
                        yield [
                            put({
                                type: types.LOG_OUT_SUCCESS, response: {
                                    message: parsedBody.message,
                                    timestamp: parsedBody.timestamp,
                                    success: parsedBody.success,
                                }
                            }),
                        ];
                        window.location.href="/login";
                    }
                    break;
                default:
                    //logout()
                    break;
            }
        }

        // Redirect to the login page
    } catch (error) {
        if(error instanceof Error)
            yield put({ type: types.LOG_OUT_ERROR,response:error.message});
    }
}
