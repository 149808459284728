import * as types from "../../actions";
import {CouvertsNumbers} from "../../Models/dashboard/couvertsNumbers";
import {GetCouvertsNumbersInterfaceResponse} from "../../interfaces/dashboardInterface/GetCouvertsNumbersInterface";


const initialState: GetCouvertsNumbersInterfaceResponse = {
    couverts_number:[]
}
export const GetCouvertsNumbersReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_COUVERTS_NUMBERS_SUCCESS:
            return {
                ...state,
                couverts_number: response.couverts_number,
            };
        case types.GET_COUVERTS_NUMBERS_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
