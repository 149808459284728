import React, {Component} from 'react';
import {connect} from "react-redux";
import DashboardPage from "./dashboardPages";



class Dashboard extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            multiValue: [],
            multiValueSelect: []
        };
    }




    render() {
        const {decoded_token}=this.props;
        return (
            <DashboardPage decoded_token={decoded_token} />
        )

    }
}


const mapDispatchToProps = {};

function mapStateToProps(state) {
    return {
        decoded_token:state.DecodeTokenReducer.decoded_token,

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);