import * as types from "../../actions";
import {
    PostChangeConsignesInterfaceResponse
} from "../../interfaces/etablissements/consignesCollectesInterface/PostChangeConsignesInterface";


const initialState: PostChangeConsignesInterfaceResponse = {
    message:"",
    success:false,
    timestamp:"",
}
export const ChangeConsignesReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.ADD_CHANGE_CONSIGNES_SUCCESS:
            return {
                ...state,
                message: response.message,
                success: response.success,
                timestamp: response.timestamp,
            };
        case types.ADD_CHANGE_CONSIGNES_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
