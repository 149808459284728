import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../../../actions/layout/actions";
import Select from "react-select";
import {isAdmin,isSociete} from "../../../../utils/myFunction";
import {GetSocietesForAdminAction} from "../../../../actions/roleManagement/adminPortail/getSocietesForAdminAction";
import {GetEtablissementsForSocieteAction} from "../../../../actions/roleManagement/societes/getEtablissementsForSocieteAction";
import {
    elementOfEtablissementsForAdmin, elementOfEtablissementsForSociete,
    elementOfSocietesForAdmin
} from "../../../../utils/roleManagement/functionsToGenerateElements";
import {
    GetEtablissementsForAdminAction
} from "../../../../actions/roleManagement/adminPortail/getEtablissementsForAdminAction";
import {Button, Col, Spinner} from "reactstrap";
import {SetDataWithSocieteAndEtablissements} from "../../../../Models/roleManagement/setDataWithSocieteAndEtablissements";
import {SetDataForAdminAction} from "../../../../actions/roleManagement/adminPortail/setDataForAdminAction";
import {
    AddEtablissementsToStoreAction
} from "../../../../actions/roleManagement/adminPortail/addEtablissementsToStoreAction";
import {SelectStyles} from "../../../../Constansts/sideBarSelectStyle";
import {SetDataForSocieteAction} from "../../../../actions/roleManagement/societes/setDataForSocieteAction";

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            societe_to_send_for_admin:null,
            societe_for_client:null,
            etablissements_to_send:this.props.etablissements_from_store?this.props.etablissements_from_store:null,
            request_sent:false,
            flag:false,
        };
    }
    initMenu() {
        new MetisMenu("#side-menu");
        let matchingMenuItem = null;
        let ul = document.getElementById("side-menu");
        let items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }
    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };
    componentDidMount() {
        const {user}=this.props;
        if(user) {
            if (isAdmin(user.scope.role)) {
                this.props.GetSocietesForAdminAction();
                if(this.props.societe_for_admin){
                    this.setState({societe_to_send_for_admin:this.props.societe_for_admin});
                }
            }
            if (isSociete(user.scope.role)) {
                this.setState({societe_for_client: {societe_id:user.societeId,societe_name:user.first_name}})
                this.props.GetEtablissementsForSocieteAction(user.societeId);
            }
            if(this.props.etablissements_from_store){
                this.setState({etablissements_to_send:this.props.etablissements_from_store});
            }
        }
        this.initMenu();
    }
    getOnlyValues=(items)=>{
        let values=[];
        if(items){
            Object.keys(items).forEach(function (itemKey) {
                values.push(
                    items[itemKey]['value']
                );
            });
        }
        return values;
    }
    getOnlyLabels=(items)=>{
        let values=[];
        if(items){
            Object.keys(items).forEach(function (itemKey) {
                values.push(
                    items[itemKey]['label']
                );
            });
        }
        return values;
    }
    componentDidUpdate(prevProps,prevState) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }
        }
    }
    addSocieteIdToList=(societe)=>{
        this.setState({societe_to_send_for_admin:societe,etablissements_to_send:null,flag:true});
        this.props.AddEtablissementsToStoreAction([]);
        this.props.GetEtablissementsForAdminAction(societe,societe.value);
    }
    addEtablissementToList=(etablissement)=>{
        this.setState({etablissements_to_send:etablissement});
    }
    searchForDataForAdmin=()=>{
        let etablissements_ids;
        if(this.state.etablissements_to_send!==null  && this.state.etablissements_to_send.length>0){
            this.setState({request_sent:true});
            etablissements_ids = this.getOnlyValues(this.state.etablissements_to_send);
            if(etablissements_ids.includes(0)){
                etablissements_ids=[0]
            }
        }
        if(this.state.etablissements_to_send===null){
            etablissements_ids=[0]
        }
        const data=new SetDataWithSocieteAndEtablissements(this.state.societe_to_send_for_admin.value,etablissements_ids);
        this.props.AddEtablissementsToStoreAction(this.state.etablissements_to_send);
        this.props.SetDataForAdminAction(data);

    }
    searchForDataForClient=()=>{
        let etablissements_ids;
        if(this.state.etablissements_to_send!==null  && this.state.etablissements_to_send.length>0){
            this.setState({request_sent:true});
            etablissements_ids = this.getOnlyValues(this.state.etablissements_to_send);
            if(etablissements_ids.includes(0)){
                etablissements_ids=[0]
            }
        }
        if(this.state.etablissements_to_send===null){
            etablissements_ids=[0]
        }
        const data=new SetDataWithSocieteAndEtablissements(this.state.societe_for_client.societe_id,etablissements_ids);
        this.props.AddEtablissementsToStoreAction(this.state.etablissements_to_send);
        this.props.SetDataForSocieteAction(data);
    }

    render() {
        const {user,societes_for_admin,etablissements_for_admin,societe_for_admin,etablissements_for_societe}=this.props;
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    {
                      user &&  isAdmin(user.scope.role) && this.props.layout.leftSideBarType==="default" &&
                        <>
                            <Select
                                options={elementOfSocietesForAdmin(societes_for_admin)}
                                styles={SelectStyles}
                                menuPlacement="auto"
                                placeholder="Choisir Société..."
                                onChange={this.addSocieteIdToList}
                                menuPosition="fixed"
                                defaultValue={societe_for_admin?societe_for_admin:null}
                            />
                            {
                                this.state.societe_to_send_for_admin || societe_for_admin ?
                                    <>
                                        <Select
                                            options={elementOfEtablissementsForAdmin(etablissements_for_admin)}
                                            className="react-select-container"
                                            styles={SelectStyles}
                                            value={this.state.etablissements_to_send}
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            placeholder="Choisir établissement..."
                                            isMulti
                                            isOptionDisabled={ ()=> this.state.etablissements_to_send && this.state.etablissements_to_send.length>=4}
                                            onChange={this.addEtablissementToList}
                                        />
                                        {this.state.etablissements_to_send && this.state.etablissements_to_send.length>=4 ?
                                            <span className="ms-4 text-start">vous avez atteint le maximum d'options</span>
                                            :
                                            null
                                        }
                                        {this.state.etablissements_to_send && this.state.etablissements_to_send.length>0
                                            ?
                                            <Col sm={12}>
                                                <Button onClick={this.searchForDataForAdmin} variant="info" className="ms-4">
                                                    <i className="ri-search-line"></i>
                                                </Button>
                                            </Col>:
                                            null
                                        }
                                    </>
                                    :
                                    null
                            }

                        </>
                    }
                    {
                      user &&  isSociete(user.scope.role)  && this.props.layout.leftSideBarType==="default" &&
                            <>
                                <Select
                                    options={elementOfEtablissementsForSociete(etablissements_for_societe)}
                                    className="react-select-container"
                                    styles={SelectStyles}
                                    value={this.state.etablissements_to_send}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    placeholder="Choisir établissement..."
                                    isMulti
                                    isOptionDisabled={ ()=> this.state.etablissements_to_send && this.state.etablissements_to_send.length>=4}
                                    onChange={this.addEtablissementToList}
                                />
                                {this.state.etablissements_to_send && this.state.etablissements_to_send.length>=4 ?
                                    <span className="ms-4 text-start">vous avez atteint le maximum d'options</span>
                                    :null}
                                {this.state.etablissements_to_send && this.state.etablissements_to_send.length>0
                                    ?
                                    <Col sm={12}>
                                        <Button onClick={this.searchForDataForClient} variant="info" className="ms-4">
                                            <i className="ri-search-line"></i>
                                        </Button>
                                    </Col>:null
                                }
                            </>
                    }
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Menu')}</li>
                        <li>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="ri-dashboard-line"></i>
                                <span className="ms-1">{this.props.t('Dashboard')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dacs" className="waves-effect">
                                <i className="ri-file-pdf-line"></i>
                                <span className="ms-1">{this.props.t('Dacs')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/factures" className="waves-effect">
                                <i className="ri-file-paper-2-line"></i>
                                <span className="ms-1">{this.props.t('Factures')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/etablissements" className="has-arrow waves-effect">
                                <i className="ri-building-4-line"></i>
                                <span className="ms-1">Etablissements</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/etablissements/consignes-collecte">Consignes & Collecte</Link></li>
                                {/*<li><Link to="/etablissements/horaires-ouverture">Horaire d'ouvertures</Link></li>*/}
                            </ul>
                        </li>

                    </ul>
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
    GetSocietesForAdminAction,
    GetEtablissementsForAdminAction,
    GetEtablissementsForSocieteAction,
    SetDataForAdminAction,
    SetDataForSocieteAction,
    AddEtablissementsToStoreAction
};

const mapStatetoProps = state => {
    return {
        layout:state.Layout,
        user:state.LoginUserReducer.user,
        societes_for_admin:state.GetSocietesForAdminReducer.societes,
        etablissements_for_admin:state.GetEtablissementsForAdminReducer.etablissements,
        societe_for_admin:state.GetEtablissementsForAdminReducer.societe_for_admin,
        etablissements_from_store:state.AddEtablissementsToStoreReducer.etablissements,
        data_admins:state.SetDataForAdminReducer.data_to_admin,
        data_societe:state.SetDataForSocieteReducer.data_to_societe,
        etablissements_for_societe:state.GetEtablissementsForSocieteReducer.etablissements,

    };
};

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(withNamespaces()(SidebarContent)));
