import React, { Component } from 'react';
import { Card, CardBody} from 'reactstrap';
//Import Charts
import ReactApexChart from 'react-apexcharts';
import "../../../../assets/scss/dashboard.scss";
import {optionForMoyenCoutCouvert} from "../../../../Constansts/optionsForFacturesGraph";
import {optionsForSyntheseEnvironnementaleGraph} from "../../../../Constansts/optionsForDashboardGraph";

class MoyenCoutCouvert extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Coûts / Couverts </h4>
                        <div>
                            {this.props.series && this.props.series.length > 0 && this.props.categories && this.props.categories.length > 0 ? (
                                <div id="line-column-chart" className="apex-charts" dir="ltr">
                                    <ReactApexChart width={"100%"} options={optionForMoyenCoutCouvert(this.props.categories)} series={this.props.series}
                                                    type="line" height={350} />
                                </div>
                            ) : (
                                <p>Aucune donnée disponible.</p>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MoyenCoutCouvert;