import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "../../../../assets/scss/dashboard.scss";
import {
    optionsForSyntheseEnvironnementaleGraph,
} from "../../../../Constansts/optionsForDashboardGraph";

class SyntheseEnvironnementale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMonth:false,
            activeButtonId:"num_sem",
        }
    }
    setActiveButton=(id)=>{
        if(id!== this.state.activeButtonId && this.props.client) {
            this.setState({activeButtonId: id});
            const data=this.props.client;
            data['type']=id;
            this.props.ToggleSyntheseEnvironnementalePeriode(data);
        }
    }
    render() {
        let maxLeftAxe,maxRightAxe=0;

        // Check if this.props.series is not empty
        if (this.props.series && this.props.series.length > 0) {
            maxLeftAxe = this.props.series[0] && this.props.series[1]
                ? Math.max(...this.props.series[0].data, ...this.props.series[1].data)
                : 0;
            maxRightAxe = this.props.series[2] && this.props.series[3]
                ? Math.max(...this.props.series[2].data, ...this.props.series[3].data)
                : 0;
        }
        return (

        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="float-end d-none d-md-inline-block">
                        <ButtonGroup className="mb-2">
                            <Button size="sm" color="light"  className={this.state.activeButtonId === "num_sem"? "active" : ""} onClick={() => this.setActiveButton("num_sem")} type="button">Semaine</Button>
                            <Button size="sm" color="light" className={this.state.activeButtonId === "num_mois"? "active" : ""} onClick={() => this.setActiveButton("num_mois")} type="button">Mois</Button>
                        </ButtonGroup>
                    </div>
                    <h4 className="card-title mb-4">Graphique Synthèse environnementale</h4>
                    {this.props.series && this.props.series.length > 0 && this.props.categories && this.props.categories.length > 0 ? (
                        <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <ReactApexChart
                                width={"100%"}
                                options={optionsForSyntheseEnvironnementaleGraph(this.props.categories, maxLeftAxe, maxRightAxe)}
                                series={this.props.series}
                                type="line"
                                height={450}
                            />
                        </div>
                    ) : (
                        <p>Aucune donnée disponible.</p>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
        );
    }
}

export default SyntheseEnvironnementale;
