import React, { Component } from 'react';
import {  Card, CardBody } from "reactstrap";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { FacturesListColumns} from "../../../../Constansts/facturesListColumns";
import DataTable from 'react-data-table-component';
import {facturesElementsGenerator} from "../../../../utils/factures/facturesFunctions";

class FacturesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideId:true,
      hideEtablissement:true,
      hideSociete:true,
    }
  }
  render() {
    const hideArray=[
      {hideId:!this.state.hideId},
      {hideEtablissement:!this.state.hideEtablissement},
      {hideSociete:!this.state.hideSociete}];
    const customStyles =  {
      table: {
        style: {
          overflow:"auto",// override the row height
        },
      },
    }
    return (
      <React.Fragment>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Liste des Factures</h4>
              <div className="table-responsive">
                  <DataTable
                      columns={FacturesListColumns(hideArray)}
                      data={this.props.factures && facturesElementsGenerator(this.props.factures)}
                      pagination
                      highlightOnHover
                      pointerOnHover
                      responsive
                      customStyles={customStyles}
                      noHeader
                  />
              </div>
            </CardBody>
          </Card>
      </React.Fragment>
    );
  }
}

export default FacturesTable;