import { call,  put } from "redux-saga/effects";



import {
	changeSidebarType as changeSidebarTypeAction,
	changeTopbarTheme as changeTopbarThemeAction,
} from "../../actions/layout/actions";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
	if (document.body) document.body.setAttribute(attribute, value);
	return true;
}


/**
 * Toggle the class on body
 * @param {*} cssClass
 * @param action
 */
function manageBodyClass(cssClass, action = "toggle") {
	switch (action) {
		case "add":
			if (document.body) document.body.classList.add(cssClass);
			break;
		case "remove":
			if (document.body) document.body.classList.remove(cssClass);
			break;
		default:
			if (document.body) document.body.classList.toggle(cssClass);
			break;
	}

	return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
export function* changeLayout({ payload: layout }) {
	try {
		if (layout === 'horizontal') {
			yield put(changeTopbarThemeAction('dark'));
			document.body.removeAttribute('data-sidebar');
			document.body.removeAttribute('data-sidebar-size');
		} else {
			yield put(changeTopbarThemeAction('light'));
		}
		yield call(changeBodyAttribute, "data-layout", layout);

	} catch (error) { }
}

/**
 * Changes the layout width
 * @param {*} param0
 */
export function* changeLayoutWidth({ payload: { width, layoutType } }) {
	try {
		if(layoutType === "vertical") {
		if (width === 'boxed') {
			yield put(changeSidebarTypeAction("icon"));
		} else {
			yield put(changeSidebarTypeAction("default"));
		}
		}
		yield call(changeBodyAttribute, "data-layout-size", width);
	} catch (error) { }
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
export function* changeLeftSidebarTheme({ payload: { theme, layoutType } }) {
	try {
		yield call(changeBodyAttribute, "data-sidebar", theme);
		if(layoutType === "vertical") {
			if(theme === "light")
			//Fire action for changing dark theme of topbar
			yield put(changeTopbarThemeAction('dark'));
			if(theme === "dark")
			//Fire action for changing light theme of topbar
			yield put(changeTopbarThemeAction('light'));
		}
	} catch (error) { }
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
export function* changeTopbarTheme({ payload: theme }) {
	try {
		yield call(changeBodyAttribute, "data-topbar", theme);
	} catch (error) { }
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
export function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
	try {
		switch (sidebarType) {
			case "compact":
				yield call(changeBodyAttribute, "data-sidebar-size", "small");
				yield call(manageBodyClass, "sidebar-enable", "remove");
				yield call(manageBodyClass, "vertical-collpsed", "remove");
				break;
			case "icon":
				yield call(changeBodyAttribute, "data-keep-enlarged", "true");
				yield call(manageBodyClass, "vertical-collpsed", "add");
				break;
			case "condensed":
				yield call(manageBodyClass, "sidebar-enable", "add");
				if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
				break;
			default:
				yield call(changeBodyAttribute, "data-sidebar-size", "");
				yield call(manageBodyClass, "sidebar-enable", "remove");
				if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "remove");
				break;
		}
	} catch (error) { }
}

/**
 * Toggles the rightsidebar
 */
export function* toggleRightSidebar() {
	try {
		yield call(manageBodyClass, "right-bar-enabled");
	} catch (error) { }
}

/**
 * Show the rightsidebar
 */
export function* showRightSidebar() {
	try {
		yield call(manageBodyClass, "right-bar-enabled", "add");
	} catch (error) { }
}

/**
 * Hides the rightsidebar
 */
export function* hideRightSidebar() {
	try {
		yield call(manageBodyClass, "right-bar-enabled", "remove");
	} catch (error) { }
}



