import * as types from "../../actions";
import {GetProfilDetailsInterfaceResponse} from "../../interfaces/ProfilInterface/GetProfilDetailsInterface";
import {ProfilDetailsModel, ProfilRoleModel,RoleModel} from "../../Models/profil/ProfilDetailsModel";
import {InstallationProfilDetailsModel} from "../../Models/profil/InstallationProfilDetailsModel";


const initialProfilDetails:ProfilDetailsModel=new ProfilDetailsModel('','');
const initialProfilDetailsInstallation:InstallationProfilDetailsModel=new InstallationProfilDetailsModel(0,'');
const initialRole:RoleModel=new RoleModel('');
const initialProfilRole:ProfilRoleModel=new ProfilRoleModel(0,initialRole);

const initialState: GetProfilDetailsInterfaceResponse = {
    profil_details: {
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        is_active: '',
        created_at: '',
        userRole: initialProfilRole,
        userDetails: initialProfilDetails,
        installation: initialProfilDetailsInstallation
    }
}
export const GetProfilDetailsReducer= (state = initialState,
                         action: any
) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_PROFIL_DETAILS_SUCCESS:
            return {
                ...state,
                profil_details: response.profil_details,

            };
        case types.GET_PROFIL_DETAILS_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
