export class UpdateConsignes {
    old_data:string;
    new_data:string;
    userId:number;
    societeId:number;
    etablissementId:number;
    constructor(
    old_data:string,
    new_data:string,user_id:number,societeId:number,etablissementId:number,) {
        this.old_data=old_data;
        this.new_data=new_data;
        this.userId=user_id;
        this.societeId=societeId;
        this.etablissementId=etablissementId;
    }
}
