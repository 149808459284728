import * as types from "../../actions";
import {
    GetConsignesCollectesListInterfaceResponse
} from "../../interfaces/etablissements/consignesCollectesInterface/GetConsignesCollectesListInterface";


const initialState: GetConsignesCollectesListInterfaceResponse = {
    consignes_collectes:[]
}
export const GetConsignesCollectesListReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_CONSIGNES_COLLECTES_LIST_SUCCESS:
            return {
                ...state,
                consignes_collectes: response.consignes_collectes,
            };
        case types.GET_CONSIGNES_COLLECTES_LIST_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
