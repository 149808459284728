import * as types from "../index";
import {analyse_poids_graph,  Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetPoidsAnalyseGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_POIDS_ANALYSE_GRAPH,
        url:Url + analyse_poids_graph ,
        dataPayload:data_to_send
    }
};