import JsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

export async function exportElementPdf() {
    const doc = new JsPDF("p", "px");
    const elements = document.getElementsByClassName("syntheseutilisateur");
    await creatPdf({ doc, elements });
    doc.save(`syntheseutilisateur.pdf`);
}

 async function creatPdf({doc, elements}: {
    doc: JsPDF;
     elements: HTMLCollectionOf<Element>;})
{
    const padding = 10;
    const marginTop = 20;
    let top = marginTop;

    const el = elements[0] as HTMLElement;
    const imgData = await htmlToImage.toPng(el);
    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;
    const pageWidth = doc.internal.pageSize.getWidth();
    if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding * 2;
        elWidth = elWidth * ratio - padding * 2;
    }
    const pageHeight = doc.internal.pageSize.getHeight();
    if (top + elHeight > pageHeight) {
        doc.addPage();
        top = marginTop;
    }
    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight);
}