import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {putJson} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"
import {
    AddCouvertsNumbersInterface,
    AddCouvertsNumbersInterfaceResponse
} from "../../interfaces/dashboardInterface/AddCouvertsNumbersInterface";


export function* AddCouvertNumberSaga(payload: any) :any {
    try {
        let couvert_number:any=payload.AddCouvertNumberPayload;
        const apiCall = async () => {
            return await putJson<AddCouvertsNumbersInterface>(payload.url, couvert_number,headers);
        };
        let response, parsedBody: AddCouvertsNumbersInterface | any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody.data) {
                        let data: AddCouvertsNumbersInterfaceResponse = parsedBody.data;
                        yield put({
                                type: types.ADD_COUVERTS_NUMBERS_SUCCESS, response: {
                                    message: data.message,
                                }
                            });
                    }
                    break;
                case 422:
                    if(parsedBody.error){
                        yield put({
                                type: types.ADD_COUVERTS_NUMBERS_ERROR, response: {
                                    error: parsedBody.error,
                                }
                            });
                    }
                    break;

                case 401:
                    localStorage.clear();
                    window.location.reload();
                    break;
                default:
                    break;
            }
        }
        else {
            //logout();
        }
    } catch (error) {
        yield put({type: types.ADD_COUVERTS_NUMBERS_ERROR, error});
    }
}
