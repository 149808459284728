import * as types from "../actions/index";
import { put } from "redux-saga/effects";
import {logout} from "../utils/cookies";
import jwt_decode from "jwt-decode";

export function* DecodeTokenSaga(payload: any) {
    try {
        let token: string = payload.tokenPayload;
        if (token) {
            yield put({
                        type: types.DECODE_TOKEN_SUCCESS,
                        response: {
                            decoded_token:jwt_decode(token)
                        }
                    })
        } else {
            logout();
        }

    } catch (error) {
        yield put({type: types.DECODE_TOKEN_ERROR, error})

    }
}
