export function elementOfSocietesForAdmin ( societes ){
    let societes_liste = [];
    Object.keys(societes).forEach(function (itemKey) {
        societes_liste.push(
            {value:societes[itemKey]['id'],label:societes[itemKey]['code_societe']+'-'+societes[itemKey]['nom_societe']}
        );
    });
    return societes_liste;
}
export function elementOfEtablissementsForAdmin ( etablissements ){
    let etablissements_liste = [{value:0,label:'Voir Tous'}];
    Object.keys(etablissements).forEach(function (itemKey) {
        etablissements_liste.push(
            {value:etablissements[itemKey]['id'],label:etablissements[itemKey]['nom_affichage_portail_client']}
        );
    });
    return etablissements_liste;
}

export function elementOfEtablissementsForSociete ( etablissements ){
    let etablissements_liste = [{value:0,label:'Voir Tous'}];
    Object.keys(etablissements).forEach(function (itemKey) {
        etablissements_liste.push(
            {value:etablissements[itemKey]['id'],label:etablissements[itemKey]['nom_affichage_portail_client']}
        );
    });
    return etablissements_liste;
}