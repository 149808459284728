import Swal from "sweetalert2";
export const diffDays = (tokenExp:number) => {
    let date = new Date(1000 * tokenExp);
    let iso = date.toISOString().match(/(\d{4}-\d{2}-\d{2})/)
    let myDate1=new Date();
    let myDate2 = new Date(iso?iso[0]:0);
    let Difference_In_Time = myDate2.getTime() - myDate1.getTime();
    return Difference_In_Time / (1000 * 3600 * 24);
}
export const  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const randomPassword =()=> {
    let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < 10; x++) {
        let i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
}

export  const isPermissionExist=(roles:[any],permission:string)=>{
    let permissions=[];
    let permissionsToRetrun=[];
    permissions=roles.map(item=> {return item['permissions']});
    for(let i=0;i<permissions.length;i++){
        for(let j=0;j<permissions[i].length;j++){
            permissionsToRetrun.push(permissions[i][j])
        }
    }
    permissionsToRetrun=permissionsToRetrun.filter((v,i,a)=>a.findIndex(v2=>(v2.name===v.name))===i)
    for(let perm of permissionsToRetrun){
        if(perm['name']===permission)
            return true;
    }
    return false
}
export const getRoles= (roles: [])=>{
    let higherRole:string="";
    higherRole=roles.filter(role => role['id'] ===  Math.min(...roles.map(item => item['id']))).map(item => item['name'])[0];
    return higherRole;

}

export const addMediaToFormData= (key: any, items: [any], formData: FormData) => {
    if(Array.isArray(items)) {
        for (let i = 0; i < items.length; i++) {
            formData.append(key, items[i]);
        }
    }
}
export function deleteCognitoItemsFromLocalStorage() {
    const prefix = 'CognitoIdentityServiceProvider.';

    // Get all keys from local storage
    const allKeys = Object.keys(localStorage);

    // Filter and delete keys that start with the prefix
    allKeys.forEach(key => {
        if (key.startsWith(prefix)) {
            localStorage.removeItem(key);
        }
    });
}
export const isAdmin=(roles:[any])=>{
    if(roles.some(role => role.name === 'Admin Portail'))
        return true;
}
export const isSociete=(roles:[any])=>{
    if(roles.some(role => role.name === 'Client'))
        return true;
}
export const isEtablissement=(roles:[any])=>{
    if(roles.some(role => role.name === 'Etablissement'))
        return true;
}

