import React, {Component} from 'react';
import {connect} from "react-redux";
import ResetPage from "./resetPage";

class ResetPassword extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }
    render() {
        return (
                <div className="wrapper">
                    <ResetPage/>
                </div>
        )
    }
}
const mapDispatchToProps = {};
function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);