import {HttpResponse} from "../../../Enums/service/HttpResponse";
import {MethodHeaderEnum} from "../../../Enums/service/MethodHeaderEnum";

export async function http<T>(
    request: RequestInfo
): Promise<HttpResponse<T>|unknown> {
    let response: HttpResponse<T>;
    try {
        response = await fetch(
            request
        );
        response.parsedBody = await response.json();
        return response;
    } catch (e) {
        return e;
    }
}

export async function get<T>(
    path: string,
    headers?: {},
    args: RequestInit = {
        headers: headers,
        method: MethodHeaderEnum.GET,
        credentials: 'include',// This line is added
    }
): Promise<HttpResponse<T>|unknown> {
    return await http<T>(new Request(path, args));
}

export async function post<T>(
    path: string,
    body: any,
    headers?: {},
    args: RequestInit = {
        method: MethodHeaderEnum.POST,
        headers: headers,
        body:JSON.stringify(body),
        credentials: 'include',// This line is added
    }
): Promise<HttpResponse<T>|unknown> {
    return await http<T>(new Request(path, args));
}
export async function postFormData<T>(
    path: string,
    body: any,
    headers?: {},
    args: RequestInit = {
        method: MethodHeaderEnum.POST,
        headers: headers,
        body:body,
        credentials: 'include',// This line is added
    }
): Promise<HttpResponse<T>|unknown> {
    return await http<T>(new Request(path, args));
}

export async function putFormData<T>(
    path: string,
    body: any,
    headers?: {},
    args: RequestInit = {
        method: MethodHeaderEnum.PUT,
        headers: headers,
        body:body,
        credentials: 'include',// This line is added
    }): Promise<HttpResponse<T>|unknown> {
    return await http<T>(new Request(path, args));
}
export async function putJson<T>(
    path: string,
    body: any,
    headers?: {},
    args: RequestInit = {
        method: MethodHeaderEnum.PUT,
        headers: headers,
        body:JSON.stringify(body),
        credentials: 'include',// This line is added
    }): Promise<HttpResponse<T>|unknown> {
    return await http<T>(new Request(path, args));
}

export async function deleted<T>(
    path: string,
    headers?: {},
    args: RequestInit = {
        headers: headers,
        method: MethodHeaderEnum.DELETE,
        credentials: 'include',// This line is added
    }
): Promise<HttpResponse<T>|unknown> {
    return await http<T>(new Request(path, args));
}