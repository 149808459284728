import * as types from "../../../actions";
import {
    GetEtablissementsForAdminInterfaceResponse
} from "../../../interfaces/roleManagementInterface/getEtablissementsForAdminInterface";


const initialState: GetEtablissementsForAdminInterfaceResponse = {
    etablissements:[{
        id:0,
        nom_affichage_portail_client:"",
        code_etablissement:""
    }],
    societe_for_admin:null

}
export const GetEtablissementsForAdminReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_ETABLISSEMENTS_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                etablissements:response.etablissements,
                societe_for_admin:response.societe_for_admin
            };
        case types.GET_ETABLISSEMENTS_FOR_ADMIN_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
