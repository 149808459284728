import * as types from "../actions";

const initialState: any[]=[];

export const DecodeTokenReducer= (state = initialState, action: any)=> {
    const response = action.response;
    switch (action.type) {
        case types.DECODE_TOKEN_SUCCESS:
            return {
                ...state,
                decoded_token: response.decoded_token,

            };
        case types.DECODE_TOKEN_ERROR:

            return {
                ...state,
                message:response.message
            };
        default:
            return state;
    }
}
;

