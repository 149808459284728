import * as types from "../index";
import {couverts_numbers, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetCouvertsNumbersAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_COUVERTS_NUMBERS,
        url:Url + couverts_numbers ,
        dataPayload:data_to_send

    }
};
