import * as types from "../index";
import {factures_graph, factures_listes, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetFacturesListesAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_FACTURES_LISTES,
        url:Url + factures_listes ,
        dataPayload:data_to_send

    }
};
