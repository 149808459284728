import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DataTable from 'react-data-table-component';
import {UpdateConsignes} from "../../../../../Models/etablissements/updateConsignes";
import {HoraireOuvertureListColumns} from "../../../../../Constansts/horaireOuvertureListColumns";
import {horaireOuvertureElementsGenerator} from "../../../../../utils/etablissements/horaireOuvertureFunctions";
import Select from "react-select";
import {
  elementOfEtablissementsForSociete,
} from "../../../../../utils/roleManagement/functionsToGenerateElements";
import {isAdmin, isEtablissement, isSociete} from "../../../../../utils/myFunction";
import {AddHoraireOuverture} from "../../../../../Models/etablissements/addHoraireOuverture";

class HoraireOuvertureTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideId:true,
      hideEtablissement:true,
      hideSociete:true,
      hideHoraire:true,
      hideConsignes:true,
      showModal: false,
      inputValues: [{days:[],horaire_debut:'',horaire_fin:''}],
      selectedDays: [],
      horaire_debut: '',
      horaire_fin: '',
      etablissement_id:0,
    }
  }
  toggleShow=()=> {
    this.setState({modal: !this.state.modal})
  }
  handleAddInput = () => {
    const { inputValues } = this.state;
    inputValues.push({ days: [], horaire_debut: '', horaire_fin: '' }); // add an object containing empty values for days, start time, and end time to the inputValues array
    this.setState({ inputValues }); // update the state with the new array
  }

  handleRemoveInput = (index) => {
    const { inputValues } = this.state;
    inputValues.splice(index, 1); // remove the object at the given index
    this.setState({ inputValues }); // update the state with the new array
  }

  handleInputChange = (event, index, key) => {
    const { inputValues } = this.state;
    inputValues[index][key] = event.target.value; // update the input value in the object
    this.setState({ inputValues }); // update the state with the new object
  }

  handleDayChange = (selectedDays, index) => {
    const { inputValues } = this.state;
     // get an array of selected days
    if (!inputValues[index]) {
      inputValues[index] = {};
    }
    inputValues[index].days = selectedDays.map(day => day.value).join(','); // update the days array in the object
    this.setState({ inputValues }); // update the state with the new object
  }
  handleSubmit = (event) => {
    event.preventDefault();
    let etablissementId=0;
    if(isSociete(this.props.decoded_token.scope.role) || isAdmin(this.props.decoded_token.scope.role)) {
      etablissementId = this.state.etablissement_id;
    }
    if(isEtablissement(this.props.decoded_token.scope.role)){
      etablissementId = this.props.decoded_token.etablissementId;
    }
    if(etablissementId!==0){
        const data=new AddHoraireOuverture(etablissementId,this.state.inputValues);
        this.props.addHoraireOuverture(data)
        this.setState({
            inputValues: [{days:[],horaire_debut:'',horaire_fin:''}],
            selectedDays: [],
            horaire_debut: '',
            horaire_fin: '',
            etablissement_id:0,
        });
        this.toggleShow();
        this.props.handleClick(true);
    }
  }
  addEtablissementToList=(etablissement)=>{
    this.setState({etablissement_id:etablissement.value});
  }
  render() {

    const hideArray=[
      {hideId:!this.state.hideId},
      {hideEtablissement:!this.state.hideEtablissement},
      {hideSociete:!this.state.hideSociete},
      {hideConsignes:!this.state.hideConsignes},
      {hideHoraire:!this.state.hideHoraire}
    ];
    const customStyles =  {
      table: {
        style: {
          overflow:"auto",// override the row height
        },
      },
    };
    const { inputValues } = this.state;
    const jours=[{value:"lundi",label:"Lundi"},
      {value:"mardi",label:"Mardi"},
      {value:"mercredi",label:"Mercredi"},
      {value:"jeudi",label:"Jeudi"},
      {value:"vendredi",label:"Vendredi"},
      {value:"samedi",label:"Samedi"},
      {value:"dimanche",label:"Dimanche"},]
    return (
      <>
       <Row>
          <Col className={"mb-3"} sm={12}>
            <div>
              <button className="btn btn-info float-end" onClick={this.toggleShow}>Ajouter un horaire d'ouverture</button>
            </div>
          </Col>
         <Col sm={12}>
           <Card>
             <CardBody>
               <h4 className="card-title mb-4">Liste des Horaires d'ouverture</h4>
               <div className="table-responsive">
                 <DataTable
                     columns={HoraireOuvertureListColumns(hideArray,this.handleConsigne)}
                     data={this.props.data && horaireOuvertureElementsGenerator(this.props.data)}
                     pagination
                     highlightOnHover
                     pointerOnHover
                     responsive
                     customStyles={customStyles}
                     noHeader
                 />
               </div>
             </CardBody>
           </Card>
         </Col>
       </Row>
        <div>
          <Modal isOpen={this.state.modal} size="lg" centered  toggle={this.toggleShow}>
            <ModalHeader toggle={this.toggleShow}>Ajout d'horaire</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.handleSubmit}>
                  {
                      isAdmin(this.props.decoded_token.scope.role) &&
                      <Row className="mb-2">
                          <Col md={12}>
                              <Select
                                  options={elementOfEtablissementsForSociete(this.props.etablissements_for_admin)
                                      .filter(item => item.value > 0)}
                                  placeholder="Etablissement..."
                                  onChange={this.addEtablissementToList}
                              />
                          </Col>
                      </Row>
                  }
                {
                    isSociete(this.props.decoded_token.scope.role) &&
                    <Row className="mb-2">
                      <Col md={12}>
                        <Select
                            options={elementOfEtablissementsForSociete(this.props.etablissements_for_societe)
                                .filter(item => item.value > 0)}
                            placeholder="Etablissement..."
                            onChange={this.addEtablissementToList}
                        />
                      </Col>
                    </Row>
                }
                {inputValues.map((inputValue, index) => (
                    <div key={index} className="mb-2">
                      <Row>
                        <Col md={index>0?5:6}>
                          <Select
                              options={jours}
                              placeholder="Choisir le jour..."
                              isMulti
                              onChange={(selectedDays) =>
                                  this.handleDayChange(selectedDays, index)}
                          />
                        </Col>
                        <Col md={index>0?5:6}>
                          <Row>
                            <Col md={6}>
                              <Input type="time" className="form-control" placeholder={"00:00"}
                                     onChange={(event) =>
                                         this.handleInputChange(event, index, 'horaire_debut')}/>
                            </Col>
                            <Col md={6}>
                              <Input type="time" className="form-control" placeholder={"00:00"}
                                     onChange={(event) =>
                                         this.handleInputChange(event, index, 'horaire_fin')}/>
                            </Col>
                          </Row>
                        </Col>
                        {index>0 &&
                            <Col sm={2}>
                              <Button color="danger" onClick={() => this.handleRemoveInput(index)}>x</Button>
                            </Col>
                        }
                      </Row>
                    </div>
                ))}
                <Row className="mb-1">
                  <Col md={12}>
                    <Button color="primary" onClick={this.handleAddInput}>Ajoute une ligne</Button>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col md={12}>
                    <Button className="float-end" color="success">Submit</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleShow}>Fermer</Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

export default HoraireOuvertureTable;
