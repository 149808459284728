
export class LoginForm {

   email:string;
   password:string;


    constructor(
        email :string,
        password :string,

    ) {
        this.email = email;
        this.password = password;


    }
    getKey():string{
        return this.email;
    }
    getId():string{
        return this.password;
    }


}