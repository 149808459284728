import {call, put} from "redux-saga/effects";
import * as types from "../../actions";
import {get} from "../../helpers/service/httpSpecificFunctions";

import {headers} from "../../utils/headers";

export function* CheckStatusSaga(payload:any) : Generator<any, void, any>{
    try {
        const apiCall = async () => {
            return await get<any>(payload.url, headers);
        };
        let response, parsedBody: any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody.isAuthenticated) {
                        yield put({
                            type: types.CHECK_STATUS_SUCCESS,
                            response: {
                                isAuthenticated: parsedBody.isAuthenticated,
                            }
                        });
                    } else {
                        localStorage.clear();
                        yield put({
                            type: types.CHECK_STATUS_ERROR,
                            response: {
                                isAuthenticated: parsedBody.isAuthenticated,
                            }
                        });

                    }
                    break;
                case 500:
                    localStorage.clear();
                    yield put({
                        type: types.CHECK_STATUS_ERROR,
                        response: {
                            isAuthenticated: false,
                        }
                    });

                    break;
                default:
                    //logout()
                    break;
            }
        }

        // Redirect to the login page
    } catch (error) {
        if(error instanceof Error)
         yield put({ type: types.CHECK_STATUS_ERROR,response:error.message});
    }
}
