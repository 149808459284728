import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../../../Common/Breadcrumb';
//Import Components
import ConsignesAndCollecteTable from "./consignesAndCollecteTable";
import {connect} from "react-redux";

import {isAdmin, isEtablissement, isSociete} from "../../../../../utils/myFunction";
import {GetConsignesCollecteListesAction} from "../../../../../actions/etablissements/getConsignesCollecteListesAction";
import {ChangeConsignesAction} from "../../../../../actions/etablissements/changeConsignesAction";
import Swal from 'sweetalert2';

class ConsignesCollectePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Portail Client", link : "/" },
                { title : "Etablissements", link : "/#" },
            ],
            isClicked:false,
        }
    }
    componentDidMount() {
        const {user,data_admins,data_societe}=this.props;
        if(user){
            let data;
            if(isAdmin(user.scope.role) && data_admins){
                data=data_admins;
            }
            if(isSociete(user.scope.role) && data_societe){
                data=data_societe;
            }
            if (isEtablissement( user.scope.role)) {
                data = {
                    "societeId": user.societeId,
                    "etablissementsId":[user.etablissementId],
                };
            }
            if(data){
                this.props.GetConsignesCollecteListesAction(data);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user && (JSON.stringify(this.props.data_admins) !== JSON.stringify(prevProps.data_admins) ||
            JSON.stringify(this.props.data_societe) !== JSON.stringify(prevProps.data_societe))) {
            let data;
            if (isAdmin(this.props.user.scope.role) && this.props.data_admins) {
                data = this.props.data_admins;
            }
            if (isSociete(this.props.user.scope.role) && this.props.data_societe) {
                data =  this.props.data_societe;
            }
            if (isEtablissement(this.props.user.scope.role)) {
                data = {
                    "societeId":this.props.user.societeId,
                    "etablissementsId":[this.props.user.etablissementId],
                };
            }
            if(data){
                this.props.GetConsignesCollecteListesAction(data);
            }
        }
        if((this.props.message !==prevProps.message || this.props.timestamp !==prevProps.timestamp) && this.state.isClicked ){
                this.setState({isClicked:false});
            Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            }).fire({
                icon: 'success',
                title: this.props.message
            });
        }
    }
    handleClick =(bool)=>{
        this.setState({isClicked:bool})
    }
    render() {
        const {consignes_collectes_listes,user}=this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    <Breadcrumbs title="Consignes & Collecte" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <ConsignesAndCollecteTable handleClick={this.handleClick} handleChangeConsignes={this.props.ChangeConsignesAction} user={user} data={consignes_collectes_listes}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {GetConsignesCollecteListesAction,ChangeConsignesAction};

function mapStateToProps(state) {
    return {
        user:state.LoginUserReducer.user,
        data_admins:state.SetDataForAdminReducer.data_to_admin,
        data_societe:state.SetDataForSocieteReducer.data_to_societe,
        consignes_collectes_listes:state.GetConsignesCollectesListReducer.consignes_collectes,
        message:state.ChangeConsignesReducer.message,
        timestamp:state.ChangeConsignesReducer.timestamp,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsignesCollectePage);
