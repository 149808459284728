import * as types from "../index";


export const changeLayout = layout => ({
	type: types.CHANGE_LAYOUT,
	payload: layout
});

export const changePreloader = layout => ({
	type: types.CHANGE_PRELOADER,
	payload: layout
});

export const changeLayoutWidth = (width, layoutType) => ({
	type: types.CHANGE_LAYOUT_WIDTH,
	payload:  { width, layoutType }
});

export const changeSidebarTheme = (theme, layoutType) => ({
	type: types.CHANGE_SIDEBAR_THEME,
	payload: {theme, layoutType}
});

export const changeSidebarType = (sidebarType, isMobile) => {
	return {
		type: types.CHANGE_SIDEBAR_TYPE,
		payload: { sidebarType, isMobile }
	};
};

export const changeTopbarTheme = topbarTheme => ({
	type: types.CHANGE_TOPBAR_THEME,
	payload: topbarTheme
});


export const toggleRightSidebar = () => ({
	type: types.TOGGLE_RIGHT_SIDEBAR,
	payload: null
});

export const showRightSidebar = () => ({
	type: types.SHOW_RIGHT_SIDEBAR,
	payload: null
});

export const hideRightSidebar = () => ({
	type: types.HIDE_RIGHT_SIDEBAR,
	payload: null
});
