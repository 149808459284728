import * as types from "../index";
import {aleas_logisitque_graph, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetAleasLogistiquesGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_ALEAS_LOGISTIQUES_GRAPH,
        url:Url + aleas_logisitque_graph ,
        dataPayload:data_to_send

    }
};