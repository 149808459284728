import * as types from "../../actions";
import {GetDacDetailsInterfaceResponse} from "../../interfaces/dacsInterface/GetDacDetailsInterface";
import {DacDetails} from "../../Models/dacs/dacDetails";

const initialDac : DacDetails=new DacDetails(0,'','','','',
    '','','','','','','','' +
    '','','')
const initialState: GetDacDetailsInterfaceResponse = {
    dac_details:initialDac,
    success:false,
    timestamp:''
}
export const GetDacDetailsReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_DAC_DETAILS_SUCCESS:
            return {
                ...state,
                dac_details: response.dac_details,
                success: response.success,
                timestamp: response.timestamp,
            };
        case types.GET_DAC_DETAILS_ERROR:
            return {
                ...state,
                error:response.error
            };
        default:
            return state;
    }
};
