export class DacDetails {
    id:number;
    nom_etablissement:string;
    adresse:string;
    cp:string;
    ville:string;
    date_presta:string;
    quantite:string;
    poids:string;
    societe_name:string;
    societe_num_agrement_sanitaire:string;
    societe_adresse:string;
    etablissement_categorie_origine:string;
    etablissement_code_dechet:string;
    etablissement_conditionnement_dechet:string;
    etablissement_mode_conservation:string;


    constructor(id:number,
    nom_etablissement:string,
    adresse:string,
    cp:string,
    ville:string,
    date_presta:string,
    quantite:string,
    poids:string,
    societe_name:string,
    societe_num_agrement_sanitaire:string,
    societe_adresse:string,
    etablissement_categorie_origine:string,
    etablissement_code_dechet:string,
    etablissement_conditionnement_dechet:string,
    etablissement_mode_conservation:string,) {
        this.id=id;
        this.nom_etablissement=nom_etablissement;
        this.adresse=adresse;
        this.cp=cp;
        this.ville=ville;
        this.date_presta=date_presta;
        this.quantite=quantite;
        this.poids=poids;
        this.societe_name=societe_name;
        this.societe_num_agrement_sanitaire=societe_num_agrement_sanitaire;
        this.societe_adresse=societe_adresse;
        this.etablissement_categorie_origine=etablissement_categorie_origine;
        this.etablissement_code_dechet=etablissement_code_dechet;
        this.etablissement_conditionnement_dechet=etablissement_conditionnement_dechet;
        this.etablissement_mode_conservation=etablissement_mode_conservation;
    }
}