import React, {Component} from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AppRoute from "./routes/route";
import NonAuthLayout from "./components/layouts/shared/NonAuthLayout";
import { privateNavigation, publicNavigation} from "./helpers/navigation/navigation";
import { privateComponents, publicComponents} from "./helpers/components/components";
import "./assets/scss/theme.scss";
import VerticalLayout from "./components/layouts/shared/VerticalLayout";
import HorizontalLayout from "./components/layouts/shared/HorizontalLayout";
import {DecodeTokenAction} from "./actions/decodeTokenAction";
import {CheckStatusAction} from "./actions/auth/CheckStatusAction";
import {GetUserDataAction} from "./actions/auth/GetUserDataAction";
import {LogOutAction} from "./actions/auth/LogOutAction";
import ResetPassword from "./components/auth/reset-password/main";
import {deleteCognitoItemsFromLocalStorage} from "./utils/myFunction";


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maintenance:false
        };
        this.getLayout = this.getLayout.bind(this);
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    shouldFetchUserData() {
        const { user, isAuthenticated } = this.props;
        return (!user || (user && user.id === 0)) && isAuthenticated;
    }

    componentDidMount() {
        const { isAuthenticated, CheckStatusAction, GetUserDataAction } = this.props;

        if (!isAuthenticated) {
            CheckStatusAction();
        }

        if (this.shouldFetchUserData()) {
            GetUserDataAction();
        }
        deleteCognitoItemsFromLocalStorage();

        this.setState({ loading: false });
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated: prevIsAuthenticated } = prevProps;

        if (this.props.isAuthenticated !== prevIsAuthenticated && this.shouldFetchUserData()) {
            this.props.GetUserDataAction();
        }

        if (this.props.isAuthenticated === "false") {
            this.props.history.push('/login');
        }
    }
    render() {
        const Layout = this.getLayout();
        const { newPasswordRequired,isAuthenticated } = this.props;
        const { loading } = this.state;

        if (loading) {
            return null; // or your loading component
        }
        return (
            <Router>
                <Switch>
                    <Route path="/new-password" component={ResetPassword} />
                    {newPasswordRequired ? (
                            <Redirect to="/new-password" />
                        ):
                        (<>
                            {publicNavigation.map((navItem, idx) => (
                                <AppRoute
                                    isAuthenticated={isAuthenticated}
                                    key={idx}
                                    isAuth
                                    path={navItem[1]}
                                    layout={NonAuthLayout}
                                    component={publicComponents[navItem[2]]}
                                    isAuthProtected={false}
                                />
                            ))}
                            {privateNavigation.map((navItem, idx) => (
                                <AppRoute
                                    isAuthenticated={isAuthenticated}
                                    key={idx}
                                    isAuth
                                    exact
                                    path={navItem[1]}
                                    layout={Layout}
                                    component={privateComponents[navItem[2]]}
                                    isAuthProtected={true}
                                    title={navItem[3]}
                                />
                            ))}
                        </>)}
                </Switch>
            </Router>
        );
    }
}


const mapDispatchToProps = {DecodeTokenAction,GetUserDataAction,LogOutAction,CheckStatusAction};
const mapStatetoProps = (state) => {
    return {
        layout:state.Layout,
        newPasswordRequired: state.LoginUserReducer.newPasswordRequired,
        user: state.LoginUserReducer.user,
        isAuthenticated: state.CheckStatusReducer.isAuthenticated,
    };
};

export default connect(mapStatetoProps, mapDispatchToProps)(App);
