import * as types from "../index";
import {profil_update, Url} from "../../Env/env";

export const UpdateProfilAction = (updateProfil:any, id:number) => {
    return {
        type: types.UPDATE_PROFIL,
        url:Url+profil_update+id,
        updateProfilPayload:updateProfil
    }
};
