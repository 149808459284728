import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../../Common/Breadcrumb';
//Import Components
import DacsTable from "./DacsTable";
import {connect} from "react-redux";
import {GetDacsListAction} from "../../../../actions/dacs/getDacsListAction";
import moment from "moment";
import {isAdmin, isEtablissement, isSociete} from "../../../../utils/myFunction";
import {CreateDacPDFAction} from "../../../../actions/dacs/CreateDacPDFAction";
import {DacPDF} from "./DacPdf";
import {pdf} from "@react-pdf/renderer";
import { saveAs } from 'file-saver';



class DacsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Portail Client", link : "/" },
                { title : "Dac", link : "/dacs/list" },
            ],
            ranges:[
                this.props.startDate!==""?this.props.startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                this.props.endDate!==""?this.props.endDate: moment().format('YYYY-MM-DD'),
            ]
        }
    }
    componentDidMount() {
        const {user,data_admins,data_societe}=this.props;
        if(user && this.props.startDate!=="" && this.props.endDate!== ""){
            const startDate=moment(this.props.startDate).format('YYYY-MM-DD');
            const endDate=moment(this.props.endDate).format('YYYY-MM-DD');
            this.setState({ranges:[startDate,endDate?endDate:null]})
            let data;
            if(isAdmin(user.scope.role) && data_admins){
                data=data_admins;
                data['start_date'] = startDate;
                data['end_date'] = endDate;
            }
            if(isSociete(user.scope.role) && data_societe){
                data=data_societe;
                data['start_date'] = startDate;
                data['end_date'] = endDate;
            }
            if(isEtablissement(user.scope.role)){
                data = {
                    "societeId":this.props.user.societeId,
                    "etablissementsId":[this.props.user.etablissementId],
                    "start_date":startDate,
                    "end_date":endDate,
                } ;
                this.setState({data_etablissement:data});
            }

        if(data){
                this.props.GetDacsListAction(data);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.user){
            if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate ||
                this.props.data_admins &&
                JSON.stringify(this.props.data_admins) !== JSON.stringify(prevProps.data_admins) ||
                JSON.stringify(this.props.data_societe) !== JSON.stringify(prevProps.data_societe)) {

                const startDate = moment(this.props.startDate).format('YYYY-MM-DD');
                const endDate = moment(this.props.endDate).format('YYYY-MM-DD');
                this.setState({ranges: [startDate, endDate ? endDate : null]})
                let data;
                if (isAdmin(this.props.user.scope.role) && this.props.data_admins) {
                    data = this.props.data_admins;
                    data['start_date'] = startDate;
                    data['end_date'] = endDate;
                }
                if (isSociete(this.props.user.scope.role) && this.props.data_societe) {
                    data = this.props.data_societe;
                    data['start_date'] = startDate;
                    data['end_date'] = endDate;
                }
                if (isEtablissement(this.props.user.scope.role)) {
                    data = {
                        "societeId":this.props.user.societeId,
                        "etablissementsId":[this.props.user.etablissementId],
                        "start_date":startDate,
                        "end_date":endDate,
                    };
                    this.setState({data_etablissement:data});
                }
                if (data) {
                    this.props.GetDacsListAction(data);
                }
            }
            if (this.props.dac_details !== prevProps.dac_details && this.props.success && this.props.timestamp !== prevProps.timestamp) {
                pdf(<DacPDF data={this.props.dac_details}/>)
                    .toBlob()
                    .then((blob) => saveAs(blob, this.props.dac_details.date_presta.split('T')[0].replace(/-/g, '').concat(this.props.dac_details.id, ".pdf")));
            }
        }
    }

    render() {
        const {dacs_rows}=this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    <Breadcrumbs title="Liste des Dacs" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <DacsTable dacs={dacs_rows} CreatePDFDac={this.props.CreateDacPDFAction}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {GetDacsListAction,CreateDacPDFAction};

function mapStateToProps(state) {
    return {
        user:state.LoginUserReducer.user,
        dacs_rows:state.GetDacsListReducer.rows,
        startDate:state.ChangeDateRangeReducer.startDateDashboard,
        endDate:state.ChangeDateRangeReducer.endDateDashboard,
        data_admins:state.SetDataForAdminReducer.data_to_admin,
        data_societe:state.SetDataForSocieteReducer.data_to_societe,
        dac_details:state.GetDacDetailsReducer.dac_details,
        success:state.GetDacDetailsReducer.success,
        timestamp:state.GetDacDetailsReducer.timestamp,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DacsPage);
