import * as types from "../../actions";

import {
    GetPrestationAnalyticsGraphInterfaceResponse
} from "../../interfaces/dashboardInterface/GetDetailsOperationnelsGraphInterface";


const initialState: GetPrestationAnalyticsGraphInterfaceResponse = {
    series:[],
    categories:[],
}
export const GetDetailsOperationnelsGraphReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_DETAILS_OPERATIONNELS_GRAPH_SUCCESS:
            return {
                ...state,
                series: response.series,
                categories: response.categories,
            };
        case types.GET_DETAILS_OPERATIONNELS_GRAPH_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
