import React from "react";

export function horaireOuvertureElementsGenerator ( items){
    let horaire_ouverture_list = [];
    Object.keys(items).forEach(function (itemKey) {
        horaire_ouverture_list.push(
            {
                id:items[itemKey]['id'],
                societeId:items[itemKey]['etablissement']['societe']['id'],
                etablissement_name:items[itemKey]['etablissement']['etablissement_name'],
                code_etablissement:items[itemKey]['etablissement']['code_etablissement'],
                horaire_ouverture:
                    items[itemKey]['jour_ouverture'].split(',').map(item=>{
                    return <div key={Math.random()*1001+1}>
                    <li>{firstCapLetter(item)} {items[itemKey]['horaire_start']!=="" && items[itemKey]['horaire_end']!==""?
                        " : de "+items[itemKey]['horaire_start'].substring(0,5)+ " au "+ items[itemKey]['horaire_end'].substring(0,5)
                    :null} </li>
                </div>;
                }),
                societe:items[itemKey]['etablissement']['societe']['nom_societe'],
            }
        );
    });
    return horaire_ouverture_list;
}
function firstCapLetter(str) {
    if (typeof str !== "string") {
        return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
