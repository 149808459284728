export class AddHoraireOuverture {
    etablissementId:number;
    horaires_ouverture:[HoraireOuvertureModel]
    constructor(
       etablissementId:number,horaires_ouverture:[HoraireOuvertureModel]) {
        this.horaires_ouverture=horaires_ouverture;
        this.etablissementId=etablissementId;
    }
}
export class HoraireOuvertureModel {

    days:string;
    horaire_debut:string;
    horaire_fin:string;
    constructor(
        days:string,horaire_debut:string,horaire_fin:string) {
        this.days=days;
        this.horaire_debut=horaire_debut;
        this.horaire_fin=horaire_fin;
    }
}
