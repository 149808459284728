import React from 'react';
import { Route, Redirect } from 'react-router-dom';

class AppRoute extends React.Component {

	render() {
		const { component: Component, layout: Layout, isAuthProtected,isAuthenticated, ...rest } = this.props;
		return (
			<Route
				{...rest}
				render={props => {
					if (isAuthProtected && !isAuthenticated) {
						localStorage.setItem("redirectUrl", props.location.pathname);
						return (
							<Redirect
								to={{
									pathname: "/login",
									state: { from: props.location }
								}}
							/>
						);
					}
					if (!isAuthProtected && isAuthenticated && props.location.pathname === "/login") {
						const { state } = props.location;
						const redirectUrl = localStorage.getItem("redirectUrl");
						localStorage.removeItem("redirectUrl");
						const redirectTo = state && state.from ? state.from.pathname : redirectUrl || "/dashboard";
						return <Redirect to={redirectTo} />;
					}
					return (
						<Layout>
							<Component {...props} />
						</Layout>
					);
				}}
			/>
		);
	}
}

export default AppRoute;
