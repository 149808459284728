import React, {Component} from 'react';
import {connect} from "react-redux";

import FacturesPage from "./facturesPages";



class Factures extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            multiValue: [],
            multiValueSelect: []
        };
    }




    render() {

        return (
            <FacturesPage />
        )

    }
}


const mapDispatchToProps = {};

function mapStateToProps(state) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Factures);