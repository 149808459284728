import React, { Component } from 'react';
import {  Card, CardBody } from "reactstrap";
import cellEditFactory from 'react-bootstrap-table2-editor';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import {CouvertsNumbers} from "../../../../Models/dashboard/couvertsNumbers";


class CouvertsMensuels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    }
  }
  handleCouvertNumber=(row)=>{
      const data=new CouvertsNumbers(Number(row.id),row.num_mois,Number(row.qte_couverts_mois),this.props.user.societeId,this.props.user.etablissementsId[0]);
      this.props.AddCouvertNumber(data);
  }
  render() {
    const columns = [
        {
          dataField: "num_mois",
          text: "Mois",
          editable:false
        },
        {
          dataField: "qte_couverts_mois",
          text: "Nombre de couverts mensuels",
          editable: (content) => Number(content) === null || Number(content) ===0 ,
          validator: (newValue) => {
            if (String(newValue) && isNaN(newValue)) {
              return {
                valid: false,
                message: 'nombre de couverts doit être numerique.'
              };
            }
            return true;
          }
        }
      ];
    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [ {
          text: '10th', value: 10
        }]
    };
    return (
      <React.Fragment >
          <Card>
              <CardBody>
                  <h4 className="card-title mb-4">Nombre de couverts mensuels</h4>
                  <div className="table-responsive">
                      <BootstrapTable
                        keyField="num_mois"
                        data={this.props.couverts}
                        columns={columns}
                        pagination={paginationFactory(options)}
                        cellEdit={ cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                            afterSaveCell: (oldValue, newValue, row, column) => { if(row.qte_couverts_mois >0) this.handleCouvertNumber(row) }
                        }) }
                      />
                  </div>
              </CardBody>
          </Card>
      </React.Fragment>
    );
  }
}

export default CouvertsMensuels;
