import * as types from "../../../actions";

const initialState:any = {
    societeId:0,
    etablissementsId:[null],
    start_date:"",
    end_date:"",
    type:""
}
export const SetDataForSocieteReducer= (state = initialState, action: any) =>{
    const response = action.response;
        switch (action.type) {
        case types.SET_DATA_FOR_SOCIETE_SUCCESS:
            return {
                ...state,
                data_to_societe:response.data_to_societe
            };
        case types.SET_DATA_FOR_SOCIETE_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
