import React, {Component} from 'react';
import {connect} from "react-redux";
import LoginPage from "./loginPage";



class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            multiValue: [],
            multiValueSelect: []
        };
    }




    render() {
        return (
            <div className="main-wrapper">
                <LoginPage/>
            </div>
        )

    }
}


const mapDispatchToProps = {};

function mapStateToProps(state) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);