import {call, put} from "redux-saga/effects";
import {headers} from "../../../utils/headers";
import {get} from "../../../helpers/service/httpSpecificFunctions";
import * as types from "../../../actions"

import {
    GetEtablissementsForAdminInterface,
    GetEtablissementsForAdminInterfaceResponse
} from "../../../interfaces/roleManagementInterface/getEtablissementsForAdminInterface";


export function* GetEtablissementsForAdminSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await get<GetEtablissementsForAdminInterface>(payload.url, headers);
            };
            let response, parsedBody: GetEtablissementsForAdminInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetEtablissementsForAdminInterfaceResponse= parsedBody.data;
                            yield put({
                                type: types.GET_ETABLISSEMENTS_FOR_ADMIN_SUCCESS,
                                response: {
                                    etablissements:data.etablissements,
                                    societe_for_admin:payload.societePayload
                                    }
                                });
                        }
                        break;
                    case 400:
                        break;
                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        if(parsedBody.status ==="Token is Invalid")
                        {
                            // logout();
                        }
                        break;
                }
            }
            else {
                yield put({type: types.GET_ETABLISSEMENTS_FOR_ADMIN_ERROR, error: response.status})
            }
    } catch (error) {
        yield [
            put({type: types.GET_ETABLISSEMENTS_FOR_ADMIN_ERROR, error}),
        ];
    }
}
