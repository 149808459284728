import * as types from "../index";
import { synthese_environnementale_graph_toggle, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const ToggleSyntheseEnvironnementalePeriodeAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.TOGGLE_SYNTHESE_ENVIRONNEMENTALE_GRAPH,
        url:Url + synthese_environnementale_graph_toggle ,
        dataPayload:data_to_send
    }
};
