import {call, put} from "redux-saga/effects";
import * as types from "../../actions";
import {get} from "../../helpers/service/httpSpecificFunctions";

import {headers} from "../../utils/headers";
import {LoginInterfaceResponse} from "../../interfaces/authInterface/loginInterface/loginInterfaceResponse";
import {LoginInterface} from "../../interfaces/authInterface/loginInterface/loginInterface";

export function* GetUserDataSaga(payload:any) : Generator<any, void, any>{
    try {
        const apiCall = async () => {
            return await get<LoginInterface>(payload.url, headers);
        };
        let response, parsedBody: LoginInterfaceResponse|any;
        response = yield call(apiCall);
        parsedBody = response.parsedBody;
        let status=response.status;
        if (status) {
            switch (status) {
                case 200:
                    if (parsedBody) {
                        yield put({
                            type: types.LOGIN_USER_SUCCESS,
                            response: {
                                isAuth:true,
                                user: parsedBody.user,
                            }
                        });
                    }
                    window.location.reload();
                    break;
                default:
                    //logout()
                    break;
            }
        }

        // Redirect to the login page
    } catch (error) {
        if(error instanceof Error)
         yield put({ type: types.USER_DATA_ERROR,response:error.message});
    }
}
