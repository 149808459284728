import * as types from "../index";
import {add_couverts_numbers , Url} from "../../Env/env";
import {CouvertsNumbers} from "../../Models/dashboard/couvertsNumbers";

export const AddCouvertNumberAction = (couvertPayload:CouvertsNumbers) => {
    return {
        type: types.ADD_COUVERTS_NUMBERS,
        url:Url+add_couverts_numbers,
        AddCouvertNumberPayload:couvertPayload
    }
};
