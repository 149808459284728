import React, {Component} from 'react';
import {connect} from "react-redux";
import {PasswordResetAction} from "../../../../actions/auth/PasswordResetAction";
import Swal from "sweetalert2";
import {Link, withRouter} from "react-router-dom";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import images
import logodark from "../../../../assets/images/logo-dark.png";



class ResetPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isSubmit: false
        };
    }
    handlePassword = (event) => {
        event.preventDefault();
        this.setState({isSubmit: true})

        const {newUser,email}=this.props;
        let password = event.target.password.value;
        this.props.PasswordResetAction(password,newUser,email);
    };
     componentDidMount() {
         if (!this.props.newPasswordRequired) {
             this.props.history.push('/login');
         }
         document.body.classList.add("auth-body-bg");

     }
    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.newPasswordRequired === false || this.props.isAuth) {
            this.props.history.push('/login');
        }
        if (this.props.success && this.props.message !== "" && this.state.isSubmit && this.props.timestamp !== prevProps.timestamp) {
            this.setState({isSubmit: false})
            Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            }).fire({
                icon: 'success',
                title: "Le mot de passe est bien changé"
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <img src={logodark} height="20" alt="logo" />
                                                        </div>
                                                        <h4 className="font-size-18 mt-4">Changer le mot de passe temporaire</h4>
                                                    </div>
                                                    <div className="p-2 mt-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handlePassword}>
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"/>
                                                                <Label htmlFor="useremail">Nouveau mot de passe</Label>
                                                                <input type="password" className="form-control" id="password"/>                                                            </div>
                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>
                                                    <div className="mt-5 text-center">
                                                        <p>Déjà changé ? <Link to="/login" className="fw-medium text-primary"> Log in </Link> </p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
const mapDispatchToProps = {PasswordResetAction};
function mapStateToProps(state) {
    return {
        newPasswordRequired: state.LoginUserReducer.newPasswordRequired,
        isAuth: state.LoginUserReducer.isAuth,
        newUser: state.LoginUserReducer.user,
        email: state.LoginUserReducer.email,
        success: state.LoginUserReducer.success,
        message: state.LoginUserReducer.message,
        timestamp: state.LoginUserReducer.timestamp,
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPage));
