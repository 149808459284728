import React, {Component} from 'react';
import {connect} from "react-redux";
import HoraireOuverturePage from "./horaireOuverturePages";

class HoraireOuverture extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            multiValue: [],
            multiValueSelect: []
        };
    }
    render() {
        return (
            <HoraireOuverturePage />
        )
    }
}
const mapDispatchToProps = {};
function mapStateToProps(state) {
    return {

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HoraireOuverture);