import React, {Component} from 'react';
import {connect} from "react-redux";
import ProfilDetails from "./profilPages/profilDetails";



class Profil extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };
    }
    render() {
        return (

                <ProfilDetails/>

        )

    }
}


const mapDispatchToProps = {};

function mapStateToProps(state) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profil);