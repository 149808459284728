import React from "react";
import {TableColumn} from "react-data-table-component";

type FactureDataRow = {
    id: string;
    etablissement_name: string;
    societe_name: string;
    name: string;
    type_facutration: string;
};


export function FacturesListColumns(hideElements:any):TableColumn<FactureDataRow>[]{
    return [
        // {
        //     name: 'Id',
        //     selector: row => row.id,
        //     sortable:true,
        //     omit:hideElements[0].hideId,
        //     width:"80px",
        //     reorder:true,
        // },
        {
            name: 'Nom de la facture',
            selector: row => row.name,
            sortable:true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
            width:"auto",
        },
        {
            name: 'Code de société',
            selector: row => row.societe_name,
            sortable:true,
            omit:hideElements[2].hideSociete,
            reorder:true,
            width:"auto",
        },
        {
            name: "Nom d'etablissement",
            selector: row => row.etablissement_name,
            sortable:true,
            omit:hideElements[1].hideEtablissement,
            reorder:true,
            width:"auto",
        },

        {
            name: 'Actions',
            allowOverflow: true,
            reorder:true,
            button: true,
            width:"200px",
            cell: row => {
                let url=row.type_facutration==="détail"?"https://bucketlogistique.s3.eu-west-3.amazonaws.com/factures/"+row.societe_name+"/"+row.etablissement_name+"/"+row.name:
                    row.type_facutration==="global"? "https://bucketlogistique.s3.eu-west-3.amazonaws.com/factures/"+row.societe_name+"/"+row.name:null
                return (
                    <>
                        {url!==null ? <div className="col-sm-12 text-center">
                            <a href={url}
                               className=" btn btn-info radius-30 m-1" target="_blank" rel="noreferrer" download>
                                <i className="ri-file-download-fill"></i>Télécharger
                            </a>
                        </div>:null}
                    </>
                );
            }
        }
    ];
}