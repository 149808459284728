import * as types from "../index";
import {aleas_logisitque_graph_toggle, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const ToggleAleasLogistiquesGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.TOGGLE_ALEAS_LOGISTIQUES_GRAPH,
        url:Url + aleas_logisitque_graph_toggle,
        dataPayload:data_to_send
 }
};
