import * as types from "../../actions";

import {SyntheseUtilisateur} from "../../Models/dashboard/syntheseUtilisateur";
import {GetSyntheseUtilisateurInterfaceResponse} from "../../interfaces/dashboardInterface/GetSyntheseUtilisateurInterface";


const initialSyntheseUtilisateur:SyntheseUtilisateur=new SyntheseUtilisateur(0,0,0,0,0
    ,0,0,0,0);
const initialState: GetSyntheseUtilisateurInterfaceResponse = {
   synthese_utilisateur:initialSyntheseUtilisateur
}
export const GetSyntheseUtilisateurReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_SYNTHESE_UTILISATEUR_SUCCESS:
            return {
                ...state,
                synthese_utilisateur: response.synthese_utilisateur,
            };
        case types.GET_SYNTHESE_UTILISATEUR_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
