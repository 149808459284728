import {combineReducers} from 'redux';
import {UpdateProfilReducer} from "./profil/updateProfilReducer";
import {LoginUserReducer} from "./auth/LoginUserReducer";
import {DecodeTokenReducer} from "./DecodeTokenReducer";
import {GetReportingCoutWidgetsReducer} from "./factures/getReportingCoutWidgetsReducer";
import {Layout} from "./layout/reducer";
import {GetReportingCoutGraphReducer} from "./factures/getReportingCoutGraphReducer";
import {GetMoyenCoutCouvertReducer} from "./factures/getMoyenCoutCouvertReducer";
import {GetDetailsOperationnelsGraphReducer} from "./dashboard/getDetailsOperationnelsGraphReducer";
import {GetPoidsAnalyseGraphReducer} from "./dashboard/getPoidsAnalyseGraphReducer";
import {GetDacsListReducer} from "./dacs/getDacsListReducer";
import {GetDacDetailsReducer} from "./dacs/getDacDetailsReducer";
import {GetCouvertsNumbersReducer} from "./dashboard/getCouvertsNumbersReducer";
import {AddCouvertsNumbersReducer} from "./dashboard/addCouvertsNumbersReducer";
import {GetSyntheseEnvironnementaleGraphReducer} from "./dashboard/getSyntheseEnvironnementaleGraphReducer";
import {GetFacturesGraphReducer} from "./factures/getFacturesGraphReducer";
import {GetSyntheseUtilisateurReducer} from "./dashboard/getSyntheseUtilisateurReducer";
import {GetAleasLogistiquesGraphReducer} from "./dashboard/getAleasLogistiquesGraphReducer";
import {ChangeDateRangeReducer} from "./dashboard/changeDateRangeReducer";
import {GetProfilDetailsReducer} from "./profil/getProfilDetailsReducer";
import {GetSocietesForAdminReducer} from "./roleManagement/adminPortail/getSocietesForAdminReducer";
import {GetEtablissementsForAdminReducer} from "./roleManagement/adminPortail/getEtablissementsForAdminReducer";
import {SetDataForAdminReducer} from "./roleManagement/adminPortail/setDataForAdminReducer";
import {AddEtablissementsToStoreReducer} from "./roleManagement/adminPortail/addEtablissementsToStoreReducer";
import {GetEtablissementsForSocieteReducer} from "./roleManagement/societe/getEtablissementsForSocieteReducer";
import {SetDataForSocieteReducer} from "./roleManagement/societe/setDataForSocieteReducer";
import {GetFacturesListesReducer} from "./factures/getFacturesListesReducer";
import {GetConsignesCollectesListReducer} from "./etablissements/getConsignesCollectesListReducer";
import {ChangeConsignesReducer} from "./etablissements/changeConsignesReducer";
import {AddHoraireOuvertureReducer} from "./etablissements/addHorairesOuvertureReducer";
import {GetHorairesOuvertureListReducer} from "./etablissements/getHorairesOuvertureListReducer";
import {CheckStatusReducer} from "./auth/CheckStatusReducer";

const rootReducer = combineReducers({
    // reducer to login
    LoginUserReducer,
    CheckStatusReducer,
    // reducer to decode token
    DecodeTokenReducer,

    // Dashboard prestation analytics
    GetDetailsOperationnelsGraphReducer,
    // dashboard analyse poids
    GetPoidsAnalyseGraphReducer,
    // dashboard get couverts numbers
    GetCouvertsNumbersReducer,
    // dashboard add couvert number reducer
    AddCouvertsNumbersReducer,
    // dashboard get tonne collectees
    GetSyntheseEnvironnementaleGraphReducer ,
    // dashboard reducer synthese utilisateur
    GetSyntheseUtilisateurReducer,
    // Get Aleas logistique reducer graph
    GetAleasLogistiquesGraphReducer,
    //ChangeDateRangeReducer
    ChangeDateRangeReducer,
    // reducer to get and update profil data
    GetProfilDetailsReducer,
    UpdateProfilReducer,
    // reducer to get dacs list
    GetDacsListReducer,
    GetDacDetailsReducer,
    // reducer to get facture graph
    GetFacturesGraphReducer,
    GetFacturesListesReducer,
    GetReportingCoutGraphReducer,
    GetReportingCoutWidgetsReducer,
    GetMoyenCoutCouvertReducer,

    // reducer for layout
    Layout,

    GetSocietesForAdminReducer,
    GetEtablissementsForAdminReducer,
    SetDataForAdminReducer,
    AddEtablissementsToStoreReducer,
    GetEtablissementsForSocieteReducer,
    SetDataForSocieteReducer,

    GetConsignesCollectesListReducer,
    ChangeConsignesReducer,
    GetHorairesOuvertureListReducer,
    AddHoraireOuvertureReducer,
});

export default rootReducer;
