import * as types from "../../actions";

import {GetFacturesGraphInterfaceResponse} from "../../interfaces/facturesInterface/GetFacturesGraphInterface";


const initialState: GetFacturesGraphInterfaceResponse = {
    series:[],
    categories:[],
}
export const GetFacturesGraphReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_FACTURES_GRAPH_SUCCESS:
            return {
                ...state,
                series: response.series,
                categories: response.categories,
            };
        case types.GET_FACTURES_GRAPH_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
