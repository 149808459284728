import React, { Component } from 'react';
import {  Card, CardBody } from "reactstrap";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DataTable from 'react-data-table-component';
import {ConsignesCollectesListColumns} from "../../../../../Constansts/consignesCollectesListColumns";
import {consignesCollectesElementsGenerator} from "../../../../../utils/etablissements/consignesCollectesFunctions";
import {UpdateConsignes} from "../../../../../Models/etablissements/updateConsignes";

class ConsignesAndCollecteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideId:true,
      hideEtablissement:true,
      hideSociete:true,
      hideHoraire:true,
      hideConsignes:true,
    }
  }
  handleConsigne = (e,row) => {
      e.preventDefault();
      const new_data=document.querySelector(`textarea[data-row-id="${row.id}"]`).value;
      const data=new UpdateConsignes(row.consignes,new_data,this.props.user.id,row.societeId,row.id);
      if(new_data && new_data!="" && new_data!==data.old_data){
        this.props.handleChangeConsignes(data);
        this.props.handleClick(true)
    }

  }
  render() {
    const hideArray=[
      {hideId:!this.state.hideId},
      {hideEtablissement:!this.state.hideEtablissement},
      {hideSociete:!this.state.hideSociete},
      {hideConsignes:!this.state.hideConsignes},
      {hideHoraire:!this.state.hideHoraire}
    ];
    const customStyles =  {
      table: {
        style: {
          overflow:"auto",// override the row height
        },
      },
    }

    return (
      <React.Fragment>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Liste des Consignes et Collecte</h4>
              <div className="table-responsive">
                  <DataTable
                      columns={ConsignesCollectesListColumns(hideArray,this.handleConsigne)}
                      data={this.props.data && consignesCollectesElementsGenerator(this.props.data)}
                      pagination
                      highlightOnHover
                      pointerOnHover
                      responsive
                      customStyles={customStyles}
                      noHeader
                  />
              </div>
            </CardBody>
          </Card>
      </React.Fragment>
    );
  }
}

export default ConsignesAndCollecteTable;
