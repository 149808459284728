import {call, put} from "redux-saga/effects";
import {headers} from "../../utils/headers";
import {post} from "../../helpers/service/httpSpecificFunctions";
import * as types from "../../actions/index"

import {
    GetSyntheseUtilisateurInterface,
    GetSyntheseUtilisateurInterfaceResponse
} from "../../interfaces/dashboardInterface/GetSyntheseUtilisateurInterface";
import {logout} from "../../utils/cookies";


export function* GetSyntheseUtilisateurSaga(payload: any):any {
    try {
         const apiCall = async () => {
                return await post<GetSyntheseUtilisateurInterface>(payload.url,payload.dataPayload, headers);
            };
            let response, parsedBody: GetSyntheseUtilisateurInterface | any;
            response = yield call(apiCall);
            parsedBody = response.parsedBody;
            let status=response.status;
            if (status) {
                switch (status) {
                    case 200:
                        if (parsedBody.data) {
                            let data: GetSyntheseUtilisateurInterfaceResponse= parsedBody.data;
                            yield put({
                                type: types.GET_SYNTHESE_UTILISATEUR_SUCCESS,
                                response: {
                                    synthese_utilisateur:data.synthese_utilisateur
                                    }
                                });
                        }
                        break;
                    case 400:
                        break;

                    case 401:
                        localStorage.clear();
                        window.location.reload();
                        break;
                    default:
                        break;
                }
            }
            else {
                //logout();
                yield put({type: types.GET_SYNTHESE_UTILISATEUR_ERROR, error: response.status})
            }
    } catch (error) {
        yield [
            put({type: types.GET_SYNTHESE_UTILISATEUR_ERROR, error}),
        ];
    }
}
