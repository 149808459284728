import * as types from "../index";
import { factures_graph, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetFacturesGraphAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_FACTURES_GRAPH,
        url:Url + factures_graph ,
        dataPayload:data_to_send

    }
};
