import * as types from "../../actions/index";
import { call, put } from "redux-saga/effects";
import {
    completeNewPassword,
} from "../../services/authService";
import {postLoginActions} from "../PostLoginActions";


export function* ChangePasswordSaga(payload: any) : Generator<any, void, any> {
    try {
        const result = yield call(completeNewPassword, payload.userPayload, payload.passwordPayload);
        yield put({ type: types.PASSWORD_RESET_SUCCESS, response: result });
        yield call(postLoginActions, payload.emailPayload);

    } catch (error:any) {
        yield put({ type: types.PASSWORD_RESET_ERROR, error: error.message });
    }
}
