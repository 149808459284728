import * as types from "../../actions/index";
import { call, put } from "redux-saga/effects";
import {
    authenticateWithCognito,
} from "../../services/authService";
import { postLoginActions } from "../PostLoginActions";


export function* LoginUserSaga(payload: any) : Generator<any, void, any>{
    try {
        const user = yield call(authenticateWithCognito, payload.LoginUserPayload);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            yield put({ type: types.NEW_PASSWORD_REQUIRED, response: { user, email:payload.LoginUserPayload.email } });
            return;
        }
        yield call(postLoginActions, payload.LoginUserPayload.email);

    } catch (error: any) {
        if (error && error.__type === 'NotAuthorizedException') {
            yield put({ type: types.LOGIN_USER_ERROR, response: {message:"Incorrect username or password.",success:false,timestamp:Date.parse(new Date().toString())} });
        } else {
            yield put({ type: types.LOGIN_USER_ERROR, response: {message:"Incorrect username or password.",success:false,timestamp:Date.parse(new Date().toString())} });
        }
    }
}
