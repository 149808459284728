import React, {Component} from 'react';
import {Button, ButtonGroup, Card, CardBody, Col, Row} from "reactstrap";
import {SyntheseUtilisateurSvg} from "../../../../Constansts/syntheseUtilisateurSvg";
import * as htmlToImage from "html-to-image";
import {exportElementPdf} from "../../../../utils/printElements";

class SyntheseUtilisateur extends Component {
    constructor(props) {
        super(props);
        this.domEl=React.createRef()
    }
    downloadImage = async () => {
        const dataUrl = await htmlToImage.toPng(this.domEl.current);
        const link = document.createElement('a');
        link.download = 'syntheseUtilisateur.png';
        link.href = dataUrl;
        link.click();
    };

    render() {
        return (
            <>
            <Row className={"mb-2"}>
                <Col>
                    <div className="float-end d-none d-md-inline-block">
                        <ButtonGroup className="mb-2">
                            <Button size="md"  className={" btn btn-info m-1 "} onClick={this.downloadImage} type="button">
                                <i className="ri-download-line"></i>
                                <span className="ms-1">Png</span>
                            </Button>
                            <Button size="md"  className={" btn btn-info m-1 "} onClick={exportElementPdf} type="button">
                                <i className="ri-download-line"></i>
                                <span className="ms-1">Pdf</span>
                            </Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className={"bg-light syntheseutilisateur"} id="domEl" ref={this.domEl}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={5}>
                                    <h4 className="card-title mb-4">Synthèse  Utilisateur</h4></Col>
                                <Col sm={7}>
                                    <h3  className="float-start">{this.props.nameEtablissement}</h3>
                                </Col>
                            </Row>
                            {this.props.rangeDate?
                                <Row>
                                    <Col sm={5}>
                                        <h4 className="card-title mb-4">
                                            Période du {this.props.rangeDate[0] + " au " + this.props.rangeDate[1]}
                                        </h4>
                                    </Col>
                                </Row>:
                                null}
                            <Row >
                                <Col>
                                    <SyntheseUtilisateurSvg etablissements={this.props.nameEtablissement}
                                                            synthese_utilisateur={this.props.synthese_utilisateur}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
            </>
        );
    }
}

export default SyntheseUtilisateur;
