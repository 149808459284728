import * as types from "../../actions";
import {GetFacturesListesInterfaceResponse} from "../../interfaces/facturesInterface/GetFacturesListesInterface";
const initialState: GetFacturesListesInterfaceResponse = {
    factures:[],
}
export const GetFacturesListesReducer= (state = initialState, action: any) =>{

    const response = action.response;
    switch (action.type) {
        case types.GET_FACTURES_LISTES_SUCCESS:
            return {
                ...state,
                factures: response.factures,
            };
        case types.GET_FACTURES_LISTES_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
};
