import * as types from "../../actions";

import {FacturesGraph} from "../../Models/factures/facturesGraph";
import {GetFacturesGraphInterfaceResponse} from "../../interfaces/facturesInterface/GetFacturesGraphInterface";


const initialState: GetFacturesGraphInterfaceResponse = {
    series:[],
    categories:[],
}
export const GetMoyenCoutCouvertReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_FACTURES_MOYEN_COUT_COUVERT_SUCCESS:
            return {
                ...state,
                series: response.series,
                categories: response.categories,
            };
        case types.GET_FACTURES_MOYEN_COUT_COUVERT_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
