import * as types from "../../actions";
import {LoginInterfaceResponse} from "../../interfaces/authInterface/loginInterface/loginInterfaceResponse";


const initialState: LoginInterfaceResponse = {
    isAuth:false,
    user:null,
    message:"",
    success:false,
    timestamp:"",

}
export const LoginUserReducer=(state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.NEW_PASSWORD_REQUIRED:
            return {
                ...state,
                newPasswordRequired: true,
                user: response.user,
                email:response.email
            };
        case types.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isAuth: response.isAuth,
                user:response.user,
                message:response.message,
                success:response.success,
                timestamp:response.timestamp,
                newPasswordRequired:false
            };
        case types.LOGIN_USER_ERROR:
            return {
                ...state,
                success:response.success,
                message:response.message,
                timestamp:response.timestamp
            };
        default:
            return state;
    }
}
;
