export function dacsListElementGenerator ( items){
    let tifOptions = [];
    Object.keys(items).forEach(function (itemKey) {
        tifOptions.push(
            {
                id:items[itemKey]['id'],
                collecteur_prevu:items[itemKey]['collecteur_prevu'],
                collecteur_effectif:items[itemKey]['collecteur_effectif'],
                nom_etablissement:items[itemKey]['nom_etablissement'],
                code_etablissement:items[itemKey]['code_etablissement'],
                adresse:items[itemKey]['adresse'],
                cp:items[itemKey]['cp'],
                ville:items[itemKey]['ville'],
                date_presta:items[itemKey]['date_presta'].split('T')[0],
                type_presta:items[itemKey]['type_presta'],
                motif:items[itemKey]['motif'],
                quantite:items[itemKey]['quantite'],
                volume_total:items[itemKey]['volume_total'],
                poids:items[itemKey]['poids']?parseFloat(items[itemKey]['poids']).toFixed(2):0,
                origine_mesure:items[itemKey]['origine_mesure'],
                temps_attente:items[itemKey]['temps_attente'],
            }
        );
    },this);
    return tifOptions;
}
export function processRow(row) {
    delete row.user;
    delete row.id;
    return row;
}
