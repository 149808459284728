import * as types from "../index";
import { dacs_list, Url} from "../../Env/env";
import {SetDataWithSocieteAndEtablissements} from "../../Models/roleManagement/setDataWithSocieteAndEtablissements";

export const GetDacsListAction = (data_to_send:SetDataWithSocieteAndEtablissements) => {
    return {
        type: types.GET_DACS_LIST,
        url:Url + dacs_list,
        dataPayload:data_to_send

    }
};
