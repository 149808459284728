import React from "react";

export function facturesElementsGenerator ( items){
    let facture_list = [];
    Object.keys(items).forEach(function (itemKey) {
        facture_list.push(
            {
                id:items[itemKey]['id'],
                etablissement_name:items[itemKey]['etablissement_name'],
                societe_name:items[itemKey]['societe_name'],
                name:items[itemKey]['name'],
                type_facutration:items[itemKey]['societe']['type_facturation'],
            }
        );
    });
    return facture_list;
}
