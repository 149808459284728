import * as types from "../../actions/index"

const INIT_STATE = {
	layoutType: "vertical",
	layoutWidth: "fluid",
	leftSideBarTheme: "dark",
	leftSideBarType: "default",
	topbarTheme: "light",
	isPreloader: false,
	showRightSidebar: false,
	isMobile: false
};

export const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {
		case types.CHANGE_LAYOUT:
			return {
				...state,
				layoutType: action.payload
			};
		case types.CHANGE_PRELOADER:
			return {
				...state,
				isPreloader: action.payload
			};

		case types.CHANGE_LAYOUT_WIDTH:
			return {
				...state,
				layoutWidth: action.payload.width
			};
		case types.CHANGE_SIDEBAR_THEME:
			return {
				...state,
				leftSideBarTheme: action.payload.theme
			};
		case types.CHANGE_SIDEBAR_TYPE:
			return {
				...state,
				leftSideBarType: action.payload.sidebarType
			};
		case types.CHANGE_TOPBAR_THEME:
			return {
				...state,
				topbarTheme: action.payload
			};
		case types.TOGGLE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: !state.showRightSidebar
			};
		case types.SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: true
			};
		case types.HIDE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: false
			};
		default:
			return state;
	}
};

