import {Auth} from '@aws-amplify/auth';
import {LoginForm} from "../Models/authForm/loginForm";
import {login, Url} from "../Env/env";

async function authenticateWithCognito(login:LoginForm) {
    return await Auth.signIn(login.email, login.password);
}

async function completeNewPassword(user: any, newPassword:string) {
    return await Auth.completeNewPassword(user, newPassword);
}

// Function to refresh the Cognito tokens
async function getCurrentSessionTokens() {
    const session = await Auth.currentSession();
    return {
        idToken: session.getIdToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken()
    };
}

async function getBackendToken(cognitoToken:string,refreshToken:string,email:string) {
    const response = await fetch(Url+login, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: cognitoToken,refreshToken,email }),
        credentials:"include"
    });

    const data = await response.json();

    if (response.status !== 200) {
        throw new Error(data.message || "Backend authentication failed");
    }
    return data; // Assuming your backend sends the token as { token: 'YOUR_PERSONALIZED_TOKEN' }
}

export { authenticateWithCognito, completeNewPassword, getCurrentSessionTokens,getBackendToken };
