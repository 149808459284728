export const privateNavigation = [
    ["DashboardPage", "/", "DashboardPage","Dashboard", true,false],
    ["DashboardPage", "/dashboard", "DashboardPage","Dashboard", true,false],

    ["DacsPage", "/dacs", "DacsPage","Dacs", true,false],

    ["FacturesPage", "/factures", "FacturesPage","Factures", true,false],

    ["ProfilPage", "/profil", "ProfilPage","Profil", true,false],

    ["ConsignesCollectesPages", "/etablissements/consignes-collecte", "ConsignesCollectesPages","Consignes & Collectes", true,false],
    ["HoraireOuverturePages", "/etablissements/horaires-ouverture", "HoraireOuverturePages","Horaire ouverture", true,false],


];

export const publicNavigation = [
    ["LoginPage", "/login", "LoginPage", true,false],
];
