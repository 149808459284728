import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../../Common/Breadcrumb';
//Import Components
import FacturesTable from "./FacturesTable";
import {connect} from "react-redux";
import CoutsAvgGraph from "./CoutsAvgGraph";
import {GetFacturesGraphAction} from "../../../../actions/factures/getFacturesGraphAction";
import CoutsAnalytics from "./CoutsAnalytics";
import {GetReportingCoutsGraphAction} from "../../../../actions/factures/getReportingCoutsGraphAction";
import {ToggleCoutsAnalyticsGraphAction} from "../../../../actions/factures/toggleCoutsAnalyticsGraphAction";
import moment from "moment/moment";
import {ToggleCoutAvgGraphAction} from "../../../../actions/factures/toggleCoutAvgGraphAction";
import CoutsWidgets from "../../dashboard/dashboardPages/CoutsWidgets";
import {GetReportingCoutsWidgetsAction} from "../../../../actions/factures/getReportingCoutsWidgetsAction";
import {isAdmin, isEtablissement, isSociete} from "../../../../utils/myFunction";
import {GetFacturesListesAction} from "../../../../actions/factures/getFacturesListesAction";
import MoyenCoutCouvert from "./MoyenCoutCouvert";
import {GetMoyenCoutCouvertAction} from "../../../../actions/factures/getMoyenCoutCouvertAction";


class FacturesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Portail Client", link : "/" },
                { title : "Factures", link : "/factures" },
            ],
            ranges:[
                this.props.startDate!==""?this.props.startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                this.props.endDate!==""?this.props.endDate: moment().format('YYYY-MM-DD'),
            ],
            data_etablissement:null,
        }
    }
    componentDidMount() {
        const {user,data_admins,data_societe}=this.props;
        if(user && this.props.startDate!=="" && this.props.endDate!== ""){
            const startDate=moment(this.props.startDate).format('YYYY-MM-DD');
            const endDate=moment(this.props.endDate).format('YYYY-MM-DD');
            this.setState({ranges:[startDate,endDate?endDate:null]})
            let data;
            if (isAdmin(user.scope.role) && data_admins) {
                data = data_admins;
                data['start_date'] = startDate;
                data['end_date'] = endDate;
            }
            if (isSociete(user.scope.role) && data_societe) {
                data = data_societe;
                data['start_date'] = startDate;
                data['end_date'] = endDate;
            }
            if (isEtablissement(user.scope.role)) {
                data = {
                    "societeId":user.societeId,
                    "etablissementsId":[user.etablissementId],
                    "start_date":startDate,
                    "end_date":endDate,
                };
                this.setState({data_etablissement:data});
            }
            if(data){
                this.props.GetFacturesGraphAction(data);
                this.props.GetReportingCoutsWidgetsAction(data);
                this.props.GetReportingCoutsGraphAction(data);
                this.props.GetFacturesListesAction(data);
                this.props.GetMoyenCoutCouvertAction(data);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.user){
            if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate ||
                this.props.data_admins && JSON.stringify(this.props.data_admins) !== JSON.stringify(prevProps.data_admins) ||
                JSON.stringify(this.props.data_societe) !== JSON.stringify(prevProps.data_societe)) {
                const startDate = moment(this.props.startDate).format('YYYY-MM-DD');
                const endDate = moment(this.props.endDate).format('YYYY-MM-DD');
                this.setState({ranges: [startDate, endDate ? endDate : null]})
                let data;
                if (isAdmin(this.props.user.scope.role) && this.props.data_admins) {
                    data = this.props.data_admins;
                    data['start_date'] = startDate;
                    data['end_date'] = endDate;
                }
                if (isSociete(this.props.user.scope.role) && this.props.data_societe) {
                    data = this.props.data_societe;
                    data['start_date'] = startDate;
                    data['end_date'] = endDate;
                }
                if (isEtablissement(this.props.user.scope.role)) {
                    data = {
                        "societeId":this.props.user.societeId,
                        "etablissementsId":[this.props.user.etablissementId],
                        "start_date":startDate,
                        "end_date":endDate,
                    };
                    this.setState({data_etablissement:data});
                }
                if (data) {
                    this.props.GetFacturesGraphAction(data);
                    this.props.GetReportingCoutsWidgetsAction(data);
                    this.props.GetReportingCoutsGraphAction(data);
                    this.props.GetFacturesListesAction(data);
                    this.props.GetMoyenCoutCouvertAction(data);
                }
            }
        }
    }

    render() {
        const {user,couts_widgets,cout_moyen_series,cout_moyen_categories,decoded_token,
            couts_graph_series,couts_graph_categories,data_admins,data_societe,factures_listes,
            moyen_cout_couvert_series,moyen_cout_couvert_categories}=this.props;
        if(!user)return null;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    <Breadcrumbs title="Factures" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>

                            {/*couts widgets*/}
                            <Col  xl={12}>
                                <Row>
                                    <CoutsWidgets reports={couts_widgets} />
                                </Row>
                            </Col>
                            <Col xl={12}>
                                <CoutsAnalytics ToggleCoutsAnalyticsPeriode={this.props.ToggleCoutsAnalyticsGraphAction}
                                                client={isAdmin(user.scope.role)?data_admins
                                                    : isSociete(user.scope.role)?data_societe
                                                        : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                            :null}
                                                series={couts_graph_series} categories={couts_graph_categories}/>

                            </Col>
                            <Col md={12}>
                                <CoutsAvgGraph ToggleCoutAvgGraph={this.props.ToggleCoutAvgGraphAction}
                                               series={cout_moyen_series} categories={cout_moyen_categories}
                                               client={isAdmin(user.scope.role)?data_admins
                                                   : isSociete(user.scope.role)?data_societe
                                                       : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                           :null}
                                />
                            </Col>
                            <Col md={12}>
                                <MoyenCoutCouvert
                                               series={moyen_cout_couvert_series} categories={moyen_cout_couvert_categories}
                                               client={isAdmin(user.scope.role)?data_admins
                                                   : isSociete(user.scope.role)?data_societe
                                                       : isEtablissement(user.scope.role)?this.state.data_etablissement
                                                           :null}
                                />
                            </Col>
                            <Col>
                                <FacturesTable factures={factures_listes}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {GetReportingCoutsWidgetsAction,GetFacturesGraphAction,GetReportingCoutsGraphAction,
    ToggleCoutsAnalyticsGraphAction,ToggleCoutAvgGraphAction,GetFacturesListesAction,GetMoyenCoutCouvertAction};

function mapStateToProps(state) {
    return {
        user:state.LoginUserReducer.user,
        cout_moyen_series:state.GetFacturesGraphReducer.series,
        cout_moyen_categories:state.GetFacturesGraphReducer.categories,
        couts_graph_series:state.GetReportingCoutGraphReducer.series,
        couts_graph_categories:state.GetReportingCoutGraphReducer.categories,
        startDate:state.ChangeDateRangeReducer.startDateDashboard,
        endDate:state.ChangeDateRangeReducer.endDateDashboard,
        couts_widgets:state.GetReportingCoutWidgetsReducer.couts_widgets,
        data_admins:state.SetDataForAdminReducer.data_to_admin,
        data_societe:state.SetDataForSocieteReducer.data_to_societe,
        moyen_cout_couvert_series:state.GetMoyenCoutCouvertReducer.series,
        moyen_cout_couvert_categories:state.GetMoyenCoutCouvertReducer.categories,
        factures_listes:state.GetFacturesListesReducer.factures,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacturesPage);
