import * as types from "../../actions";
import {GetSyntheseEnvironnementaleGraphInterfaceResponse} from "../../interfaces/dashboardInterface/GetSyntheseEnvironnementaleGraphInterface";

const initialState: GetSyntheseEnvironnementaleGraphInterfaceResponse = {
    series:[],
    categories:[],
}
export const GetSyntheseEnvironnementaleGraphReducer= (state = initialState, action: any) =>{
    const response = action.response;
    switch (action.type) {
        case types.GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH_SUCCESS:
            return {
                ...state,
                series: response.series,
                categories: response.categories,
            };
        case types.GET_SYNTHESE_ENVIRONNEMENTALE_GRAPH_ERROR:
            return {
                ...state,
                error:response.error
            };

        default:
            return state;
    }
}
;
