export const SelectStyles={

    control:(css:any)=>({
        ...css,
        background:"white",
        margin: "2px 20px 20px 20px",
    }),
    option:(css:any)=>({
        ...css,
        background:"white",
        color:"black",
        borderBottom: '1px solid grey',
        padding: 5,
        font:'9px',
        width:'auto'
    }),
    input: (css:any) => ({
        ...css,
        input: {
            color:"black",
            background: "black",
            margin: "0px 0px 0px 0px",

        }
    })
};