import React, {Component} from 'react';
import {connect} from "react-redux";
import DacsPage from "./dacPages";

class Dacs extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }
    render() {
        return (
            <DacsPage />
        )
    }
}
const mapDispatchToProps = {};
function mapStateToProps() {
    return {

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dacs);